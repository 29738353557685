const screenInputData = {
  Customer_New: [{"name":"prmCustomerId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	TestScreen: [{"name":"prmProductId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"prmProductCategoryId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"prmImageId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	Brand_New: [{"name":"prmBrandId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"prmImageId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	Region_New: [{"name":"prmRegionId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	Product_Translation: [{"name":"prmLanguageId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"prmProductTranslationId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"prmWaitFor","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"prmCurrentLang","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"prmProductCategoryId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	SubBrand_New: [{"name":"prmSubBrandId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"prmParentId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	Product_New: [{"name":"prmProductId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"prmProductCategoryId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"prmPurpose","type":"String","cardinality":1,"isExpandedPanel":null}],
	Contact_New: [{"name":"prmContactId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"prmCustomerId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"prmPurpose","type":"String","cardinality":1,"isExpandedPanel":null}],
	Customer_Details: [{"name":"prmCustomerId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	Campaign_Details: [{"name":"prmCampaignId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	Campaign_New: [{"name":"prmCampaignId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"prmImageId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"prmPurpose","type":"String","cardinality":1,"isExpandedPanel":null}],
	Product_NewCategory: [{"name":"prmImageId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"prmProductTranslationId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"prmProductCategoryId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"prmProductId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"prmPurpose","type":"String","cardinality":1,"isExpandedPanel":null}],
	Product_Details: [{"name":"prmImageId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"prmProductCategoryId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	Contact_UnpairedNew: [{"name":"prmContactId","type":"Guid","cardinality":1,"isExpandedPanel":null}]
};

export class ScreenInputHelper {
  public static getScreenInputValue(screenName: string, inputName: string, value: string): any {
    switch (this.getScreenInputType(screenName, inputName)) {
      case "String":
        return value;
      case "Boolean":
        return value.toLowerCase() === "true";
      // TODO
    }

    return value;
  }

  private static getScreenInputType(screenName: string, inputName: string): string {
    const data = screenInputData[screenName]?.find((x) => x.Name === inputName);
    return data?.TypeName ? data.TypeName : "";
  }
}
