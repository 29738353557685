import { Form, Spin, Typography } from "antd";
import { connect } from "react-redux";
import {
  Button,
  Header,
  HorizontalStack,
  Icon,
  Image,
  KCol,
  KContentMenu,
  KContentMenuItem,
  KContentMenuPanel,
  KDrawer,
  KPagination,
  KPanel,
  KRow,
  KTab,
  KTabItem,
  KTable,
  KTableColumn,
  KTableHeader,
  KTableRow,
  Label,
  TextInput
} from "../../components/web-components";
import {
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/photo-take/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { Campaign_List_ScreenBase } from "./campaign_list-base";

import { Campaign_Details_Screen } from "../campaign_details/campaign_details";
import { Campaign_New_Screen } from "../campaign_new/campaign_new";
import { LeftMenu_Screen } from "../leftmenu/leftmenu";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;

class Campaign_List_Screen extends Campaign_List_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <Spin spinning={this.state.spinnerCount > 0}>
          <Form
            form={this.props.form}
            name="basic"
            initialValues={{
              campaign_list_444432_value: "d4abeae1-492c-4c55-3c19-08db6ca818e0",
              campaign_list_610406_value: undefined,
              campaign_list_254037_value: undefined
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
              <Header
                id="campaign_list_header"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    textAlign: "-webkit-center",
                    fontFamily: "Poppins",
                    fontWeight: 400,
                    fontSize: "15px",
                    color: "rgba(68, 68, 68, 1)",
                    lineHeight: "22px"
                  } as any
                }
              >
                <KPanel
                  id="939301"
                  scrolling="hidden"
                  scrollRadius={false}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      maxWidth: "1240px",
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      fontSize: "15px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                >
                  <KRow
                    id="558941"
                    align="top"
                    justify="start"
                    horizontalGutter={0}
                    verticalGutter={0}
                    style={
                      {
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 12,
                        borderBottomLeftRadius: 12,
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        paddingTop: 16,
                        paddingRight: 16,
                        paddingBottom: 16,
                        paddingLeft: 16,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: "15px",
                        color: "rgba(68, 68, 68, 1)",
                        lineHeight: "22px"
                      } as any
                    }
                  >
                    <KCol
                      id="720152"
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      xxl={12}
                      order={0}
                      pull={0}
                      push={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 8,
                          paddingRight: 8,
                          paddingBottom: 8,
                          paddingLeft: 8,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: "15px",
                          color: "rgba(68, 68, 68, 1)",
                          lineHeight: "22px"
                        } as any
                      }
                    >
                      <HorizontalStack
                        id="48192"
                        direction="horizontal"
                        size={24}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            alignItems: "center",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: "15px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      >
                        <Icon
                          id="276412"
                          onClick={(e?: any) => {
                            if (e && e.stopPropagation) e.stopPropagation();
                            this.Campaign_ListComponent_276412_onClick();
                          }}
                          showCursorPointer
                          iconName="menu"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "24px",
                              color: "rgba(155, 155, 155, 1)",
                              letterSpacing: "1px"
                            } as any
                          }
                        ></Icon>

                        <Label
                          id="316688"
                          value={ReactSystemFunctions.translate(this.ml, 316688, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 300,
                              fontSize: "24px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        ></Label>
                      </HorizontalStack>
                    </KCol>

                    <KCol
                      id="762032"
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      xxl={12}
                      order={0}
                      pull={0}
                      push={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 8,
                          paddingRight: 8,
                          paddingBottom: 8,
                          paddingLeft: 8,
                          alignItems: "center",
                          textAlign: "-webkit-right",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: "15px",
                          color: "rgba(68, 68, 68, 1)",
                          lineHeight: "22px"
                        } as any
                      }
                    >
                      <HorizontalStack
                        id="882446"
                        direction="horizontal"
                        size={24}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            alignItems: "center",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: "15px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      >
                        <KContentMenu
                          id="19507"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: "15px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        >
                          <KContentMenuPanel
                            id="16666"
                            style={
                              {
                                borderTopLeftRadius: 12,
                                borderTopRightRadius: 12,
                                borderBottomRightRadius: 12,
                                borderBottomLeftRadius: 12,
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "15px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="campaign_list_444432_value">
                              <Image
                                id="444432"
                                zoomOnClick={false}
                                imageFit="stretch"
                                style={
                                  {
                                    borderTopLeftRadius: 32,
                                    borderTopRightRadius: 32,
                                    borderBottomRightRadius: 32,
                                    borderBottomLeftRadius: 32,
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    width: "32px",
                                    height: "32px",
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 400,
                                    fontSize: "15px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              ></Image>
                            </Form.Item>
                          </KContentMenuPanel>

                          <KContentMenuItem
                            id="640217"
                            label={ReactSystemFunctions.translate(this.ml, 640217, "label", this.defaultML)}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundColor: "rgba(255, 255, 255, 1)",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 20,
                                paddingRight: 32,
                                paddingBottom: 20,
                                paddingLeft: 16,
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "15px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          ></KContentMenuItem>

                          <KContentMenuItem
                            id="126271"
                            label={ReactSystemFunctions.translate(this.ml, 126271, "label", this.defaultML)}
                            style={
                              {
                                borderTopWidth: 1,
                                borderColor: "rgba(238, 238, 238, 1)",
                                borderStyle: "solid",
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundColor: "rgba(255, 255, 255, 1)",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 20,
                                paddingRight: 32,
                                paddingBottom: 20,
                                paddingLeft: 16,
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "15px",
                                color: "rgba(208, 2, 27, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          ></KContentMenuItem>
                        </KContentMenu>
                      </HorizontalStack>
                    </KCol>
                  </KRow>
                </KPanel>
              </Header>

              <div
                id="campaign_list_body"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(247, 247, 247, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left",
                    display: "block"
                  } as any
                }
              >
                <KRow
                  id="999506"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 24,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      fontSize: "15px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                >
                  <KCol
                    id="150846"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        alignItems: "flex-start",
                        textAlign: "-webkit-center",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: "15px",
                        color: "rgba(68, 68, 68, 1)",
                        lineHeight: "22px"
                      } as any
                    }
                  >
                    <KPanel
                      id="795366"
                      scrolling="hidden"
                      scrollRadius={false}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          maxWidth: "1240px",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: "15px",
                          color: "rgba(68, 68, 68, 1)",
                          lineHeight: "22px"
                        } as any
                      }
                    >
                      <KRow
                        id="354238"
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 16,
                            paddingLeft: 0,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: "15px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      >
                        <KCol
                          id="11164"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                          xxl={24}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderTopLeftRadius: 12,
                              borderTopRightRadius: 12,
                              borderBottomRightRadius: 12,
                              borderBottomLeftRadius: 12,
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(255, 255, 255, 1)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: "15px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        >
                          <KRow
                            id="934264"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 0,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "15px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          >
                            <KCol
                              id="339223"
                              xs={16}
                              sm={16}
                              md={16}
                              lg={16}
                              xl={16}
                              xxl={16}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 400,
                                  fontSize: "15px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            >
                              <Form.Item className="kFormItem" name="campaign_list_610406_value">
                                <TextInput
                                  id="610406"
                                  onChange={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.Campaign_ListComponent_610406_onChange();
                                  }}
                                  placeholder={ReactSystemFunctions.translate(
                                    this.ml,
                                    610406,
                                    "placeholder",
                                    this.defaultML
                                  )}
                                  allowClear={true}
                                  bordered={true}
                                  disabled={false}
                                  type="text"
                                  iconColor="rgba(0, 0, 0, 1)"
                                  formatter="None"
                                  isCharOnly={false}
                                  style={
                                    {
                                      borderTopLeftRadius: 8,
                                      borderTopRightRadius: 8,
                                      borderBottomRightRadius: 8,
                                      borderBottomLeftRadius: 8,
                                      borderTopWidth: 1,
                                      borderRightWidth: 1,
                                      borderBottomWidth: 1,
                                      borderLeftWidth: 1,
                                      borderColor: "rgba(255, 255, 255, 0)",
                                      borderStyle: "solid",
                                      backgroundColor: "rgba(247, 247, 247, 1)",
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      width: "300px",
                                      height: "40px",
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "15px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                ></TextInput>
                              </Form.Item>
                            </KCol>

                            <KCol
                              id="36212"
                              xs={8}
                              sm={8}
                              md={8}
                              lg={8}
                              xl={8}
                              xxl={8}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-right",
                                  fontFamily: "Poppins",
                                  fontWeight: 400,
                                  fontSize: "15px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            >
                              <Button
                                id="625746"
                                onClick={(e?: any) => {
                                  if (e && e.stopPropagation) e.stopPropagation();
                                  this.Campaign_ListComponent_625746_onClick();
                                }}
                                showCursorPointer
                                label={ReactSystemFunctions.translate(this.ml, 625746, "label", this.defaultML)}
                                size="middle"
                                loading={false}
                                ghost={false}
                                block={false}
                                htmlType="button"
                                iconPosition="left"
                                danger={false}
                                style={
                                  {
                                    borderTopLeftRadius: 8,
                                    borderTopRightRadius: 8,
                                    borderBottomRightRadius: 8,
                                    borderBottomLeftRadius: 8,
                                    borderTopWidth: 1,
                                    borderRightWidth: 1,
                                    borderBottomWidth: 1,
                                    borderLeftWidth: 1,
                                    borderColor: "rgba(3, 119, 253, 1)",
                                    borderStyle: "solid",
                                    backgroundColor: "rgba(255, 255, 255, 1)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingTop: 8,
                                    paddingRight: 16,
                                    paddingBottom: 8,
                                    paddingLeft: 16,
                                    height: "40px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "16px",
                                    color: "rgba(54, 112, 246, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              ></Button>
                            </KCol>
                          </KRow>

                          <KRow
                            id="704617"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "15px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          >
                            <KCol
                              id="524275"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 400,
                                  fontSize: "15px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            >
                              <Form.Item className="kFormItem" name="campaign_list_254037_value" noStyle>
                                <KTab
                                  id="254037"
                                  type="line"
                                  size="default"
                                  tabPosition="top"
                                  selectedTabColor="#0078FF"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "15px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <KTabItem
                                    id="688816"
                                    tab={ReactSystemFunctions.translate(this.ml, 688816, "tab", this.defaultML)}
                                    key={688816}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundColor: "rgba(255, 255, 255, 1)",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "15px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <KTable
                                      id="886984"
                                      kuikaRef={this.campaign_list_886984_value_kuikaTableRef}
                                      form={this.props.form}
                                      dataSource={this.state.CurrentCampaignSearch}
                                      size="middle"
                                      bordered={true}
                                      showHeader={true}
                                      loading={false}
                                      nodatafoundmessage={ReactSystemFunctions.translate(
                                        this.ml,
                                        886984,
                                        "nodatafoundmessage",
                                        this.defaultML
                                      )}
                                      sorter={false}
                                      pagination={false}
                                      striped={false}
                                      stripedColor="#F5F7FA"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 400,
                                          fontSize: "15px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <KTableHeader
                                        id="155051"
                                        hideOnMobileResolution={false}
                                        textDirection="Default"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "12px",
                                            color: "rgba(155, 155, 155, 1)",
                                            lineHeight: "20px"
                                          } as any
                                        }
                                      ></KTableHeader>

                                      <KTableRow
                                        id="989163"
                                        hoverFontColor="red"
                                        hoverBgColor="#F5F5F5"
                                        style={
                                          {
                                            borderTopWidth: 1,
                                            borderRightWidth: 1,
                                            borderBottomWidth: 1,
                                            borderLeftWidth: 1,
                                            borderColor: "rgba(218, 218, 218, 1)",
                                            borderStyle: "solid",
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "15px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <KTableColumn
                                          id="326261"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            326261,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          style={
                                            {
                                              borderTopWidth: 1,
                                              borderRightWidth: 1,
                                              borderBottomWidth: 1,
                                              borderLeftWidth: 1,
                                              borderColor: "rgba(218, 218, 218, 1)",
                                              borderStyle: "solid",
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              fontFamily: "Poppins",
                                              fontWeight: 400,
                                              fontSize: "15px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="184064"
                                            value="[datafield:campaignName]"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 400,
                                                fontSize: "15px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          ></Label>
                                        </KTableColumn>

                                        <KTableColumn
                                          id="463931"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            463931,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              width: "260px",
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              fontFamily: "Poppins",
                                              fontWeight: 400,
                                              fontSize: "15px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="981286"
                                            value="[datafield:regionsNames]"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 400,
                                                fontSize: "15px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          ></Label>
                                        </KTableColumn>

                                        <KTableColumn
                                          id="698337"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            698337,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          style={
                                            {
                                              borderTopWidth: 1,
                                              borderRightWidth: 1,
                                              borderBottomWidth: 1,
                                              borderLeftWidth: 1,
                                              borderColor: "rgba(218, 218, 218, 1)",
                                              borderStyle: "solid",
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              width: "260px",
                                              alignItems: "center",
                                              textAlign: "-webkit-right",
                                              fontFamily: "Poppins",
                                              fontWeight: 400,
                                              fontSize: "15px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="835747"
                                            value="[datafield:startDate]"
                                            formatter="datetime"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 400,
                                                fontSize: "15px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          ></Label>
                                        </KTableColumn>

                                        <KTableColumn
                                          id="855514"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            855514,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          style={
                                            {
                                              borderTopWidth: 1,
                                              borderRightWidth: 1,
                                              borderBottomWidth: 1,
                                              borderLeftWidth: 1,
                                              borderColor: "rgba(218, 218, 218, 1)",
                                              borderStyle: "solid",
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              width: "260px",
                                              alignItems: "center",
                                              textAlign: "-webkit-right",
                                              fontFamily: "Poppins",
                                              fontWeight: 400,
                                              fontSize: "15px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="855123"
                                            value="[datafield:endDate]"
                                            formatter="datetime"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 400,
                                                fontSize: "15px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          ></Label>
                                        </KTableColumn>

                                        <KTableColumn
                                          id="269276"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            269276,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          style={
                                            {
                                              borderTopWidth: 1,
                                              borderRightWidth: 1,
                                              borderBottomWidth: 1,
                                              borderLeftWidth: 1,
                                              borderColor: "rgba(218, 218, 218, 1)",
                                              borderStyle: "solid",
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              width: "260px",
                                              alignItems: "center",
                                              textAlign: "-webkit-right",
                                              fontFamily: "Poppins",
                                              fontWeight: 400,
                                              fontSize: "15px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="991406"
                                            value="[datafield:submitDate]"
                                            formatter="date"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 400,
                                                fontSize: "15px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          ></Label>
                                        </KTableColumn>

                                        <KTableColumn
                                          id="827875"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            827875,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          style={
                                            {
                                              borderTopWidth: 1,
                                              borderRightWidth: 1,
                                              borderBottomWidth: 1,
                                              borderLeftWidth: 1,
                                              borderColor: "rgba(218, 218, 218, 1)",
                                              borderStyle: "solid",
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-center",
                                              fontFamily: "Poppins",
                                              fontWeight: 400,
                                              fontSize: "15px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <HorizontalStack
                                            id="570731"
                                            direction="horizontal"
                                            size={2}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                fontFamily: "Poppins",
                                                fontWeight: 400,
                                                fontSize: "15px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          >
                                            <Icon
                                              id="78003"
                                              onClick={(e?: any) => {
                                                if (e && e.stopPropagation) e.stopPropagation();
                                                this.Campaign_ListComponent_78003_onClick();
                                              }}
                                              showCursorPointer
                                              iconName="edit"
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 8,
                                                  paddingRight: 8,
                                                  paddingBottom: 8,
                                                  paddingLeft: 8,
                                                  alignItems: "flex-start",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "20px",
                                                  color: "rgba(245, 166, 35, 1)",
                                                  letterSpacing: "1px"
                                                } as any
                                              }
                                            ></Icon>

                                            <Icon
                                              id="950975"
                                              onClick={(e?: any) => {
                                                if (e && e.stopPropagation) e.stopPropagation();
                                                this.Campaign_ListComponent_950975_onClick();
                                              }}
                                              showCursorPointer
                                              iconName="remove_red_eye"
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 0,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "20px",
                                                  color: "rgba(65, 117, 5, 1)",
                                                  letterSpacing: "1px"
                                                } as any
                                              }
                                            ></Icon>
                                          </HorizontalStack>
                                        </KTableColumn>
                                      </KTableRow>
                                    </KTable>

                                    <KRow
                                      id="321049"
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "flex-start",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Poppins",
                                          fontWeight: 400,
                                          fontSize: "15px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="317213"
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                        xxl={12}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "15px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="178105"
                                          value={ReactSystemFunctions.translate(
                                            this.ml,
                                            178105,
                                            "value",
                                            this.defaultML
                                          )}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              alignItems: "flex-start",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 400,
                                              fontSize: "15px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        ></Label>

                                        <Label
                                          id="789737"
                                          value={this.state.CurrentCampaignSearchCount?.at?.(0)?.count ?? undefined}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              alignItems: "flex-start",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 400,
                                              fontSize: "15px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        ></Label>
                                      </KCol>

                                      <KCol
                                        id="299077"
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                        xxl={12}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "15px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <KPagination
                                          id="758021"
                                          kuikaRef={this.campaign_list_758021_value_kuikaPaginationRef}
                                          total={this.state.CurrentCampaignSearchCount?.at?.(0)?.count ?? undefined}
                                          pageSize={20}
                                          defaultCurrent={1}
                                          current={1}
                                          defaultPageSize={10}
                                          disabled={false}
                                          hideOnSinglePage={true}
                                          showLessItems={false}
                                          showQuickJumper={false}
                                          showSizeChanger={false}
                                          showFirstPageButton={false}
                                          showLastPageButton={false}
                                          showNumbers={true}
                                          showText={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              textAlign: "-webkit-right"
                                            } as any
                                          }
                                        ></KPagination>
                                      </KCol>
                                    </KRow>
                                  </KTabItem>

                                  <KTabItem
                                    id="871467"
                                    tab={ReactSystemFunctions.translate(this.ml, 871467, "tab", this.defaultML)}
                                    key={871467}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundColor: "rgba(255, 255, 255, 1)",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "15px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <KTable
                                      id="669724"
                                      kuikaRef={this.campaign_list_669724_value_kuikaTableRef}
                                      form={this.props.form}
                                      dataSource={this.state.PastCampaignSearch}
                                      size="middle"
                                      bordered={true}
                                      showHeader={true}
                                      loading={false}
                                      nodatafoundmessage={ReactSystemFunctions.translate(
                                        this.ml,
                                        669724,
                                        "nodatafoundmessage",
                                        this.defaultML
                                      )}
                                      sorter={false}
                                      pagination={false}
                                      striped={false}
                                      stripedColor="#F5F7FA"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 400,
                                          fontSize: "15px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <KTableHeader
                                        id="225481"
                                        hideOnMobileResolution={false}
                                        textDirection="Default"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "12px",
                                            color: "rgba(155, 155, 155, 1)",
                                            lineHeight: "20px"
                                          } as any
                                        }
                                      ></KTableHeader>

                                      <KTableRow
                                        id="825224"
                                        hoverFontColor="red"
                                        hoverBgColor="#F5F5F5"
                                        style={
                                          {
                                            borderTopWidth: 1,
                                            borderRightWidth: 1,
                                            borderBottomWidth: 1,
                                            borderLeftWidth: 1,
                                            borderColor: "rgba(218, 218, 218, 1)",
                                            borderStyle: "solid",
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "15px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <KTableColumn
                                          id="738539"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            738539,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          style={
                                            {
                                              borderTopWidth: 1,
                                              borderRightWidth: 1,
                                              borderBottomWidth: 1,
                                              borderLeftWidth: 1,
                                              borderColor: "rgba(218, 218, 218, 1)",
                                              borderStyle: "solid",
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              fontFamily: "Poppins",
                                              fontWeight: 400,
                                              fontSize: "15px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="100328"
                                            value="[datafield:campaignName]"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 400,
                                                fontSize: "15px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          ></Label>
                                        </KTableColumn>

                                        <KTableColumn
                                          id="490555"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            490555,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              width: "260px",
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              fontFamily: "Poppins",
                                              fontWeight: 400,
                                              fontSize: "15px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="134041"
                                            value="[datafield:regionsNames]"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 400,
                                                fontSize: "15px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          ></Label>
                                        </KTableColumn>

                                        <KTableColumn
                                          id="649991"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            649991,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          style={
                                            {
                                              borderTopWidth: 1,
                                              borderRightWidth: 1,
                                              borderBottomWidth: 1,
                                              borderLeftWidth: 1,
                                              borderColor: "rgba(218, 218, 218, 1)",
                                              borderStyle: "solid",
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              width: "260px",
                                              alignItems: "center",
                                              textAlign: "-webkit-right",
                                              fontFamily: "Poppins",
                                              fontWeight: 400,
                                              fontSize: "15px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="589024"
                                            value="[datafield:startDate]"
                                            formatter="datetime"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 400,
                                                fontSize: "15px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          ></Label>
                                        </KTableColumn>

                                        <KTableColumn
                                          id="595348"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            595348,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          style={
                                            {
                                              borderTopWidth: 1,
                                              borderRightWidth: 1,
                                              borderBottomWidth: 1,
                                              borderLeftWidth: 1,
                                              borderColor: "rgba(218, 218, 218, 1)",
                                              borderStyle: "solid",
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              width: "260px",
                                              alignItems: "center",
                                              textAlign: "-webkit-right",
                                              fontFamily: "Poppins",
                                              fontWeight: 400,
                                              fontSize: "15px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="437782"
                                            value="[datafield:endDate]"
                                            formatter="datetime"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 400,
                                                fontSize: "15px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          ></Label>
                                        </KTableColumn>

                                        <KTableColumn
                                          id="950839"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            950839,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          style={
                                            {
                                              borderTopWidth: 1,
                                              borderRightWidth: 1,
                                              borderBottomWidth: 1,
                                              borderLeftWidth: 1,
                                              borderColor: "rgba(218, 218, 218, 1)",
                                              borderStyle: "solid",
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              width: "260px",
                                              alignItems: "center",
                                              textAlign: "-webkit-right",
                                              fontFamily: "Poppins",
                                              fontWeight: 400,
                                              fontSize: "15px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="85487"
                                            value="[datafield:submitDate]"
                                            formatter="date"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 400,
                                                fontSize: "15px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          ></Label>
                                        </KTableColumn>

                                        <KTableColumn
                                          id="599455"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            599455,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          style={
                                            {
                                              borderTopWidth: 1,
                                              borderRightWidth: 1,
                                              borderBottomWidth: 1,
                                              borderLeftWidth: 1,
                                              borderColor: "rgba(218, 218, 218, 1)",
                                              borderStyle: "solid",
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-center",
                                              fontFamily: "Poppins",
                                              fontWeight: 400,
                                              fontSize: "15px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <HorizontalStack
                                            id="189362"
                                            direction="horizontal"
                                            size={12}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Poppins",
                                                fontWeight: 400,
                                                fontSize: "15px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          >
                                            <Icon
                                              id="718031"
                                              onClick={(e?: any) => {
                                                if (e && e.stopPropagation) e.stopPropagation();
                                                this.Campaign_ListComponent_718031_onClick();
                                              }}
                                              showCursorPointer
                                              iconName="remove_red_eye"
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 0,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "20px",
                                                  color: "rgba(65, 117, 5, 1)",
                                                  letterSpacing: "1px"
                                                } as any
                                              }
                                            ></Icon>
                                          </HorizontalStack>
                                        </KTableColumn>
                                      </KTableRow>
                                    </KTable>

                                    <KRow
                                      id="897702"
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "flex-start",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Poppins",
                                          fontWeight: 400,
                                          fontSize: "15px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="836325"
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                        xxl={12}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "15px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="798685"
                                          value={ReactSystemFunctions.translate(
                                            this.ml,
                                            798685,
                                            "value",
                                            this.defaultML
                                          )}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              alignItems: "flex-start",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 400,
                                              fontSize: "15px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        ></Label>

                                        <Label
                                          id="474202"
                                          value={this.state.PastCampaignSearchCount?.at?.(0)?.count ?? undefined}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              alignItems: "flex-start",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 400,
                                              fontSize: "15px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        ></Label>
                                      </KCol>

                                      <KCol
                                        id="952730"
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                        xxl={12}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "15px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <KPagination
                                          id="296847"
                                          kuikaRef={this.campaign_list_296847_value_kuikaPaginationRef}
                                          total={this.state.PastCampaignSearchCount?.at?.(0)?.count ?? undefined}
                                          pageSize={20}
                                          defaultCurrent={1}
                                          current={1}
                                          defaultPageSize={10}
                                          disabled={false}
                                          hideOnSinglePage={true}
                                          showLessItems={false}
                                          showQuickJumper={false}
                                          showSizeChanger={false}
                                          showFirstPageButton={false}
                                          showLastPageButton={false}
                                          showNumbers={true}
                                          showText={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              textAlign: "-webkit-right"
                                            } as any
                                          }
                                        ></KPagination>
                                      </KCol>
                                    </KRow>
                                  </KTabItem>
                                </KTab>
                              </Form.Item>
                            </KCol>
                          </KRow>
                        </KCol>
                      </KRow>
                    </KPanel>
                  </KCol>
                </KRow>

                <KDrawer
                  id="508020"
                  placement="left"
                  onClose={() => {
                    this.setState({ NavVisible508020: false }, () => {
                      this.callCallbackFunction(508020);
                    });
                  }}
                  visible={this.state.NavVisible508020}
                  width="320px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible508020 && (
                      <LeftMenu_Screen
                        onClose={() => {
                          this.setState({ NavVisible508020: false }, () => {
                            this.callCallbackFunction(508020);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      fontSize: "15px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="559615"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible559615: false }, () => {
                      this.callCallbackFunction(559615);
                    });
                  }}
                  visible={this.state.NavVisible559615}
                  width="1200px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible559615 && (
                      <Campaign_New_Screen
                        onClose={() => {
                          this.setState({ NavVisible559615: false }, () => {
                            this.callCallbackFunction(559615);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      fontSize: "15px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="467223"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible467223: false }, () => {
                      this.callCallbackFunction(467223);
                    });
                  }}
                  visible={this.state.NavVisible467223}
                  width="1200px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible467223 && (
                      <Campaign_New_Screen
                        onClose={() => {
                          this.setState({ NavVisible467223: false }, () => {
                            this.callCallbackFunction(467223);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      fontSize: "15px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="339341"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible339341: false }, () => {
                      this.callCallbackFunction(339341);
                    });
                  }}
                  visible={this.state.NavVisible339341}
                  width="600px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible339341 && (
                      <Campaign_Details_Screen
                        onClose={() => {
                          this.setState({ NavVisible339341: false }, () => {
                            this.callCallbackFunction(339341);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      fontSize: "15px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="465341"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible465341: false }, () => {
                      this.callCallbackFunction(465341);
                    });
                  }}
                  visible={this.state.NavVisible465341}
                  width="600px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible465341 && (
                      <Campaign_Details_Screen
                        onClose={() => {
                          this.setState({ NavVisible465341: false }, () => {
                            this.callCallbackFunction(465341);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      fontSize: "15px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                ></KDrawer>
              </div>
            </div>
          </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that))
});
const tmp = withGoogleLogin(
  withContext(
    withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(Campaign_List_Screen))))
  )
);
export { tmp as Campaign_List_Screen };
//export default tmp;
//export { tmp as Campaign_List_Screen };
