import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ICustomer_Details_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ICustomer_Details_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  GoBack: any;
  CustomerById: any[];
  ContactByCustomerId: any[];
  CustomerContactsDelete: number;
}

export class Customer_Details_ScreenBase extends React.PureComponent<ICustomer_Details_ScreenProps, any> {
  customer_details_558628_value_kuikaTableRef: React.RefObject<any>;
  customer_details_725114_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "937da27a-1820-c1c3-46e3-ec04ce38ecf6",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "c468f90d-5806-478e-9dcb-9a5f4b03d250",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 276742, PropertyName: "value", Value: "Customer details" },
        { Id: 515701, PropertyName: "label", Value: "My profile" },
        { Id: 520169, PropertyName: "label", Value: "Logout" },
        { Id: 59699, PropertyName: "value", Value: "Edit" },
        { Id: 839719, PropertyName: "value", Value: "Region(s)" },
        { Id: 248481, PropertyName: "tab", Value: "Contacts" },
        { Id: 748147, PropertyName: "label", Value: "NEW CONTACT" },
        { Id: 558628, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 734907, PropertyName: "title", Value: "Full name" },
        { Id: 832559, PropertyName: "value", Value: "[datafield:fullname]" },
        { Id: 380229, PropertyName: "title", Value: "Title" },
        { Id: 844800, PropertyName: "value", Value: "[datafield:contacttitle]" },
        { Id: 595774, PropertyName: "title", Value: "Phone" },
        { Id: 113765, PropertyName: "value", Value: "[datafield:contactphonenumber]" },
        { Id: 583052, PropertyName: "title", Value: "Email" },
        { Id: 161003, PropertyName: "value", Value: "[datafield:contactemail]" },
        { Id: 484445, PropertyName: "title", Value: "#" },
        { Id: 940366, PropertyName: "tab", Value: "Information requests" },
        { Id: 725114, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 494590, PropertyName: "title", Value: "Info. req. ID" },
        { Id: 181120, PropertyName: "value", Value: "INFORMATION-REQ-ID-ABC123456DEF" },
        { Id: 510397, PropertyName: "title", Value: "Date" },
        { Id: 686803, PropertyName: "value", Value: "May 14, 2023" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.customer_details_558628_value_kuikaTableRef = React.createRef();
    this.customer_details_725114_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      GoBack: "",
      CustomerById: [],
      ContactByCustomerId: [],
      CustomerContactsDelete: 0
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("customer_details", "customer_details");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.Customer_DetailsPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("customer_details", "customer_details");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("customer_details", "customer_details");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.Customer_DetailsPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  Customer_DetailsPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      CustomerId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmCustomerId ?? this.props.screenInputs.prmcustomerid,
        "Guid"
      ),
      CustomerId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmCustomerId ?? this.props.screenInputs.prmcustomerid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "Customer_Details/Customer_DetailsPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CustomerById = result?.data.customerById;
    formVars.customer_details_345227_value = ReactSystemFunctions.toString(
      this,
      stateVars.CustomerById?.length > 0 ? stateVars.CustomerById[0]?.title : null
    );
    formVars.customer_details_434008_value = ReactSystemFunctions.toString(
      this,
      stateVars.CustomerById?.length > 0 ? stateVars.CustomerById[0]?.regionsNames : null
    );
    formVars.customer_details_554517_value = ReactSystemFunctions.toString(
      this,
      stateVars.CustomerById?.length > 0 ? stateVars.CustomerById[0]?.phoneNumber1 : null
    );
    formVars.customer_details_348282_value = ReactSystemFunctions.toString(
      this,
      stateVars.CustomerById?.length > 0 ? stateVars.CustomerById[0]?.email : null
    );
    formVars.customer_details_91873_value = ReactSystemFunctions.toString(
      this,
      stateVars.CustomerById?.length > 0 ? stateVars.CustomerById[0]?.address : null
    );
    stateVars.ContactByCustomerId = result?.data.contactByCustomerId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Customer_DetailsPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Customer_DetailsPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.customer_details_345227_value = ReactSystemFunctions.toString(
      this,
      this.state.CustomerById?.length > 0 ? this.state.CustomerById[0]?.title : null
    );

    formVars.customer_details_434008_value = ReactSystemFunctions.toString(
      this,
      this.state.CustomerById?.length > 0 ? this.state.CustomerById[0]?.regionsNames : null
    );

    formVars.customer_details_554517_value = ReactSystemFunctions.toString(
      this,
      this.state.CustomerById?.length > 0 ? this.state.CustomerById[0]?.phoneNumber1 : null
    );

    formVars.customer_details_348282_value = ReactSystemFunctions.toString(
      this,
      this.state.CustomerById?.length > 0 ? this.state.CustomerById[0]?.email : null
    );

    formVars.customer_details_91873_value = ReactSystemFunctions.toString(
      this,
      this.state.CustomerById?.length > 0 ? this.state.CustomerById[0]?.address : null
    );

    stateVars.dataSource_558628 = this.state.ContactByCustomerId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  Customer_DetailsComponent_358559_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Customer_Details",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "368238",
      null,
      "left",
      null,
      "320px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Customer_DetailsComponent_936583_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Customer_DetailsComponent_59699_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "Customer_New",
      "prmCustomerId",
      this.props.screenInputs.prmCustomerId ?? this.props.screenInputs.prmcustomerid
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Customer_Details",
      "Customer_New",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "839549",
      null,
      "right",
      null,
      "500px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Customer_DetailsComponent_748147_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "Contact_New",
      "prmCustomerId",
      this.props.screenInputs.prmCustomerId ?? this.props.screenInputs.prmcustomerid
    );
    KuikaAppManager.addToPageInputVariables("Contact_New", "prmContactId", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables("Contact_New", "prmPurpose", "ADD");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Customer_Details",
      "Contact_New",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "737716",
      null,
      "right",
      null,
      "800px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Customer_DetailsComponent_327093_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "Contact_New",
      "prmCustomerId",
      this.props.screenInputs.prmCustomerId ?? this.props.screenInputs.prmcustomerid
    );
    KuikaAppManager.addToPageInputVariables(
      "Contact_New",
      "prmContactId",
      ReactSystemFunctions.value(this, "customer_details_558628_value", "id")
    );
    KuikaAppManager.addToPageInputVariables("Contact_New", "prmPurpose", "EDIT");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Customer_Details",
      "Contact_New",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "728777",
      null,
      "right",
      null,
      "800px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Customer_DetailsComponent_123429_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "customer_details_558628_value", "id"),
        "Guid"
      ),
      CustomerId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmCustomerId ?? this.props.screenInputs.prmcustomerid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "bf455330_f1bb_d85f_7623_dff71b9d9a9c_confirmation",
        this.defaultML,
        "This contact will be deleted. Are you sure?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    }).then(async (result) => {
      if (result.isConfirmed) {
        KuikaAppManager.showSpinner(this);
        let result = await KuikaAppManager.ApiRequest(
          this,
          "Customer_Details/Customer_DetailsComponent_123429_onClick",
          localVar
        );
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

        stateVars.CustomerContactsDelete = result?.data.customerContactsDelete;
        stateVars.ContactByCustomerId = result?.data.contactByCustomerId;

        this.props.form.setFieldsValue(formVars);
        this.setState(stateVars, () => {
          this.Customer_DetailsComponent_123429_onClick1_();
        });
      }
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Customer_DetailsComponent_123429_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_558628 = this.state.ContactByCustomerId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [368238, 839549, 737716, 728777] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.Customer_DetailsPageInit();
    }
  }
}
