import { Form, Spin, Typography } from "antd";
import { connect } from "react-redux";
import {
  Button,
  Footer,
  Header,
  HorizontalStack,
  Icon,
  Image,
  KCol,
  KPanel,
  KRow,
  Label,
  NumberInput,
  Switch,
  TextArea,
  TextInput,
  VerticalStack
} from "../../components/web-components";
import { KSelectBox } from "../../kuika";
import {
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/photo-take/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { Product_NewCategory_ScreenBase } from "./product_newcategory-base";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;

class Product_NewCategory_Screen extends Product_NewCategory_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <Spin spinning={this.state.spinnerCount > 0}>
          <Form
            form={this.props.form}
            name="basic"
            initialValues={{
              product_newcategory_946233_value: this.state.ProductCategoryById?.at?.(0)?.imageLink ?? undefined,
              product_newcategory_799425_value: this.state.ProductCategoryById?.at?.(0)?.definition ?? undefined,
              product_newcategory_725745_value: this.state.ProductCategoryById?.at?.(0)?.description ?? undefined,
              product_newcategory_839889_value: this.state.ProductCategoryById?.at?.(0)?.categoryType ?? undefined,
              product_newcategory_677483_value: this.state.ProductById?.at?.(0)?.productCode ?? undefined,
              product_newcategory_692755_value: this.state.ProductById?.at?.(0)?.netGramaj ?? undefined,
              product_newcategory_136786_value: this.state.ProductById?.at?.(0)?.piecesInBox ?? undefined,
              product_newcategory_323676_value: this.state.ProductById?.at?.(0)?.volume ?? undefined,
              product_newcategory_479072_value: this.state.ProductById?.at?.(0)?.parcelnBox ?? undefined,
              product_newcategory_638517_value: this.state.ProductCategoryById?.at?.(0)?.parentBrandId ?? undefined,
              product_newcategory_729758_value: this.state.ProductCategoryById?.at?.(0)?.subBrandId ?? undefined,
              product_newcategory_552680_value: this.state.ProductCategoryById?.at?.(0)?.titleTag ?? undefined,
              product_newcategory_644881_value: this.state.ProductCategoryById?.at?.(0)?.metaDescription ?? undefined,
              product_newcategory_172884_value: this.state.ProductCategoryById?.at?.(0)?.isPublic ?? undefined,
              product_newcategory_574544_value: this.state.ProductCategoryById?.at?.(0)?.isActive ?? undefined
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
              <Header
                id="product_newcategory_header"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left",
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    fontFamily: "Poppins",
                    fontWeight: 400,
                    fontSize: "15px",
                    color: "rgba(68, 68, 68, 1)",
                    lineHeight: "22px"
                  } as any
                }
              >
                <KPanel
                  id="712183"
                  scrolling="hidden"
                  scrollRadius={false}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      maxWidth: "1240px",
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      fontSize: "15px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                >
                  <KRow
                    id="28429"
                    align="top"
                    justify="start"
                    horizontalGutter={0}
                    verticalGutter={0}
                    style={
                      {
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 12,
                        borderBottomLeftRadius: 12,
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        paddingTop: 16,
                        paddingRight: 16,
                        paddingBottom: 16,
                        paddingLeft: 16,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: "15px",
                        color: "rgba(68, 68, 68, 1)",
                        lineHeight: "22px"
                      } as any
                    }
                  >
                    <KCol
                      id="56423"
                      xs={20}
                      sm={20}
                      md={20}
                      lg={20}
                      xl={20}
                      xxl={20}
                      order={0}
                      pull={0}
                      push={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: "15px",
                          color: "rgba(68, 68, 68, 1)",
                          lineHeight: "22px"
                        } as any
                      }
                    >
                      <Label
                        id="607465"
                        value={ReactSystemFunctions.translate(this.ml, 607465, "value", this.defaultML)}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Poppins",
                            fontWeight: 300,
                            fontSize: "24px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      ></Label>
                    </KCol>

                    <KCol
                      id="762633"
                      xs={4}
                      sm={4}
                      md={4}
                      lg={4}
                      xl={4}
                      xxl={4}
                      order={0}
                      pull={0}
                      push={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 8,
                          paddingRight: 8,
                          paddingBottom: 8,
                          paddingLeft: 8,
                          alignItems: "flex-start",
                          textAlign: "-webkit-right",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: "15px",
                          color: "rgba(68, 68, 68, 1)",
                          lineHeight: "22px"
                        } as any
                      }
                    >
                      <Icon
                        id="419961"
                        onClick={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.Product_NewCategoryComponent_419961_onClick();
                        }}
                        showCursorPointer
                        iconName="close"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "32px",
                            color: "rgba(0, 117, 255, 1)",
                            letterSpacing: "1px"
                          } as any
                        }
                      ></Icon>
                    </KCol>
                  </KRow>
                </KPanel>
              </Header>

              <div
                id="product_newcategory_body"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(247, 247, 247, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left",
                    display: "block"
                  } as any
                }
              >
                <KRow
                  id="442131"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 16,
                      paddingRight: 16,
                      paddingBottom: 16,
                      paddingLeft: 16,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      fontSize: "15px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                >
                  <KCol
                    id="81663"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderTopLeftRadius: 12,
                        borderTopRightRadius: 12,
                        borderBottomRightRadius: 12,
                        borderBottomLeftRadius: 12,
                        borderRightWidth: 4,
                        borderColor: "rgba(247, 247, 247, 1)",
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: "15px",
                        color: "rgba(68, 68, 68, 1)",
                        lineHeight: "22px"
                      } as any
                    }
                  >
                    <KRow
                      id="671169"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 8,
                          paddingRight: 8,
                          paddingBottom: 8,
                          paddingLeft: 8,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: "15px",
                          color: "rgba(68, 68, 68, 1)",
                          lineHeight: "22px"
                        } as any
                      }
                    >
                      <KCol
                        id="808183"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: "15px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      >
                        <VerticalStack
                          id="971215"
                          direction="vertical"
                          size={12}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              textAlign: "-webkit-center",
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: "15px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        >
                          <Form.Item className="kFormItem" name="product_newcategory_946233_value">
                            <Image
                              id="946233"
                              zoomOnClick={false}
                              imageFit="fill"
                              style={
                                {
                                  borderTopLeftRadius: 4,
                                  borderTopRightRadius: 4,
                                  borderBottomRightRadius: 4,
                                  borderBottomLeftRadius: 4,
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  width: "164px",
                                  height: "164px",
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 400,
                                  fontSize: "15px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            ></Image>
                          </Form.Item>

                          <Label
                            id="960354"
                            onClick={(e?: any) => {
                              if (e && e.stopPropagation) e.stopPropagation();
                              this.Product_NewCategoryComponent_960354_onClick();
                            }}
                            showCursorPointer
                            value={ReactSystemFunctions.translate(this.ml, 960354, "value", this.defaultML)}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(0, 117, 255, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          ></Label>
                        </VerticalStack>
                      </KCol>
                    </KRow>

                    <KRow
                      id="317404"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 8,
                          paddingRight: 8,
                          paddingBottom: 8,
                          paddingLeft: 8,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: "15px",
                          color: "rgba(68, 68, 68, 1)",
                          lineHeight: "22px"
                        } as any
                      }
                    >
                      <KCol
                        id="811842"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: "15px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      >
                        <Label
                          id="720621"
                          value={ReactSystemFunctions.translate(this.ml, 720621, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: "15px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        ></Label>

                        <Label
                          id="392772"
                          value={ReactSystemFunctions.translate(this.ml, 392772, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 2,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: "15px",
                              color: "rgba(208, 2, 27, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        ></Label>

                        <Form.Item className="kFormItem" name="product_newcategory_799425_value">
                          <TextInput
                            id="799425"
                            placeholder={ReactSystemFunctions.translate(this.ml, 799425, "placeholder", this.defaultML)}
                            allowClear={false}
                            bordered={true}
                            disabled={false}
                            type="text"
                            iconColor="rgba(0, 0, 0, 1)"
                            formatter="None"
                            isCharOnly={false}
                            style={
                              {
                                borderTopLeftRadius: 8,
                                borderTopRightRadius: 8,
                                borderBottomRightRadius: 8,
                                borderBottomLeftRadius: 8,
                                borderTopWidth: 1,
                                borderRightWidth: 1,
                                borderBottomWidth: 1,
                                borderLeftWidth: 1,
                                borderColor: "rgba(218, 218, 218, 1)",
                                borderStyle: "solid",
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                height: "44px",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "15px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          ></TextInput>
                        </Form.Item>
                      </KCol>

                      <KCol
                        id="61365"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: "15px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      >
                        <Label
                          id="511383"
                          value={ReactSystemFunctions.translate(this.ml, 511383, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: "15px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        ></Label>

                        <Label
                          id="432321"
                          value={ReactSystemFunctions.translate(this.ml, 432321, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 2,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: "15px",
                              color: "rgba(208, 2, 27, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        ></Label>

                        <Form.Item className="kFormItem" name="product_newcategory_725745_value">
                          <TextArea
                            id="725745"
                            placeholder={ReactSystemFunctions.translate(this.ml, 725745, "placeholder", this.defaultML)}
                            allowClear={false}
                            autoSize={false}
                            showCount={false}
                            formatter="None"
                            readOnly={false}
                            style={
                              {
                                borderTopLeftRadius: 8,
                                borderTopRightRadius: 8,
                                borderBottomRightRadius: 8,
                                borderBottomLeftRadius: 8,
                                borderTopWidth: 1,
                                borderRightWidth: 1,
                                borderBottomWidth: 1,
                                borderLeftWidth: 1,
                                borderColor: "rgba(218, 218, 218, 1)",
                                borderStyle: "solid",
                                backgroundColor: "rgba(255, 255, 255, 1)",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "15px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          ></TextArea>
                        </Form.Item>
                      </KCol>

                      <KCol
                        id="909974"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: "15px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      >
                        <Label
                          id="316819"
                          value={ReactSystemFunctions.translate(this.ml, 316819, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: "15px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        ></Label>

                        <Label
                          id="859201"
                          value={ReactSystemFunctions.translate(this.ml, 859201, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 2,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: "15px",
                              color: "rgba(208, 2, 27, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        ></Label>

                        <Form.Item className="kFormItem" name="product_newcategory_839889_value">
                          <KSelectBox
                            id="839889"
                            kuikaRef={this.product_newcategory_839889_value_kuikaSelectBoxRef}
                            options={this.state.CategoryType}
                            placeholder={ReactSystemFunctions.translate(this.ml, 839889, "placeholder", this.defaultML)}
                            allowClear={false}
                            autoClearSearchValue={true}
                            showSearch={false}
                            widthType="fill"
                            containsNullItem={false}
                            sortBy="none"
                            datavaluefield="name"
                            datatextfield="name"
                            style={
                              {
                                borderTopLeftRadius: 8,
                                borderTopRightRadius: 8,
                                borderBottomRightRadius: 8,
                                borderBottomLeftRadius: 8,
                                borderTopWidth: 1,
                                borderRightWidth: 1,
                                borderBottomWidth: 1,
                                borderLeftWidth: 1,
                                borderColor: "rgba(218, 218, 218, 1)",
                                borderStyle: "solid",
                                backgroundColor: "rgba(255, 255, 255, 1)",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "15px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          ></KSelectBox>
                        </Form.Item>
                      </KCol>
                    </KRow>

                    <KRow
                      id="113084"
                      visibility={this.state.isComp113084Visible}
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 8,
                          paddingRight: 8,
                          paddingBottom: 8,
                          paddingLeft: 8,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: "15px",
                          color: "rgba(68, 68, 68, 1)",
                          lineHeight: "22px"
                        } as any
                      }
                    >
                      <KCol
                        id="549415"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: "15px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      >
                        <Label
                          id="765167"
                          value={ReactSystemFunctions.translate(this.ml, 765167, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: "15px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        ></Label>

                        <Label
                          id="431828"
                          value={ReactSystemFunctions.translate(this.ml, 431828, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 2,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: "15px",
                              color: "rgba(208, 2, 27, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        ></Label>

                        <Form.Item className="kFormItem" name="product_newcategory_677483_value">
                          <TextInput
                            id="677483"
                            placeholder={ReactSystemFunctions.translate(this.ml, 677483, "placeholder", this.defaultML)}
                            allowClear={false}
                            bordered={true}
                            disabled={false}
                            type="text"
                            iconColor="rgba(0, 0, 0, 1)"
                            formatter="None"
                            isCharOnly={false}
                            style={
                              {
                                borderTopLeftRadius: 8,
                                borderTopRightRadius: 8,
                                borderBottomRightRadius: 8,
                                borderBottomLeftRadius: 8,
                                borderTopWidth: 1,
                                borderRightWidth: 1,
                                borderBottomWidth: 1,
                                borderLeftWidth: 1,
                                borderColor: "rgba(218, 218, 218, 1)",
                                borderStyle: "solid",
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "15px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          ></TextInput>
                        </Form.Item>
                      </KCol>

                      <KCol
                        id="536884"
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                        xxl={6}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: "15px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      >
                        <Label
                          id="970882"
                          value={ReactSystemFunctions.translate(this.ml, 970882, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: "15px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        ></Label>

                        <Label
                          id="198328"
                          value={ReactSystemFunctions.translate(this.ml, 198328, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 2,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: "15px",
                              color: "rgba(208, 2, 27, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        ></Label>

                        <Form.Item className="kFormItem" name="product_newcategory_692755_value">
                          <NumberInput
                            id="692755"
                            decimalSeparator="."
                            decimalScale={2}
                            disabled={false}
                            placeholder={ReactSystemFunctions.translate(this.ml, 692755, "placeholder", this.defaultML)}
                            fixedDecimalScale={false}
                            allowNegative={true}
                            allowEmptyFormatting={false}
                            isThousandSeperatorOn={true}
                            style={
                              {
                                borderTopLeftRadius: 8,
                                borderTopRightRadius: 8,
                                borderBottomRightRadius: 8,
                                borderBottomLeftRadius: 8,
                                borderTopWidth: 1,
                                borderRightWidth: 1,
                                borderBottomWidth: 1,
                                borderLeftWidth: 1,
                                borderColor: "rgba(218, 218, 218, 1)",
                                borderStyle: "solid",
                                backgroundColor: "rgba(255, 255, 255, 1)",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "15px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          ></NumberInput>
                        </Form.Item>
                      </KCol>

                      <KCol
                        id="39466"
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                        xxl={6}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: "15px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      >
                        <Label
                          id="211697"
                          value={ReactSystemFunctions.translate(this.ml, 211697, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: "15px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        ></Label>

                        <Form.Item className="kFormItem" name="product_newcategory_136786_value">
                          <NumberInput
                            id="136786"
                            decimalSeparator="."
                            decimalScale={2}
                            disabled={false}
                            placeholder={ReactSystemFunctions.translate(this.ml, 136786, "placeholder", this.defaultML)}
                            fixedDecimalScale={false}
                            allowNegative={true}
                            allowEmptyFormatting={false}
                            isThousandSeperatorOn={true}
                            style={
                              {
                                borderTopLeftRadius: 8,
                                borderTopRightRadius: 8,
                                borderBottomRightRadius: 8,
                                borderBottomLeftRadius: 8,
                                borderTopWidth: 1,
                                borderRightWidth: 1,
                                borderBottomWidth: 1,
                                borderLeftWidth: 1,
                                borderColor: "rgba(218, 218, 218, 1)",
                                borderStyle: "solid",
                                backgroundColor: "rgba(255, 255, 255, 1)",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "15px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          ></NumberInput>
                        </Form.Item>
                      </KCol>

                      <KCol
                        id="967635"
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                        xxl={6}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: "15px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      >
                        <Label
                          id="298322"
                          value={ReactSystemFunctions.translate(this.ml, 298322, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: "15px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        ></Label>

                        <Label
                          id="521684"
                          value={ReactSystemFunctions.translate(this.ml, 521684, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 2,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: "15px",
                              color: "rgba(208, 2, 27, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        ></Label>

                        <Form.Item className="kFormItem" name="product_newcategory_323676_value">
                          <NumberInput
                            id="323676"
                            decimalSeparator="."
                            decimalScale={5}
                            disabled={false}
                            placeholder={ReactSystemFunctions.translate(this.ml, 323676, "placeholder", this.defaultML)}
                            fixedDecimalScale={false}
                            allowNegative={true}
                            allowEmptyFormatting={false}
                            isThousandSeperatorOn={true}
                            style={
                              {
                                borderTopLeftRadius: 8,
                                borderTopRightRadius: 8,
                                borderBottomRightRadius: 8,
                                borderBottomLeftRadius: 8,
                                borderTopWidth: 1,
                                borderRightWidth: 1,
                                borderBottomWidth: 1,
                                borderLeftWidth: 1,
                                borderColor: "rgba(218, 218, 218, 1)",
                                borderStyle: "solid",
                                backgroundColor: "rgba(255, 255, 255, 1)",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "15px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          ></NumberInput>
                        </Form.Item>
                      </KCol>

                      <KCol
                        id="989072"
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                        xxl={6}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: "15px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      >
                        <Label
                          id="510239"
                          value={ReactSystemFunctions.translate(this.ml, 510239, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: "15px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        ></Label>

                        <Label
                          id="550266"
                          value={ReactSystemFunctions.translate(this.ml, 550266, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 2,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: "15px",
                              color: "rgba(208, 2, 27, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        ></Label>

                        <Form.Item className="kFormItem" name="product_newcategory_479072_value">
                          <NumberInput
                            id="479072"
                            decimalSeparator="."
                            decimalScale={2}
                            disabled={false}
                            placeholder={ReactSystemFunctions.translate(this.ml, 479072, "placeholder", this.defaultML)}
                            fixedDecimalScale={false}
                            allowNegative={true}
                            allowEmptyFormatting={false}
                            isThousandSeperatorOn={true}
                            style={
                              {
                                borderTopLeftRadius: 8,
                                borderTopRightRadius: 8,
                                borderBottomRightRadius: 8,
                                borderBottomLeftRadius: 8,
                                borderTopWidth: 1,
                                borderRightWidth: 1,
                                borderBottomWidth: 1,
                                borderLeftWidth: 1,
                                borderColor: "rgba(218, 218, 218, 1)",
                                borderStyle: "solid",
                                backgroundColor: "rgba(255, 255, 255, 1)",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "15px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          ></NumberInput>
                        </Form.Item>
                      </KCol>
                    </KRow>

                    <KRow
                      id="695052"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 8,
                          paddingRight: 8,
                          paddingBottom: 8,
                          paddingLeft: 8,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: "15px",
                          color: "rgba(68, 68, 68, 1)",
                          lineHeight: "22px"
                        } as any
                      }
                    >
                      <KCol
                        id="367300"
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: "15px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      >
                        <Label
                          id="530804"
                          value={ReactSystemFunctions.translate(this.ml, 530804, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: "15px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        ></Label>

                        <Label
                          id="343362"
                          value={ReactSystemFunctions.translate(this.ml, 343362, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 2,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: "15px",
                              color: "rgba(208, 2, 27, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        ></Label>

                        <Form.Item className="kFormItem" name="product_newcategory_638517_value">
                          <KSelectBox
                            id="638517"
                            onChange={(e?: any) => {
                              if (e && e.stopPropagation) e.stopPropagation();
                              this.Product_NewCategoryComponent_638517_onChange();
                            }}
                            kuikaRef={this.product_newcategory_638517_value_kuikaSelectBoxRef}
                            options={this.state.ParentBrand}
                            placeholder={ReactSystemFunctions.translate(this.ml, 638517, "placeholder", this.defaultML)}
                            allowClear={true}
                            autoClearSearchValue={true}
                            showSearch={false}
                            widthType="fill"
                            containsNullItem={false}
                            sortBy="none"
                            datavaluefield="id"
                            datatextfield="name"
                            style={
                              {
                                borderTopLeftRadius: 8,
                                borderTopRightRadius: 8,
                                borderBottomRightRadius: 8,
                                borderBottomLeftRadius: 8,
                                borderTopWidth: 1,
                                borderRightWidth: 1,
                                borderBottomWidth: 1,
                                borderLeftWidth: 1,
                                borderColor: "rgba(218, 218, 218, 1)",
                                borderStyle: "solid",
                                backgroundColor: "rgba(255, 255, 255, 1)",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                height: "44px",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "15px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          ></KSelectBox>
                        </Form.Item>
                      </KCol>

                      <KCol
                        id="14"
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: "15px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      >
                        <Label
                          id="843013"
                          value={ReactSystemFunctions.translate(this.ml, 843013, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: "15px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        ></Label>

                        <Label
                          id="190552"
                          value={ReactSystemFunctions.translate(this.ml, 190552, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 2,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: "15px",
                              color: "rgba(208, 2, 27, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        ></Label>

                        <Form.Item className="kFormItem" name="product_newcategory_729758_value">
                          <KSelectBox
                            id="729758"
                            kuikaRef={this.product_newcategory_729758_value_kuikaSelectBoxRef}
                            options={this.state.BrandByParentBrandId}
                            placeholder={ReactSystemFunctions.translate(this.ml, 729758, "placeholder", this.defaultML)}
                            allowClear={true}
                            autoClearSearchValue={true}
                            showSearch={false}
                            widthType="fill"
                            containsNullItem={false}
                            sortBy="none"
                            datavaluefield="id"
                            datatextfield="name"
                            style={
                              {
                                borderTopLeftRadius: 8,
                                borderTopRightRadius: 8,
                                borderBottomRightRadius: 8,
                                borderBottomLeftRadius: 8,
                                borderTopWidth: 1,
                                borderRightWidth: 1,
                                borderBottomWidth: 1,
                                borderLeftWidth: 1,
                                borderColor: "rgba(218, 218, 218, 1)",
                                borderStyle: "solid",
                                backgroundColor: "rgba(255, 255, 255, 1)",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                height: "44px",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "15px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          ></KSelectBox>
                        </Form.Item>
                      </KCol>
                    </KRow>

                    <KRow
                      id="848792"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 8,
                          paddingRight: 8,
                          paddingBottom: 8,
                          paddingLeft: 8,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: "15px",
                          color: "rgba(68, 68, 68, 1)",
                          lineHeight: "22px"
                        } as any
                      }
                    >
                      <KCol
                        id="101559"
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: "15px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      >
                        <Label
                          id="793949"
                          value={ReactSystemFunctions.translate(this.ml, 793949, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: "15px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        ></Label>

                        <Label
                          id="252348"
                          value={ReactSystemFunctions.translate(this.ml, 252348, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 2,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: "15px",
                              color: "rgba(208, 2, 27, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        ></Label>

                        <Form.Item className="kFormItem" name="product_newcategory_552680_value">
                          <TextInput
                            id="552680"
                            placeholder={ReactSystemFunctions.translate(this.ml, 552680, "placeholder", this.defaultML)}
                            allowClear={false}
                            bordered={true}
                            disabled={false}
                            type="text"
                            iconColor="rgba(0, 0, 0, 1)"
                            formatter="None"
                            isCharOnly={false}
                            style={
                              {
                                borderTopLeftRadius: 8,
                                borderTopRightRadius: 8,
                                borderBottomRightRadius: 8,
                                borderBottomLeftRadius: 8,
                                borderTopWidth: 1,
                                borderRightWidth: 1,
                                borderBottomWidth: 1,
                                borderLeftWidth: 1,
                                borderColor: "rgba(218, 218, 218, 1)",
                                borderStyle: "solid",
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "15px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          ></TextInput>
                        </Form.Item>
                      </KCol>

                      <KCol
                        id="987086"
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: "15px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      >
                        <Label
                          id="472388"
                          value={ReactSystemFunctions.translate(this.ml, 472388, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: "15px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        ></Label>

                        <Label
                          id="813450"
                          value={ReactSystemFunctions.translate(this.ml, 813450, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 2,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: "15px",
                              color: "rgba(208, 2, 27, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        ></Label>

                        <Form.Item className="kFormItem" name="product_newcategory_644881_value">
                          <TextInput
                            id="644881"
                            placeholder={ReactSystemFunctions.translate(this.ml, 644881, "placeholder", this.defaultML)}
                            allowClear={false}
                            bordered={true}
                            disabled={false}
                            type="text"
                            iconColor="rgba(0, 0, 0, 1)"
                            formatter="None"
                            isCharOnly={false}
                            style={
                              {
                                borderTopLeftRadius: 8,
                                borderTopRightRadius: 8,
                                borderBottomRightRadius: 8,
                                borderBottomLeftRadius: 8,
                                borderTopWidth: 1,
                                borderRightWidth: 1,
                                borderBottomWidth: 1,
                                borderLeftWidth: 1,
                                borderColor: "rgba(218, 218, 218, 1)",
                                borderStyle: "solid",
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "15px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          ></TextInput>
                        </Form.Item>
                      </KCol>
                    </KRow>

                    <KRow
                      id="74686"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 8,
                          paddingRight: 8,
                          paddingBottom: 8,
                          paddingLeft: 8,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: "15px",
                          color: "rgba(68, 68, 68, 1)",
                          lineHeight: "22px"
                        } as any
                      }
                    >
                      <KCol
                        id="583140"
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: "15px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      >
                        <KRow
                          id="995745"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: "15px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        >
                          <KCol
                            id="110681"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={24}
                            xxl={24}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "15px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          >
                            <HorizontalStack
                              id="246422"
                              direction="horizontal"
                              size={12}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 400,
                                  fontSize: "15px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            >
                              <Label
                                id="544731"
                                value={ReactSystemFunctions.translate(this.ml, 544731, "value", this.defaultML)}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 400,
                                    fontSize: "15px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              ></Label>

                              <Form.Item className="kFormItem" name="product_newcategory_172884_value">
                                <Switch
                                  id="172884"
                                  disabled={false}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "15px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                ></Switch>
                              </Form.Item>
                            </HorizontalStack>
                          </KCol>
                        </KRow>
                      </KCol>

                      <KCol
                        id="738424"
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: "15px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      >
                        <KRow
                          id="242213"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: "15px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        >
                          <KCol
                            id="776789"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "15px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          >
                            <HorizontalStack
                              id="414802"
                              direction="horizontal"
                              size={12}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 50,
                                  fontFamily: "Poppins",
                                  fontWeight: 400,
                                  fontSize: "15px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            >
                              <Label
                                id="62846"
                                value={ReactSystemFunctions.translate(this.ml, 62846, "value", this.defaultML)}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 400,
                                    fontSize: "15px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              ></Label>

                              <Form.Item className="kFormItem" name="product_newcategory_574544_value">
                                <Switch
                                  id="574544"
                                  disabled={false}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "15px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                ></Switch>
                              </Form.Item>
                            </HorizontalStack>
                          </KCol>
                        </KRow>
                      </KCol>
                    </KRow>
                  </KCol>
                </KRow>
              </div>

              <Footer
                id="product_newcategory_footer"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left",
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    fontFamily: "Poppins",
                    fontWeight: 400,
                    fontSize: "15px",
                    color: "rgba(68, 68, 68, 1)",
                    lineHeight: "22px"
                  } as any
                }
              >
                <KRow
                  id="344650"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 16,
                      paddingBottom: 8,
                      paddingLeft: 16,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      fontSize: "15px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                >
                  <KCol
                    id="632181"
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: "15px",
                        color: "rgba(68, 68, 68, 1)",
                        lineHeight: "22px"
                      } as any
                    }
                  >
                    <Button
                      id="20332"
                      onClick={(e?: any) => {
                        if (e && e.stopPropagation) e.stopPropagation();
                        this.Product_NewCategoryComponent_20332_onClick();
                      }}
                      showCursorPointer
                      visibility={this.state.isComp20332Visible}
                      label={ReactSystemFunctions.translate(this.ml, 20332, "label", this.defaultML)}
                      size="middle"
                      loading={false}
                      ghost={false}
                      block={false}
                      htmlType="button"
                      iconPosition="left"
                      danger={false}
                      style={
                        {
                          borderTopLeftRadius: 8,
                          borderTopRightRadius: 8,
                          borderBottomRightRadius: 8,
                          borderBottomLeftRadius: 8,
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundColor: "rgba(245, 34, 45, 1)",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 8,
                          paddingRight: 16,
                          paddingBottom: 8,
                          paddingLeft: 16,
                          height: "40px",
                          alignItems: "center",
                          textAlign: "-webkit-center",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "16px",
                          color: "rgba(255, 255, 255, 1)",
                          lineHeight: "22px"
                        } as any
                      }
                    ></Button>
                  </KCol>

                  <KCol
                    id="870257"
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "center",
                        textAlign: "-webkit-right",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: "15px",
                        color: "rgba(68, 68, 68, 1)",
                        lineHeight: "22px"
                      } as any
                    }
                  >
                    <Button
                      id="619802"
                      onClick={(e?: any) => {
                        if (e && e.stopPropagation) e.stopPropagation();
                        this.Product_NewCategoryComponent_619802_onClick();
                      }}
                      showCursorPointer
                      label={ReactSystemFunctions.translate(this.ml, 619802, "label", this.defaultML)}
                      size="middle"
                      loading={false}
                      ghost={false}
                      block={false}
                      htmlType="button"
                      iconPosition="left"
                      danger={false}
                      style={
                        {
                          borderTopLeftRadius: 8,
                          borderTopRightRadius: 8,
                          borderBottomRightRadius: 8,
                          borderBottomLeftRadius: 8,
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundColor: "rgba(3, 88, 255, 1)",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 8,
                          paddingRight: 16,
                          paddingBottom: 8,
                          paddingLeft: 16,
                          height: "40px",
                          alignItems: "center",
                          textAlign: "-webkit-center",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "16px",
                          color: "rgba(255, 255, 255, 1)",
                          lineHeight: "22px"
                        } as any
                      }
                    ></Button>
                  </KCol>
                </KRow>
              </Footer>
            </div>
          </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that))
});
const tmp = withGoogleLogin(
  withContext(
    withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(Product_NewCategory_Screen))))
  )
);
export { tmp as Product_NewCategory_Screen };
//export default tmp;
//export { tmp as Product_NewCategory_Screen };
