import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IProduct_Details_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IProduct_Details_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  LanguageByLang: any[];
  ProductCategoryById: any[];
  ProductListByProductCategoryId: any[];
  RegionByProductId: any[];
  RegionByNotMatchProductId: any[];
  ChangeEnabledOf: any;
  ProductDelete: number;
  ProductByWebSave: any[];
  ProductCategoryTranslationDelete: any[];
  ProductPublicInfoUpdate: number;
  isComp371531Visible: "visible" | "hidden";
  isComp613436Visible: "visible" | "hidden";
  isComp546777Visible: "visible" | "hidden";
  isComp180847Enabled: "enabled" | "disabled";
  isComp444639Enabled: "enabled" | "disabled";
}

export class Product_Details_ScreenBase extends React.PureComponent<IProduct_Details_ScreenProps, any> {
  product_details_722378_value_kuikaTableRef: React.RefObject<any>;
  product_details_841137_value_kuikaTableRef: React.RefObject<any>;
  product_details_180847_value_kuikaSelectBoxRef: React.RefObject<any>;
  product_details_675061_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "937da27a-1820-c1c3-46e3-ec04ce38ecf6",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "45700eed-612c-433a-9e79-1ff46d494487",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 811807, PropertyName: "value", Value: "Product group details" },
        { Id: 384517, PropertyName: "label", Value: "My profile" },
        { Id: 89253, PropertyName: "label", Value: "Logout" },
        { Id: 569640, PropertyName: "value", Value: "Edit Product Group" },
        { Id: 829141, PropertyName: "value", Value: "gr" },
        { Id: 255017, PropertyName: "value", Value: "-" },
        { Id: 553151, PropertyName: "value", Value: "peieces" },
        { Id: 483029, PropertyName: "value", Value: "PRODUCT CODE" },
        { Id: 866893, PropertyName: "value", Value: "Product Variants" },
        { Id: 967619, PropertyName: "value", Value: "Add Product Variant" },
        { Id: 722378, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 666785, PropertyName: "title", Value: "Code" },
        { Id: 33854, PropertyName: "value", Value: "[datafield:productcode]" },
        { Id: 134898, PropertyName: "title", Value: "Weight(gr)" },
        { Id: 335051, PropertyName: "value", Value: "[datafield:netgramaj]" },
        { Id: 267086, PropertyName: "title", Value: "Pieces" },
        { Id: 740965, PropertyName: "value", Value: "[datafield:piecesinbox]" },
        { Id: 586715, PropertyName: "title", Value: "Volume" },
        { Id: 796551, PropertyName: "value", Value: "[datafield:volume]" },
        { Id: 704918, PropertyName: "title", Value: "In Parcel" },
        { Id: 298917, PropertyName: "value", Value: "[datafield:parcelnbox]" },
        { Id: 515784, PropertyName: "title", Value: "Active" },
        { Id: 995325, PropertyName: "title", Value: "#" },
        { Id: 750885, PropertyName: "tab", Value: "Translation" },
        {
          Id: 936028,
          PropertyName: "title",
          Value:
            "Display setting on your website. It is determined automatically according to the translation you entered."
        },
        { Id: 69060, PropertyName: "value", Value: "Show website" },
        { Id: 698236, PropertyName: "title", Value: "Propmt Text" },
        { Id: 145993, PropertyName: "value", Value: "English" },
        { Id: 625630, PropertyName: "title", Value: "Propmt Text" },
        { Id: 790145, PropertyName: "value", Value: "Turkish" },
        { Id: 486726, PropertyName: "title", Value: "Propmt Text" },
        { Id: 946301, PropertyName: "value", Value: "French" },
        { Id: 762411, PropertyName: "title", Value: "Propmt Text" },
        { Id: 940079, PropertyName: "value", Value: "Arabic" },
        { Id: 841137, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 1833, PropertyName: "title", Value: "Language" },
        { Id: 462650, PropertyName: "value", Value: "[datafield:language]" },
        { Id: 55980, PropertyName: "title", Value: "Translation" },
        { Id: 59621, PropertyName: "value", Value: "[datafield:definition]" },
        { Id: 446821, PropertyName: "value", Value: "[datafield:description]" },
        { Id: 272409, PropertyName: "title", Value: "Title" },
        { Id: 514483, PropertyName: "title", Value: "Edit" },
        { Id: 313844, PropertyName: "title", Value: "Delete" },
        { Id: 423689, PropertyName: "label", Value: "ADD NEW TRANSLATION" },
        { Id: 546777, PropertyName: "tab", Value: "Regions" },
        { Id: 180847, PropertyName: "placeholder", Value: "Select and add regions..." },
        { Id: 482408, PropertyName: "title", Value: "Propmt Text" },
        { Id: 37506, PropertyName: "value", Value: "Public" },
        { Id: 675061, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 393189, PropertyName: "title", Value: "isActive" },
        { Id: 67066, PropertyName: "title", Value: "Name" },
        { Id: 258989, PropertyName: "value", Value: "[datafield:definition]" },
        { Id: 285586, PropertyName: "title", Value: "Title" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.product_details_722378_value_kuikaTableRef = React.createRef();
    this.product_details_841137_value_kuikaTableRef = React.createRef();
    this.product_details_180847_value_kuikaSelectBoxRef = React.createRef();
    this.product_details_675061_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      LanguageByLang: [],
      ProductCategoryById: [],
      ProductListByProductCategoryId: [],
      RegionByProductId: [],
      RegionByNotMatchProductId: [],
      ChangeEnabledOf: "",
      ProductDelete: 0,
      ProductByWebSave: [],
      ProductCategoryTranslationDelete: [],
      ProductPublicInfoUpdate: 0,
      isComp371531Visible: "hidden",
      isComp613436Visible: "hidden",
      isComp546777Visible: "hidden",
      isComp180847Enabled: "enabled",
      isComp444639Enabled: "enabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("product_details", "product_details");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.Product_DetailsPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("product_details", "product_details");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("product_details", "product_details");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.Product_DetailsPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      product_details_443286_value: this.state.ProductCategoryById?.at?.(0)?.imageLink ?? undefined,
      product_details_86423_value: this.state.ProductCategoryById?.at?.(0)?.isWebEn ?? undefined,
      product_details_834087_value: this.state.ProductCategoryById?.at?.(0)?.isWebTr ?? undefined,
      product_details_932099_value: this.state.ProductCategoryById?.at?.(0)?.isWebFr ?? undefined,
      product_details_299888_value: this.state.ProductCategoryById?.at?.(0)?.isWebAr ?? undefined,
      product_details_909820_value: undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  Product_DetailsPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Lang_0: ReactSystemFunctions.convertToTypeByName("EN", "string"),
      LangId_1: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      ProductCategoryId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProductCategoryId ?? this.props.screenInputs.prmproductcategoryid,
        "Guid"
      ),
      ProductCategoryId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProductCategoryId ?? this.props.screenInputs.prmproductcategoryid,
        "Guid"
      ),
      ProductCategoryId_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProductCategoryId ?? this.props.screenInputs.prmproductcategoryid,
        "Guid"
      ),
      ProductCategoryId_4: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProductCategoryId ?? this.props.screenInputs.prmproductcategoryid,
        "Guid"
      ),
      isActive_4: ReactSystemFunctions.convertToTypeByName(true, "boolean")
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "Product_Details/Product_DetailsPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.LanguageByLang = result?.data.languageByLang;
    stateVars.ProductCategoryById = result?.data.productCategoryById;

    formVars.product_details_443286_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProductCategoryById?.length > 0 ? stateVars.ProductCategoryById[0]?.imageLink : null
    );
    formVars.product_details_443286_placeholderimage = "";
    formVars.product_details_839719_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProductCategoryById?.length > 0 ? stateVars.ProductCategoryById[0]?.parentName : null
    );
    formVars.product_details_710309_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProductCategoryById?.length > 0 ? stateVars.ProductCategoryById[0]?.subBrandName : null
    );
    formVars.product_details_345227_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProductCategoryById?.length > 0 ? stateVars.ProductCategoryById[0]?.definition : null
    );
    formVars.product_details_86423_value = ReactSystemFunctions.value(
      this,
      stateVars.ProductCategoryById?.length > 0 ? stateVars.ProductCategoryById[0]?.isWebEn : null
    );
    formVars.product_details_834087_value = ReactSystemFunctions.value(
      this,
      stateVars.ProductCategoryById?.length > 0 ? stateVars.ProductCategoryById[0]?.isWebTr : null
    );
    formVars.product_details_932099_value = ReactSystemFunctions.value(
      this,
      stateVars.ProductCategoryById?.length > 0 ? stateVars.ProductCategoryById[0]?.isWebFr : null
    );
    formVars.product_details_299888_value = ReactSystemFunctions.value(
      this,
      stateVars.ProductCategoryById?.length > 0 ? stateVars.ProductCategoryById[0]?.isWebAr : null
    );
    stateVars.ProductListByProductCategoryId = result?.data.productListByProductCategoryId;

    stateVars.RegionByProductId = result?.data.regionByProductId;

    stateVars.RegionByNotMatchProductId = result?.data.regionByNotMatchProductId;

    formVars.product_details_180847_options = stateVars.RegionByNotMatchProductId;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Product_DetailsPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Product_DetailsPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_details_909820_value", "value", "", "", "")
        ),
        false
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp180847Enabled", "enabled");

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Product_DetailsPageInit2_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Product_DetailsPageInit2_();
      });
    }

    return isErrorOccurred;
  };
  Product_DetailsPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_details_909820_value", "value", "", "", "")
        ),
        true
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp180847Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Product_DetailsPageInit3_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Product_DetailsPageInit3_();
      });
    }

    return isErrorOccurred;
  };
  Product_DetailsPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp444639Enabled", "disabled");
    formVars.product_details_443286_value = ReactSystemFunctions.toString(
      this,
      this.state.ProductCategoryById?.length > 0 ? this.state.ProductCategoryById[0]?.imageLink : null
    );

    formVars.product_details_839719_value = ReactSystemFunctions.toString(
      this,
      this.state.ProductCategoryById?.length > 0 ? this.state.ProductCategoryById[0]?.parentName : null
    );

    formVars.product_details_710309_value = ReactSystemFunctions.toString(
      this,
      this.state.ProductCategoryById?.length > 0 ? this.state.ProductCategoryById[0]?.subBrandName : null
    );

    formVars.product_details_345227_value = ReactSystemFunctions.toString(
      this,
      this.state.ProductCategoryById?.length > 0 ? this.state.ProductCategoryById[0]?.definition : null
    );

    stateVars.dataSource_722378 = this.state.ProductListByProductCategoryId;
    formVars.product_details_86423_value = ReactSystemFunctions.value(
      this,
      this.state.ProductCategoryById?.length > 0 ? this.state.ProductCategoryById[0]?.isWebEn : null
    );

    formVars.product_details_834087_value = ReactSystemFunctions.value(
      this,
      this.state.ProductCategoryById?.length > 0 ? this.state.ProductCategoryById[0]?.isWebTr : null
    );

    formVars.product_details_932099_value = ReactSystemFunctions.value(
      this,
      this.state.ProductCategoryById?.length > 0 ? this.state.ProductCategoryById[0]?.isWebFr : null
    );

    formVars.product_details_299888_value = ReactSystemFunctions.value(
      this,
      this.state.ProductCategoryById?.length > 0 ? this.state.ProductCategoryById[0]?.isWebAr : null
    );

    stateVars.dataSource_841137 = this.state.ProductCategoryById;

    stateVars.dataSource_180847 = this.state.RegionByNotMatchProductId;

    stateVars.dataSource_675061 = this.state.RegionByProductId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  Product_DetailsComponent_767216_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Product_Details",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "822573",
      null,
      "left",
      null,
      "320px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Product_DetailsComponent_102549_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Product_Details",
      "Product_List",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Product_DetailsComponent_569640_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "Product_NewCategory",
      "prmImageId",
      this.props.screenInputs.prmImageId ?? this.props.screenInputs.prmimageid
    );
    KuikaAppManager.addToPageInputVariables(
      "Product_NewCategory",
      "prmProductTranslationId",
      ReactSystemFunctions.value(
        this,
        this.state.ProductCategoryById?.length > 0 ? this.state.ProductCategoryById[0]?.productTranslationId : null
      )
    );
    KuikaAppManager.addToPageInputVariables(
      "Product_NewCategory",
      "prmProductCategoryId",
      this.props.screenInputs.prmProductCategoryId ?? this.props.screenInputs.prmproductcategoryid
    );
    KuikaAppManager.addToPageInputVariables("Product_NewCategory", "prmProductId", null);
    KuikaAppManager.addToPageInputVariables("Product_NewCategory", "prmPurpose", "EDIT");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Product_Details",
      "Product_NewCategory",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "889322",
      null,
      "right",
      null,
      "1000px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Product_DetailsComponent_967619_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("Product_New", "prmProductId", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables(
      "Product_New",
      "prmProductCategoryId",
      this.props.screenInputs.prmProductCategoryId ?? this.props.screenInputs.prmproductcategoryid
    );
    KuikaAppManager.addToPageInputVariables("Product_New", "prmPurpose", "ADD");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Product_Details",
      "Product_New",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "604560",
      null,
      "right",
      null,
      "800px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Product_DetailsComponent_307283_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "Product_New",
      "prmProductId",
      ReactSystemFunctions.value(this, "product_details_722378_value", "id")
    );
    KuikaAppManager.addToPageInputVariables(
      "Product_New",
      "prmProductCategoryId",
      this.props.screenInputs.prmProductCategoryId ?? this.props.screenInputs.prmproductcategoryid
    );
    KuikaAppManager.addToPageInputVariables("Product_New", "prmPurpose", "EDIT");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Product_Details",
      "Product_New",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "822699",
      null,
      "right",
      null,
      "800px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Product_DetailsComponent_26569_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "product_details_722378_value", "id"),
        "Guid"
      ),
      ProductCategoryId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProductCategoryId ?? this.props.screenInputs.prmproductcategoryid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "ef005f65_c549_9296_5883_851cf1921bcb_confirmation",
        this.defaultML,
        "This product detail will be deleted. Are you sure?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    }).then(async (result) => {
      if (result.isConfirmed) {
        KuikaAppManager.showSpinner(this);
        let result = await KuikaAppManager.ApiRequest(
          this,
          "Product_Details/Product_DetailsComponent_26569_onClick",
          localVar
        );
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

        stateVars.ProductDelete = result?.data.productDelete;
        stateVars.ProductListByProductCategoryId = result?.data.productListByProductCategoryId;

        this.props.form.setFieldsValue(formVars);
        this.setState(stateVars, () => {
          this.Product_DetailsComponent_26569_onClick1_();
        });
      }
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Product_DetailsComponent_26569_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_722378 = this.state.ProductListByProductCategoryId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Product_DetailsComponent_86423_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      isWebAr_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_details_299888_value", "value", "", "", "")
        ),
        "boolean"
      ),
      isWebEn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_details_86423_value", "value", "", "", "")
        ),
        "boolean"
      ),
      isWebFr_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_details_932099_value", "value", "", "", "")
        ),
        "boolean"
      ),
      isWebTr_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_details_834087_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ProductCategoryId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProductCategoryId ?? this.props.screenInputs.prmproductcategoryid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Product_Details/Product_DetailsComponent_86423_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ProductByWebSave = result?.data.productByWebSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  Product_DetailsComponent_834087_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      isWebAr_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_details_299888_value", "value", "", "", "")
        ),
        "boolean"
      ),
      isWebEn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_details_86423_value", "value", "", "", "")
        ),
        "boolean"
      ),
      isWebFr_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_details_932099_value", "value", "", "", "")
        ),
        "boolean"
      ),
      isWebTr_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_details_834087_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ProductCategoryId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProductCategoryId ?? this.props.screenInputs.prmproductcategoryid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Product_Details/Product_DetailsComponent_834087_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ProductByWebSave = result?.data.productByWebSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  Product_DetailsComponent_932099_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      isWebAr_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_details_299888_value", "value", "", "", "")
        ),
        "boolean"
      ),
      isWebEn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_details_86423_value", "value", "", "", "")
        ),
        "boolean"
      ),
      isWebFr_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_details_932099_value", "value", "", "", "")
        ),
        "boolean"
      ),
      isWebTr_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_details_834087_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ProductCategoryId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProductCategoryId ?? this.props.screenInputs.prmproductcategoryid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Product_Details/Product_DetailsComponent_932099_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ProductByWebSave = result?.data.productByWebSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  Product_DetailsComponent_299888_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      isWebAr_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_details_299888_value", "value", "", "", "")
        ),
        "boolean"
      ),
      isWebEn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_details_86423_value", "value", "", "", "")
        ),
        "boolean"
      ),
      isWebFr_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_details_932099_value", "value", "", "", "")
        ),
        "boolean"
      ),
      isWebTr_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_details_834087_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ProductCategoryId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProductCategoryId ?? this.props.screenInputs.prmproductcategoryid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Product_Details/Product_DetailsComponent_299888_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ProductByWebSave = result?.data.productByWebSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  Product_DetailsComponent_681564_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "product_details_841137_value", "language"), "EN")
    ) {
      KuikaAppManager.showSpinner(this);

      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "Product_NewCategory",
        "prmImageId",
        this.props.screenInputs.prmImageId ?? this.props.screenInputs.prmimageid
      );
      KuikaAppManager.addToPageInputVariables(
        "Product_NewCategory",
        "prmProductTranslationId",
        ReactSystemFunctions.value(this, "product_details_841137_value", "productTranslationId")
      );
      KuikaAppManager.addToPageInputVariables(
        "Product_NewCategory",
        "prmProductCategoryId",
        this.props.screenInputs.prmProductCategoryId ?? this.props.screenInputs.prmproductcategoryid
      );
      KuikaAppManager.addToPageInputVariables("Product_NewCategory", "prmProductId", null);
      KuikaAppManager.addToPageInputVariables("Product_NewCategory", "prmPurpose", "EDIT");

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.Product_DetailsComponent_681564_onClick1_,
        "Product_Details",
        "Product_NewCategory",
        "",
        "7d969ddf-5801-4eb6-b92b-12be35bdd565",
        "232111",
        null,
        "right",
        null,
        "1000px",
        "100vh",
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      this.setState(stateVars, () => {
        this.Product_DetailsComponent_681564_onClick1_();
      });
    }

    return isErrorOccurred;
  };
  Product_DetailsComponent_681564_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(this, "product_details_841137_value", "language"),
        "EN"
      )
    ) {
      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "Product_Translation",
        "prmLanguageId",
        ReactSystemFunctions.value(this, "product_details_841137_value", "languageId")
      );
      KuikaAppManager.addToPageInputVariables(
        "Product_Translation",
        "prmProductTranslationId",
        ReactSystemFunctions.value(this, "product_details_841137_value", "productTranslationId")
      );
      KuikaAppManager.addToPageInputVariables("Product_Translation", "prmWaitFor", "Edit");
      KuikaAppManager.addToPageInputVariables(
        "Product_Translation",
        "prmCurrentLang",
        ReactSystemFunctions.value(this, "product_details_841137_value", "language")
      );
      KuikaAppManager.addToPageInputVariables(
        "Product_Translation",
        "prmProductCategoryId",
        this.props.screenInputs.prmProductCategoryId ?? this.props.screenInputs.prmproductcategoryid
      );

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        undefined,
        "Product_Details",
        "Product_Translation",
        "",
        "7d969ddf-5801-4eb6-b92b-12be35bdd565",
        "387927",
        null,
        "right",
        null,
        "1000px",
        "100vh",
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  Product_DetailsComponent_797505_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ProductTranslationID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "product_details_841137_value", "productTranslationId"),
        "Guid"
      ),
      ProductCategoryID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProductCategoryId ?? this.props.screenInputs.prmproductcategoryid,
        "Guid"
      ),
      CurrentWebInfo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "product_details_841137_value", "language"),
        "string"
      ),
      LangId_1: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      ProductCategoryId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProductCategoryId ?? this.props.screenInputs.prmproductcategoryid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "b8ac736b_c4b0_bb5f_d7c7_c3ef40c8de62_confirmation",
        this.defaultML,
        "This translation will be deleted. Are you sure?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    }).then(async (result) => {
      if (result.isConfirmed) {
        KuikaAppManager.showSpinner(this);
        let result = await KuikaAppManager.ApiRequest(
          this,
          "Product_Details/Product_DetailsComponent_797505_onClick",
          localVar
        );
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

        stateVars.ProductCategoryTranslationDelete = result?.data.productCategoryTranslationDelete;
        stateVars.ProductCategoryById = result?.data.productCategoryById;

        formVars.product_details_443286_value = ReactSystemFunctions.toString(
          this,
          stateVars.ProductCategoryById?.length > 0 ? stateVars.ProductCategoryById[0]?.imageLink : null
        );
        formVars.product_details_443286_placeholderimage = "";
        formVars.product_details_839719_value = ReactSystemFunctions.toString(
          this,
          stateVars.ProductCategoryById?.length > 0 ? stateVars.ProductCategoryById[0]?.parentName : null
        );
        formVars.product_details_710309_value = ReactSystemFunctions.toString(
          this,
          stateVars.ProductCategoryById?.length > 0 ? stateVars.ProductCategoryById[0]?.subBrandName : null
        );
        formVars.product_details_345227_value = ReactSystemFunctions.toString(
          this,
          stateVars.ProductCategoryById?.length > 0 ? stateVars.ProductCategoryById[0]?.definition : null
        );
        formVars.product_details_86423_value = ReactSystemFunctions.value(
          this,
          stateVars.ProductCategoryById?.length > 0 ? stateVars.ProductCategoryById[0]?.isWebEn : null
        );
        formVars.product_details_834087_value = ReactSystemFunctions.value(
          this,
          stateVars.ProductCategoryById?.length > 0 ? stateVars.ProductCategoryById[0]?.isWebTr : null
        );
        formVars.product_details_932099_value = ReactSystemFunctions.value(
          this,
          stateVars.ProductCategoryById?.length > 0 ? stateVars.ProductCategoryById[0]?.isWebFr : null
        );
        formVars.product_details_299888_value = ReactSystemFunctions.value(
          this,
          stateVars.ProductCategoryById?.length > 0 ? stateVars.ProductCategoryById[0]?.isWebAr : null
        );
        this.props.form.setFieldsValue(formVars);
        this.setState(stateVars, () => {
          this.Product_DetailsComponent_797505_onClick1_();
        });
      }
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Product_DetailsComponent_797505_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.product_details_443286_value = ReactSystemFunctions.toString(
      this,
      this.state.ProductCategoryById?.length > 0 ? this.state.ProductCategoryById[0]?.imageLink : null
    );

    formVars.product_details_839719_value = ReactSystemFunctions.toString(
      this,
      this.state.ProductCategoryById?.length > 0 ? this.state.ProductCategoryById[0]?.parentName : null
    );

    formVars.product_details_710309_value = ReactSystemFunctions.toString(
      this,
      this.state.ProductCategoryById?.length > 0 ? this.state.ProductCategoryById[0]?.subBrandName : null
    );

    formVars.product_details_345227_value = ReactSystemFunctions.toString(
      this,
      this.state.ProductCategoryById?.length > 0 ? this.state.ProductCategoryById[0]?.definition : null
    );

    formVars.product_details_86423_value = ReactSystemFunctions.value(
      this,
      this.state.ProductCategoryById?.length > 0 ? this.state.ProductCategoryById[0]?.isWebEn : null
    );

    formVars.product_details_834087_value = ReactSystemFunctions.value(
      this,
      this.state.ProductCategoryById?.length > 0 ? this.state.ProductCategoryById[0]?.isWebTr : null
    );

    formVars.product_details_932099_value = ReactSystemFunctions.value(
      this,
      this.state.ProductCategoryById?.length > 0 ? this.state.ProductCategoryById[0]?.isWebFr : null
    );

    formVars.product_details_299888_value = ReactSystemFunctions.value(
      this,
      this.state.ProductCategoryById?.length > 0 ? this.state.ProductCategoryById[0]?.isWebAr : null
    );

    stateVars.dataSource_841137 = this.state.ProductCategoryById;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Product_DetailsComponent_423689_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("Product_Translation", "prmLanguageId", null);
    KuikaAppManager.addToPageInputVariables("Product_Translation", "prmProductTranslationId", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables("Product_Translation", "prmWaitFor", "Add");
    KuikaAppManager.addToPageInputVariables("Product_Translation", "prmCurrentLang", "");
    KuikaAppManager.addToPageInputVariables(
      "Product_Translation",
      "prmProductCategoryId",
      this.props.screenInputs.prmProductCategoryId ?? this.props.screenInputs.prmproductcategoryid
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Product_Details",
      "Product_Translation",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "269436",
      null,
      "right",
      null,
      "500px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Product_DetailsComponent_493806_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ProductCategoryId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProductCategoryId ?? this.props.screenInputs.prmproductcategoryid,
        "Guid"
      ),
      ProductCategoryId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProductCategoryId ?? this.props.screenInputs.prmproductcategoryid,
        "Guid"
      ),
      isActive_1: ReactSystemFunctions.convertToTypeByName(true, "boolean")
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Product_Details/Product_DetailsComponent_493806_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RegionByProductId = result?.data.regionByProductId;

    stateVars.RegionByNotMatchProductId = result?.data.regionByNotMatchProductId;

    formVars.product_details_180847_options = stateVars.RegionByNotMatchProductId;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Product_DetailsComponent_493806_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Product_DetailsComponent_493806_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_180847 = this.state.RegionByNotMatchProductId;

    stateVars.dataSource_675061 = this.state.RegionByProductId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Product_DetailsComponent_909820_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      isPublic_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_details_909820_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ProductCategoryId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProductCategoryId ?? this.props.screenInputs.prmproductcategoryid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Product_Details/Product_DetailsComponent_909820_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ProductPublicInfoUpdate = result?.data.productPublicInfoUpdate;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Product_DetailsComponent_909820_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Product_DetailsComponent_909820_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_details_909820_value", "value", "", "", "")
        ),
        false
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp180847Enabled", "enabled");

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Product_DetailsComponent_909820_onChange2_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Product_DetailsComponent_909820_onChange2_();
      });
    }

    return isErrorOccurred;
  };
  Product_DetailsComponent_909820_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_details_909820_value", "value", "", "", "")
        ),
        true
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp180847Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [822573, , 889322, 604560, 822699, 232111, 387927, 269436] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.Product_DetailsPageInit();
    }
    if (diId == 232111) {
      isErrorOccurred = await this.Product_DetailsComponent_681564_onClick1_();
      if (isErrorOccurred) return true;
    }
  }
}
