import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IProduct_New_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IProduct_New_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  ProductSave: number;
  GoBack: any;
  RegionsByCategoryId: any[];
  ProductById: any[];
  ProductByPurpose: any[];
  LanguageSwitchControl: any[];
  SetValueOf: any;
  Notify: boolean;
}

export class Product_New_ScreenBase extends React.PureComponent<IProduct_New_ScreenProps, any> {
  ml = [
    {
      Id: "937da27a-1820-c1c3-46e3-ec04ce38ecf6",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "c666cb7f-150f-4afc-b9fe-515cc89fc9cb",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 619802, PropertyName: "label", Value: "SAVE" },
        { Id: 607465, PropertyName: "value", Value: "Product form" },
        {
          Id: 687391,
          PropertyName: "title",
          Value:
            "Display setting on your website. It is determined automatically according to the translation you entered."
        },
        { Id: 355259, PropertyName: "value", Value: "Show website" },
        { Id: 760780, PropertyName: "value", Value: "English" },
        { Id: 548696, PropertyName: "value", Value: "Turkish" },
        { Id: 663509, PropertyName: "value", Value: "French" },
        { Id: 532631, PropertyName: "value", Value: "Arabic" },
        { Id: 576931, PropertyName: "value", Value: "Product Code" },
        { Id: 919737, PropertyName: "placeholder", Value: "Product code..." },
        { Id: 239080, PropertyName: "value", Value: "Weight (Gr)" },
        { Id: 193312, PropertyName: "placeholder", Value: "15" },
        { Id: 999141, PropertyName: "value", Value: "Pieces In Box" },
        { Id: 188123, PropertyName: "placeholder", Value: "15" },
        { Id: 960126, PropertyName: "value", Value: "Volume" },
        { Id: 270806, PropertyName: "placeholder", Value: "15" },
        { Id: 942104, PropertyName: "value", Value: "In Parcel" },
        { Id: 815555, PropertyName: "placeholder", Value: "15" },
        { Id: 997515, PropertyName: "value", Value: "Region(s)" },
        { Id: 544731, PropertyName: "value", Value: "Public" },
        { Id: 679009, PropertyName: "value", Value: "Active" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      ProductSave: 0,
      GoBack: "",
      RegionsByCategoryId: [],
      ProductById: [],
      ProductByPurpose: [],
      LanguageSwitchControl: [],
      SetValueOf: "",
      Notify: false
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("product_new", "product_new");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.Product_NewPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("product_new", "product_new");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("product_new", "product_new");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.Product_NewPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      product_new_810709_value: this.state.ProductByPurpose?.at?.(-1)?.isWebEn ?? undefined,
      product_new_194085_value: this.state.ProductByPurpose?.at?.(0)?.isWebTr ?? undefined,
      product_new_44212_value: this.state.ProductByPurpose?.at?.(0)?.isWebFr ?? undefined,
      product_new_433261_value: this.state.ProductByPurpose?.at?.(0)?.isWebAr ?? undefined,
      product_new_919737_value: this.state.ProductByPurpose?.at?.(0)?.productCode ?? undefined,
      product_new_193312_value: this.state.ProductByPurpose?.at?.(0)?.netGramaj ?? undefined,
      product_new_188123_value: this.state.ProductByPurpose?.at?.(0)?.piecesInBox ?? undefined,
      product_new_270806_value: this.state.ProductByPurpose?.at?.(0)?.volume ?? undefined,
      product_new_815555_value: this.state.ProductByPurpose?.at?.(-1)?.parcelnBox ?? undefined,
      product_new_472197_value: this.state.ProductByPurpose?.at?.(0)?.regionIds ?? undefined,
      product_new_923682_value: this.state.ProductByPurpose?.at?.(0)?.isPublic ?? undefined,
      product_new_882802_value: this.state.ProductByPurpose?.at?.(0)?.isActive ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  Product_NewPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      ProductCategoryID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProductCategoryId ?? this.props.screenInputs.prmproductcategoryid,
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProductId ?? this.props.screenInputs.prmproductid,
        "Guid"
      ),
      Purpose_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmPurpose ?? this.props.screenInputs.prmpurpose,
        "string"
      ),
      ProductID_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProductId ?? this.props.screenInputs.prmproductid,
        "Guid"
      ),
      ProductCategoryID_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProductCategoryId ?? this.props.screenInputs.prmproductcategoryid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "Product_New/Product_NewPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RegionsByCategoryId = result?.data.regionsByCategoryId;

    formVars.product_new_472197_options = stateVars.RegionsByCategoryId;
    formVars.product_new_472197_value =
      stateVars.ProductByPurpose?.length > 0 ? stateVars.ProductByPurpose[0]?.regionIds : null;
    stateVars.ProductById = result?.data.productById;
    stateVars.ProductByPurpose = result?.data.productByPurpose;
    formVars.product_new_810709_value = ReactSystemFunctions.value(
      this,
      stateVars.ProductByPurpose?.length > 0
        ? stateVars.ProductByPurpose[stateVars.ProductByPurpose.length - 1].isWebEn
        : null
    );
    formVars.product_new_194085_value = ReactSystemFunctions.value(
      this,
      stateVars.ProductByPurpose?.length > 0 ? stateVars.ProductByPurpose[0]?.isWebTr : null
    );
    formVars.product_new_44212_value = ReactSystemFunctions.value(
      this,
      stateVars.ProductByPurpose?.length > 0 ? stateVars.ProductByPurpose[0]?.isWebFr : null
    );
    formVars.product_new_433261_value = ReactSystemFunctions.value(
      this,
      stateVars.ProductByPurpose?.length > 0 ? stateVars.ProductByPurpose[0]?.isWebAr : null
    );
    formVars.product_new_919737_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProductByPurpose?.length > 0 ? stateVars.ProductByPurpose[0]?.productCode : null
    );
    formVars.product_new_193312_value = ReactSystemFunctions.value(
      this,
      stateVars.ProductByPurpose?.length > 0 ? stateVars.ProductByPurpose[0]?.netGramaj : null
    );
    formVars.product_new_188123_value = ReactSystemFunctions.value(
      this,
      stateVars.ProductByPurpose?.length > 0 ? stateVars.ProductByPurpose[0]?.piecesInBox : null
    );
    formVars.product_new_270806_value = ReactSystemFunctions.value(
      this,
      stateVars.ProductByPurpose?.length > 0 ? stateVars.ProductByPurpose[0]?.volume : null
    );
    formVars.product_new_815555_value = ReactSystemFunctions.value(
      this,
      stateVars.ProductByPurpose?.length > 0
        ? stateVars.ProductByPurpose[stateVars.ProductByPurpose.length - 1].parcelnBox
        : null
    );
    formVars.product_new_472197_options = stateVars.RegionsByCategoryId;
    formVars.product_new_472197_value =
      stateVars.ProductByPurpose?.length > 0 ? stateVars.ProductByPurpose[0]?.regionIds : null;
    formVars.product_new_923682_value = ReactSystemFunctions.value(
      this,
      stateVars.ProductByPurpose?.length > 0 ? stateVars.ProductByPurpose[0]?.isPublic : null
    );
    formVars.product_new_882802_value = ReactSystemFunctions.value(
      this,
      stateVars.ProductByPurpose?.length > 0 ? stateVars.ProductByPurpose[0]?.isActive : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Product_NewPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Product_NewPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.product_new_810709_value = ReactSystemFunctions.value(
      this,
      this.state.ProductByPurpose?.length > 0
        ? this.state.ProductByPurpose[this.state.ProductByPurpose.length - 1].isWebEn
        : null
    );

    formVars.product_new_194085_value = ReactSystemFunctions.value(
      this,
      this.state.ProductByPurpose?.length > 0 ? this.state.ProductByPurpose[0]?.isWebTr : null
    );

    formVars.product_new_44212_value = ReactSystemFunctions.value(
      this,
      this.state.ProductByPurpose?.length > 0 ? this.state.ProductByPurpose[0]?.isWebFr : null
    );

    formVars.product_new_433261_value = ReactSystemFunctions.value(
      this,
      this.state.ProductByPurpose?.length > 0 ? this.state.ProductByPurpose[0]?.isWebAr : null
    );

    formVars.product_new_919737_value = ReactSystemFunctions.toString(
      this,
      this.state.ProductByPurpose?.length > 0 ? this.state.ProductByPurpose[0]?.productCode : null
    );

    formVars.product_new_193312_value = ReactSystemFunctions.value(
      this,
      this.state.ProductByPurpose?.length > 0 ? this.state.ProductByPurpose[0]?.netGramaj : null
    );

    formVars.product_new_188123_value = ReactSystemFunctions.value(
      this,
      this.state.ProductByPurpose?.length > 0 ? this.state.ProductByPurpose[0]?.piecesInBox : null
    );

    formVars.product_new_270806_value = ReactSystemFunctions.value(
      this,
      this.state.ProductByPurpose?.length > 0 ? this.state.ProductByPurpose[0]?.volume : null
    );

    formVars.product_new_815555_value = ReactSystemFunctions.value(
      this,
      this.state.ProductByPurpose?.length > 0
        ? this.state.ProductByPurpose[this.state.ProductByPurpose.length - 1].parcelnBox
        : null
    );

    formVars.product_new_472197_value = ReactSystemFunctions.toString(
      this,
      this.state.ProductByPurpose?.length > 0 ? this.state.ProductByPurpose[0]?.regionIds : null
    );

    stateVars.dataSource_472197 = this.state.RegionsByCategoryId;
    formVars.product_new_923682_value = ReactSystemFunctions.value(
      this,
      this.state.ProductByPurpose?.length > 0 ? this.state.ProductByPurpose[0]?.isPublic : null
    );

    formVars.product_new_882802_value = ReactSystemFunctions.value(
      this,
      this.state.ProductByPurpose?.length > 0 ? this.state.ProductByPurpose[0]?.isActive : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  Product_NewComponent_619802_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "product_new_919737_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "product_new_919737_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_new_193312_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "product_new_193312_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_new_815555_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "product_new_815555_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_new_270806_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "product_new_270806_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      NewProductCode_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "product_new_919737_value", "value", "", "", "")
        ),
        "string"
      ),
      Purpose_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmPurpose ?? this.props.screenInputs.prmpurpose,
        "string"
      ),
      OldProductCode_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.ProductByPurpose?.length > 0 ? this.state.ProductByPurpose[0]?.productCode : null
        ),
        "string"
      ),
      isActive_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_new_882802_value", "value", "", "", "")
        ),
        "boolean"
      ),
      isPublic_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_new_923682_value", "value", "", "", "")
        ),
        "boolean"
      ),
      isWebAr_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_new_433261_value", "value", "", "", "")
        ),
        "boolean"
      ),
      isWebEn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_new_810709_value", "value", "", "", "")
        ),
        "boolean"
      ),
      isWebFr_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_new_44212_value", "value", "", "", "")
        ),
        "boolean"
      ),
      isWebTr_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_new_194085_value", "value", "", "", "")
        ),
        "boolean"
      ),
      NetGramaj_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_new_193312_value", "value", "", "", "")
        ),
        "string"
      ),
      ParcelnBox_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_new_815555_value", "value", "", "", "")
        ),
        "string"
      ),
      PiecesInBox_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_new_188123_value", "value", "", "", "")
        ),
        "string"
      ),
      ProductCategoryId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProductCategoryId ?? this.props.screenInputs.prmproductcategoryid,
        "Guid"
      ),
      ProductCode_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "product_new_919737_value", "value", "", "", "")
        ),
        "string"
      ),
      RegionIds_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "product_new_472197_value", "value", "RegionsByCategoryId", "id", "id")
        ),
        "string"
      ),
      UploadedDate_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      Volume_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_new_270806_value", "value", "", "", "")
        ),
        "number"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProductId ?? this.props.screenInputs.prmproductid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "Product_New/Product_NewComponent_619802_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ProductSave = result?.data.productSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Product_NewComponent_619802_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Product_NewComponent_619802_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Product_NewComponent_419961_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Product_NewComponent_810709_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ProductCategoryID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProductCategoryId ?? this.props.screenInputs.prmproductcategoryid,
        "Guid"
      ),
      ProductID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProductId ?? this.props.screenInputs.prmproductid,
        "Guid"
      ),
      SwitchInfo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_new_810709_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Language_0: ReactSystemFunctions.convertToTypeByName("EN", "string")
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "Product_New/Product_NewComponent_810709_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.LanguageSwitchControl = result?.data.languageSwitchControl;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Product_NewComponent_810709_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Product_NewComponent_810709_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.LanguageSwitchControl?.length > 0 ? this.state.LanguageSwitchControl[0]?.result : null
        ),
        false
      )
    ) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "product_new_810709_value", false, null);

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Product_NewComponent_810709_onChange2_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Product_NewComponent_810709_onChange2_();
      });
    }

    return isErrorOccurred;
  };
  Product_NewComponent_810709_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.LanguageSwitchControl?.length > 0 ? this.state.LanguageSwitchControl[0]?.result : null
        ),
        false
      )
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.translateCustomActions(
          this.ml,
          "5d1d492e_88e9_2eaf_b3cc_e0e6d572aa19_notify",
          this.defaultML,
          "No translation available for the option you selected for this product!"
        ),
        "warning",
        "bottom-right",
        3
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  Product_NewComponent_194085_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ProductCategoryID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProductCategoryId ?? this.props.screenInputs.prmproductcategoryid,
        "Guid"
      ),
      ProductID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProductId ?? this.props.screenInputs.prmproductid,
        "Guid"
      ),
      SwitchInfo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_new_194085_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Language_0: ReactSystemFunctions.convertToTypeByName("TR", "string")
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "Product_New/Product_NewComponent_194085_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.LanguageSwitchControl = result?.data.languageSwitchControl;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Product_NewComponent_194085_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Product_NewComponent_194085_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.LanguageSwitchControl?.length > 0 ? this.state.LanguageSwitchControl[0]?.result : null
        ),
        false
      )
    ) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "product_new_194085_value", false, null);

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Product_NewComponent_194085_onChange2_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Product_NewComponent_194085_onChange2_();
      });
    }

    return isErrorOccurred;
  };
  Product_NewComponent_194085_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.LanguageSwitchControl?.length > 0 ? this.state.LanguageSwitchControl[0]?.result : null
        ),
        false
      )
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.translateCustomActions(
          this.ml,
          "c6ea98ce_41e4_3fe3_d0b4_b5a612af9da5_notify",
          this.defaultML,
          "No translation available for the option you selected for this product!"
        ),
        "warning",
        "bottom-right",
        3
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  Product_NewComponent_44212_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ProductCategoryID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProductCategoryId ?? this.props.screenInputs.prmproductcategoryid,
        "Guid"
      ),
      ProductID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProductId ?? this.props.screenInputs.prmproductid,
        "Guid"
      ),
      SwitchInfo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_new_44212_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Language_0: ReactSystemFunctions.convertToTypeByName("FR", "string")
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "Product_New/Product_NewComponent_44212_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.LanguageSwitchControl = result?.data.languageSwitchControl;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Product_NewComponent_44212_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Product_NewComponent_44212_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.LanguageSwitchControl?.length > 0 ? this.state.LanguageSwitchControl[0]?.result : null
        ),
        false
      )
    ) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "product_new_44212_value", false, null);

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Product_NewComponent_44212_onChange2_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Product_NewComponent_44212_onChange2_();
      });
    }

    return isErrorOccurred;
  };
  Product_NewComponent_44212_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.LanguageSwitchControl?.length > 0 ? this.state.LanguageSwitchControl[0]?.result : null
        ),
        false
      )
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.translateCustomActions(
          this.ml,
          "775d75b4_7c90_546f_bb43_7b5f3fdff0e6_notify",
          this.defaultML,
          "No translation available for the option you selected for this product!"
        ),
        "warning",
        "bottom-right",
        3
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  Product_NewComponent_433261_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ProductCategoryID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProductCategoryId ?? this.props.screenInputs.prmproductcategoryid,
        "Guid"
      ),
      ProductID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProductId ?? this.props.screenInputs.prmproductid,
        "Guid"
      ),
      SwitchInfo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_new_433261_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Language_0: ReactSystemFunctions.convertToTypeByName("AR", "string")
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "Product_New/Product_NewComponent_433261_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.LanguageSwitchControl = result?.data.languageSwitchControl;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Product_NewComponent_433261_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Product_NewComponent_433261_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.LanguageSwitchControl?.length > 0 ? this.state.LanguageSwitchControl[0]?.result : null
        ),
        false
      )
    ) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "product_new_433261_value", false, null);

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Product_NewComponent_433261_onChange2_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Product_NewComponent_433261_onChange2_();
      });
    }

    return isErrorOccurred;
  };
  Product_NewComponent_433261_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.LanguageSwitchControl?.length > 0 ? this.state.LanguageSwitchControl[0]?.result : null
        ),
        false
      )
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.translateCustomActions(
          this.ml,
          "1ca97956_eee9_66d3_532f_ba9e59db2a95_notify",
          this.defaultML,
          "No translation available for the option you selected for this product!"
        ),
        "warning",
        "bottom-right",
        3
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
