import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IContact_UnpairedNew_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IContact_UnpairedNew_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  CustomerContactsSave: number;
  StartingScreenSave: number;
  GoBack: any;
  CustomerAll: any[];
  ContactById: any[];
}

export class Contact_UnpairedNew_ScreenBase extends React.PureComponent<IContact_UnpairedNew_ScreenProps, any> {
  contact_unpairednew_283214_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "937da27a-1820-c1c3-46e3-ec04ce38ecf6",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "b5266207-8390-4791-9576-31b8bd4b476e",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 619802, PropertyName: "label", Value: "SAVE" },
        { Id: 607465, PropertyName: "value", Value: "Unpaired contacts" },
        { Id: 116276, PropertyName: "value", Value: "Customer List" },
        { Id: 283214, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 960354, PropertyName: "value", Value: "Name" },
        { Id: 12345, PropertyName: "placeholder", Value: "Contact name..." },
        { Id: 239080, PropertyName: "value", Value: "Surname" },
        { Id: 762423, PropertyName: "placeholder", Value: "Contact surname..." },
        { Id: 960126, PropertyName: "value", Value: "Title" },
        { Id: 896414, PropertyName: "placeholder", Value: "Finans Manager" },
        { Id: 530804, PropertyName: "value", Value: "Phone" },
        { Id: 79879, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 843013, PropertyName: "value", Value: "Email" },
        { Id: 40970, PropertyName: "placeholder", Value: "name@company.com" },
        { Id: 668814, PropertyName: "value", Value: "Address" },
        { Id: 120346, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 919147, PropertyName: "value", Value: "Active" },
        { Id: 731529, PropertyName: "value", Value: "Show Campaign" },
        { Id: 260011, PropertyName: "value", Value: "Show Customer Requests" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.contact_unpairednew_283214_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      CustomerContactsSave: 0,
      StartingScreenSave: 0,
      GoBack: "",
      CustomerAll: [],
      ContactById: []
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("contact_unpairednew", "contact_unpairednew");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.Contact_UnpairedNewPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("contact_unpairednew", "contact_unpairednew");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("contact_unpairednew", "contact_unpairednew");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.Contact_UnpairedNewPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      contact_unpairednew_12345_value: this.state.ContactById?.at?.(0)?.firstName ?? undefined,
      contact_unpairednew_762423_value: this.state.ContactById?.at?.(0)?.lastName ?? undefined,
      contact_unpairednew_896414_value: this.state.ContactById?.at?.(0)?.contactTitle ?? undefined,
      contact_unpairednew_79879_value: this.state.ContactById?.at?.(0)?.contactPhoneNumber ?? undefined,
      contact_unpairednew_40970_value: this.state.ContactById?.at?.(0)?.contactEmail ?? undefined,
      contact_unpairednew_120346_value: this.state.ContactById?.at?.(0)?.contactAddress ?? undefined,
      contact_unpairednew_258564_value: this.state.ContactById?.at?.(0)?.contactIsActive ?? undefined,
      contact_unpairednew_450895_value: this.state.ContactById?.at?.(0)?.isCampaignShow ?? undefined,
      contact_unpairednew_201246_value: this.state.ContactById?.at?.(0)?.isShowRequests ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  Contact_UnpairedNewPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmContactId ?? this.props.screenInputs.prmcontactid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "Contact_UnpairedNew/Contact_UnpairedNewPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CustomerAll = result?.data.customerAll;

    formVars.contact_unpairednew_283214_options = stateVars.CustomerAll;
    stateVars.ContactById = result?.data.contactById;
    formVars.contact_unpairednew_12345_value = ReactSystemFunctions.toString(
      this,
      stateVars.ContactById?.length > 0 ? stateVars.ContactById[0]?.firstName : null
    );
    formVars.contact_unpairednew_762423_value = ReactSystemFunctions.toString(
      this,
      stateVars.ContactById?.length > 0 ? stateVars.ContactById[0]?.lastName : null
    );
    formVars.contact_unpairednew_896414_value = ReactSystemFunctions.toString(
      this,
      stateVars.ContactById?.length > 0 ? stateVars.ContactById[0]?.contactTitle : null
    );
    formVars.contact_unpairednew_79879_value = ReactSystemFunctions.toString(
      this,
      stateVars.ContactById?.length > 0 ? stateVars.ContactById[0]?.contactPhoneNumber : null
    );
    formVars.contact_unpairednew_40970_value = ReactSystemFunctions.toString(
      this,
      stateVars.ContactById?.length > 0 ? stateVars.ContactById[0]?.contactEmail : null
    );
    formVars.contact_unpairednew_120346_value = ReactSystemFunctions.toString(
      this,
      stateVars.ContactById?.length > 0 ? stateVars.ContactById[0]?.contactAddress : null
    );
    formVars.contact_unpairednew_258564_value = ReactSystemFunctions.value(
      this,
      stateVars.ContactById?.length > 0 ? stateVars.ContactById[0]?.contactIsActive : null
    );
    formVars.contact_unpairednew_450895_value = ReactSystemFunctions.value(
      this,
      stateVars.ContactById?.length > 0 ? stateVars.ContactById[0]?.isCampaignShow : null
    );
    formVars.contact_unpairednew_201246_value = ReactSystemFunctions.value(
      this,
      stateVars.ContactById?.length > 0 ? stateVars.ContactById[0]?.isShowRequests : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Contact_UnpairedNewPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Contact_UnpairedNewPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_283214 = this.state.CustomerAll;
    formVars.contact_unpairednew_12345_value = ReactSystemFunctions.toString(
      this,
      this.state.ContactById?.length > 0 ? this.state.ContactById[0]?.firstName : null
    );

    formVars.contact_unpairednew_762423_value = ReactSystemFunctions.toString(
      this,
      this.state.ContactById?.length > 0 ? this.state.ContactById[0]?.lastName : null
    );

    formVars.contact_unpairednew_896414_value = ReactSystemFunctions.toString(
      this,
      this.state.ContactById?.length > 0 ? this.state.ContactById[0]?.contactTitle : null
    );

    formVars.contact_unpairednew_79879_value = ReactSystemFunctions.toString(
      this,
      this.state.ContactById?.length > 0 ? this.state.ContactById[0]?.contactPhoneNumber : null
    );

    formVars.contact_unpairednew_40970_value = ReactSystemFunctions.toString(
      this,
      this.state.ContactById?.length > 0 ? this.state.ContactById[0]?.contactEmail : null
    );

    formVars.contact_unpairednew_120346_value = ReactSystemFunctions.toString(
      this,
      this.state.ContactById?.length > 0 ? this.state.ContactById[0]?.contactAddress : null
    );

    formVars.contact_unpairednew_258564_value = ReactSystemFunctions.value(
      this,
      this.state.ContactById?.length > 0 ? this.state.ContactById[0]?.contactIsActive : null
    );

    formVars.contact_unpairednew_450895_value = ReactSystemFunctions.value(
      this,
      this.state.ContactById?.length > 0 ? this.state.ContactById[0]?.isCampaignShow : null
    );

    formVars.contact_unpairednew_201246_value = ReactSystemFunctions.value(
      this,
      this.state.ContactById?.length > 0 ? this.state.ContactById[0]?.isShowRequests : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  Contact_UnpairedNewComponent_619802_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "contact_unpairednew_120346_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "contact_unpairednew_120346_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "contact_unpairednew_40970_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "contact_unpairednew_40970_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "contact_unpairednew_12345_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "contact_unpairednew_12345_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "contact_unpairednew_762423_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "contact_unpairednew_762423_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "contact_unpairednew_79879_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "contact_unpairednew_79879_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "contact_unpairednew_896414_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "contact_unpairednew_896414_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Address_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "contact_unpairednew_120346_value", "value", "", "", "")
        ),
        "string"
      ),
      CustomerId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "contact_unpairednew_283214_value", "value", "CustomerAll", "id", "id")
        ),
        "Guid"
      ),
      Email_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "contact_unpairednew_40970_value", "value", "", "", "")
        ),
        "string"
      ),
      FirstName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "contact_unpairednew_12345_value", "value", "", "", "")
        ),
        "string"
      ),
      isActive_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "contact_unpairednew_258564_value", "value", "", "", "")
        ),
        "boolean"
      ),
      LastName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "contact_unpairednew_762423_value", "value", "", "", "")
        ),
        "string"
      ),
      PhoneNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "contact_unpairednew_79879_value", "value", "", "", "")
        ),
        "string"
      ),
      Title_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "contact_unpairednew_896414_value", "value", "", "", "")
        ),
        "string"
      ),
      isCampaignShow_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "contact_unpairednew_450895_value", "value", "", "", "")
        ),
        "boolean"
      ),
      isShowRequests_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "contact_unpairednew_201246_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Puan_0: ReactSystemFunctions.convertToTypeByName(0, "number"),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmContactId ?? this.props.screenInputs.prmcontactid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Contact_UnpairedNew/Contact_UnpairedNewComponent_619802_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CustomerContactsSave = result?.data.customerContactsSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Contact_UnpairedNewComponent_619802_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Contact_UnpairedNewComponent_619802_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "contact_unpairednew_40970_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "contact_unpairednew_40970_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Email_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "contact_unpairednew_40970_value", "value", "", "", "")
        ),
        "string"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "contact_unpairednew_201246_value", "value", "", "", "")
        ),
        true
      )
    ) {
      KuikaAppManager.showSpinner(this);
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Contact_UnpairedNew/Contact_UnpairedNewComponent_619802_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.StartingScreenSave = result?.data.startingScreenSave;
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Contact_UnpairedNewComponent_619802_onClick2_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Contact_UnpairedNewComponent_619802_onClick2_();
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Contact_UnpairedNewComponent_619802_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Contact_UnpairedNewComponent_419961_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
