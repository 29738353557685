import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IRegion_New_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IRegion_New_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  RegionSave: number;
  GoBack: any;
  RegionById: any[];
}

export class Region_New_ScreenBase extends React.PureComponent<IRegion_New_ScreenProps, any> {
  ml = [
    {
      Id: "937da27a-1820-c1c3-46e3-ec04ce38ecf6",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "bcff5a47-4462-4cf8-a2ed-6256dc5b0801",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 619802, PropertyName: "label", Value: "SAVE" },
        { Id: 607465, PropertyName: "value", Value: "Region form" },
        { Id: 381900, PropertyName: "value", Value: "Region" },
        { Id: 799425, PropertyName: "placeholder", Value: "Ragion name..." },
        { Id: 49379, PropertyName: "value", Value: "Active" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      RegionSave: 0,
      GoBack: "",
      RegionById: []
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("region_new", "region_new");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.Region_NewPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("region_new", "region_new");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("region_new", "region_new");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.Region_NewPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      region_new_799425_value: this.state.RegionById?.at?.(0)?.definition ?? undefined,
      region_new_838238_value: this.state.RegionById?.at?.(0)?.isActive ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  Region_NewPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmRegionId ?? this.props.screenInputs.prmregionid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "Region_New/Region_NewPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RegionById = result?.data.regionById;
    formVars.region_new_799425_value = ReactSystemFunctions.toString(
      this,
      stateVars.RegionById?.length > 0 ? stateVars.RegionById[0]?.definition : null
    );
    formVars.region_new_838238_value = ReactSystemFunctions.value(
      this,
      stateVars.RegionById?.length > 0 ? stateVars.RegionById[0]?.isActive : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Region_NewPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Region_NewPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.region_new_799425_value = ReactSystemFunctions.toString(
      this,
      this.state.RegionById?.length > 0 ? this.state.RegionById[0]?.definition : null
    );

    formVars.region_new_838238_value = ReactSystemFunctions.value(
      this,
      this.state.RegionById?.length > 0 ? this.state.RegionById[0]?.isActive : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  Region_NewComponent_619802_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Definition_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "region_new_799425_value", "value", "", "", "")
        ),
        "string"
      ),
      isActive_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "region_new_838238_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmRegionId ?? this.props.screenInputs.prmregionid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "Region_New/Region_NewComponent_619802_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RegionSave = result?.data.regionSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Region_NewComponent_619802_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Region_NewComponent_619802_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Region_NewComponent_419961_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
