import { FormInstance } from "antd/lib/form";
import React from "react";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { IValidationData } from "../../models/dto/validation-data";
import { UserService } from "../../services/user-service";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IHomePage_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IHomePage_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  ProductCount: any[];
  CustomerCount: any[];
  CartSearchWithPagination: any[];
  CartCount: any[];
  isComp610406Visible: "visible" | "hidden";
  isComphomepage_276412AuthorizationVisible: "visible" | "hidden";
}

export class HomePage_ScreenBase extends React.PureComponent<IHomePage_ScreenProps, any> {
  homepage_526539_value_kuikaTableRef: React.RefObject<any>;
  homepage_426847_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "937da27a-1820-c1c3-46e3-ec04ce38ecf6",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "dab2e45c-4be1-4f31-ba1b-6776c0e98f9a",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 316688, PropertyName: "value", Value: "Dashboard" },
        { Id: 610406, PropertyName: "placeholder", Value: "Search..." },
        { Id: 640217, PropertyName: "label", Value: "menu item" },
        { Id: 126271, PropertyName: "label", Value: "menu item" },
        { Id: 774380, PropertyName: "label", Value: "menu item" },
        { Id: 483232, PropertyName: "label", Value: "menu item" },
        { Id: 864459, PropertyName: "label", Value: "menu item" },
        { Id: 699128, PropertyName: "value", Value: "All information requests" },
        { Id: 206055, PropertyName: "value", Value: "Total product" },
        { Id: 586619, PropertyName: "value", Value: "Customers" },
        { Id: 733261, PropertyName: "value", Value: "Information requests" },
        { Id: 526539, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 867714, PropertyName: "title", Value: "Customer name" },
        { Id: 772128, PropertyName: "value", Value: "[datafield:fullcustomercontactname]" },
        { Id: 537916, PropertyName: "title", Value: "Info req." },
        { Id: 544115, PropertyName: "value", Value: "[datafield:submissiondate]" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.homepage_526539_value_kuikaTableRef = React.createRef();
    this.homepage_426847_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      ProductCount: [],
      CustomerCount: [],
      CartSearchWithPagination: [],
      CartCount: [],
      isComp610406Visible: "hidden",
      isComphomepage_276412AuthorizationVisible: "visible"
    };

    this.CheckPageAuthorization();
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("homepage", "homepage");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.HomePagePageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isComphomepage_276412AuthorizationVisible", [
      "0d04024b-0d6e-3407-b979-cce71aae0dae",
      "ADMIN"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("homepage", "homepage");
  }

  componentWillUnmount() {}

  CheckPageAuthorization = async () => {
    KuikaAppManager.showSpinner(this);
    await UserService.GetUserInfoForce();
    const permittedRoleNames: string[] = ["ADMIN"];
    const userInfo = window.userInfo as IUserInfoDto;
    const userRoleNames = userInfo.roles.map((role) => role.name);
    if (!permittedRoleNames.some((roleName) => userRoleNames.includes(roleName))) {
      await ReactSystemFunctions.goBack(this);
      KMainFunctions.displayErrorNotification("You do not have permission to access this page.");
      this.setState({ isPageVisible: false });
      KuikaAppManager.hideSpinner(this);
      return;
    }
    this.setState({ isPageVisible: true });
    KuikaAppManager.hideSpinner(this);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("homepage", "homepage");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.HomePagePageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  HomePagePageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      currentpage_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "homepage_426847_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "homepage_426847_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "HomePage/HomePagePageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ProductCount = result?.data.productCount;
    formVars.homepage_932781_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProductCount?.length > 0 ? stateVars.ProductCount[0]?.productCount : null
    );
    stateVars.CustomerCount = result?.data.customerCount;
    formVars.homepage_71164_value = ReactSystemFunctions.toString(
      this,
      stateVars.CustomerCount?.length > 0 ? stateVars.CustomerCount[0]?.customerCount : null
    );
    stateVars.CartSearchWithPagination = result?.data.cartSearchWithPagination;

    stateVars.CartCount = result?.data.cartCount;
    formVars.homepage_5479_value = ReactSystemFunctions.toString(
      this,
      stateVars.CartCount?.length > 0 ? stateVars.CartCount[0]?.cartCount : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.HomePagePageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  HomePagePageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.homepage_5479_value = ReactSystemFunctions.toString(
      this,
      this.state.CartCount?.length > 0 ? this.state.CartCount[0]?.cartCount : null
    );

    formVars.homepage_932781_value = ReactSystemFunctions.toString(
      this,
      this.state.ProductCount?.length > 0 ? this.state.ProductCount[0]?.productCount : null
    );

    formVars.homepage_71164_value = ReactSystemFunctions.toString(
      this,
      this.state.CustomerCount?.length > 0 ? this.state.CustomerCount[0]?.customerCount : null
    );

    stateVars.dataSource_526539 = this.state.CartSearchWithPagination;
    formVars.homepage_426847_total = ReactSystemFunctions.value(
      this,
      this.state.CartCount?.length > 0 ? this.state.CartCount[0]?.cartCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  HomePageComponent_276412_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "HomePage",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "878371",
      null,
      "left",
      null,
      "300px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  HomePageComponent_426847_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "homepage_426847_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "homepage_426847_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "HomePage/HomePageComponent_426847_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CartSearchWithPagination = result?.data.cartSearchWithPagination;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.HomePageComponent_426847_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  HomePageComponent_426847_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_526539 = this.state.CartSearchWithPagination;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [878371] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.HomePagePageInit();
    }
  }
}
