import { Form, Spin, Typography } from "antd";
import { connect } from "react-redux";
import {
  Button,
  Header,
  HorizontalStack,
  Icon,
  Image,
  KCol,
  KContentMenu,
  KContentMenuItem,
  KContentMenuPanel,
  KDrawer,
  KPanel,
  KRow,
  KTab,
  KTabItem,
  KTable,
  KTableColumn,
  KTableHeader,
  KTableRow,
  Label,
  Switch,
  Tooltip,
  VerticalStack
} from "../../components/web-components";
import { KSelectBox } from "../../kuika";
import {
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/photo-take/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { Product_Details_ScreenBase } from "./product_details-base";

import { LeftMenu_Screen } from "../leftmenu/leftmenu";
import { Product_New_Screen } from "../product_new/product_new";
import { Product_NewCategory_Screen } from "../product_newcategory/product_newcategory";
import { Product_Translation_Screen } from "../product_translation/product_translation";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;

class Product_Details_Screen extends Product_Details_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <Spin spinning={this.state.spinnerCount > 0}>
          <Form
            form={this.props.form}
            name="basic"
            initialValues={{
              product_details_31549_value: "d4abeae1-492c-4c55-3c19-08db6ca818e0",
              product_details_443286_value: this.state.ProductCategoryById?.at?.(0)?.imageLink ?? undefined,
              product_details_127103_value: undefined,
              product_details_86423_value: this.state.ProductCategoryById?.at?.(0)?.isWebEn ?? undefined,
              product_details_834087_value: this.state.ProductCategoryById?.at?.(0)?.isWebTr ?? undefined,
              product_details_932099_value: this.state.ProductCategoryById?.at?.(0)?.isWebFr ?? undefined,
              product_details_299888_value: this.state.ProductCategoryById?.at?.(0)?.isWebAr ?? undefined,
              product_details_180847_value: undefined,
              product_details_909820_value: undefined
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
              <Header
                id="product_details_header"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    textAlign: "-webkit-center",
                    fontFamily: "Poppins",
                    fontWeight: 400,
                    fontSize: "15px",
                    color: "rgba(68, 68, 68, 1)",
                    lineHeight: "22px"
                  } as any
                }
              >
                <KPanel
                  id="470929"
                  scrolling="hidden"
                  scrollRadius={false}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      maxWidth: "1240px",
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      fontSize: "15px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                >
                  <KRow
                    id="843000"
                    align="top"
                    justify="start"
                    horizontalGutter={0}
                    verticalGutter={0}
                    style={
                      {
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 12,
                        borderBottomLeftRadius: 12,
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        paddingTop: 16,
                        paddingRight: 16,
                        paddingBottom: 16,
                        paddingLeft: 16,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: "15px",
                        color: "rgba(68, 68, 68, 1)",
                        lineHeight: "22px"
                      } as any
                    }
                  >
                    <KCol
                      id="464725"
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      xxl={12}
                      order={0}
                      pull={0}
                      push={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: "15px",
                          color: "rgba(68, 68, 68, 1)",
                          lineHeight: "22px"
                        } as any
                      }
                    >
                      <HorizontalStack
                        id="658358"
                        direction="horizontal"
                        size={24}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            alignItems: "center",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: "15px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      >
                        <Icon
                          id="767216"
                          onClick={(e?: any) => {
                            if (e && e.stopPropagation) e.stopPropagation();
                            this.Product_DetailsComponent_767216_onClick();
                          }}
                          showCursorPointer
                          iconName="menu"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "24px",
                              color: "rgba(155, 155, 155, 1)",
                              letterSpacing: "1px"
                            } as any
                          }
                        ></Icon>

                        <HorizontalStack
                          id="787119"
                          direction="horizontal"
                          size={12}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: "15px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        >
                          <Icon
                            id="102549"
                            onClick={(e?: any) => {
                              if (e && e.stopPropagation) e.stopPropagation();
                              this.Product_DetailsComponent_102549_onClick();
                            }}
                            showCursorPointer
                            iconName="keyboard_backspace"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "24px",
                                color: "rgba(0, 118, 255, 1)",
                                letterSpacing: "1px"
                              } as any
                            }
                          ></Icon>

                          <Label
                            id="811807"
                            value={ReactSystemFunctions.translate(this.ml, 811807, "value", this.defaultML)}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 300,
                                fontSize: "24px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          ></Label>
                        </HorizontalStack>
                      </HorizontalStack>
                    </KCol>

                    <KCol
                      id="491275"
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      xxl={12}
                      order={0}
                      pull={0}
                      push={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 8,
                          paddingRight: 8,
                          paddingBottom: 8,
                          paddingLeft: 8,
                          alignItems: "center",
                          textAlign: "-webkit-right",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: "15px",
                          color: "rgba(68, 68, 68, 1)",
                          lineHeight: "22px"
                        } as any
                      }
                    >
                      <HorizontalStack
                        id="349703"
                        direction="horizontal"
                        size={24}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            alignItems: "center",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: "15px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      >
                        <KContentMenu
                          id="24450"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: "15px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        >
                          <KContentMenuPanel
                            id="138587"
                            style={
                              {
                                borderTopLeftRadius: 12,
                                borderTopRightRadius: 12,
                                borderBottomRightRadius: 12,
                                borderBottomLeftRadius: 12,
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "15px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="product_details_31549_value">
                              <Image
                                id="31549"
                                zoomOnClick={false}
                                imageFit="stretch"
                                style={
                                  {
                                    borderTopLeftRadius: 32,
                                    borderTopRightRadius: 32,
                                    borderBottomRightRadius: 32,
                                    borderBottomLeftRadius: 32,
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    width: "32px",
                                    height: "32px",
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 400,
                                    fontSize: "15px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              ></Image>
                            </Form.Item>
                          </KContentMenuPanel>

                          <KContentMenuItem
                            id="384517"
                            label={ReactSystemFunctions.translate(this.ml, 384517, "label", this.defaultML)}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundColor: "rgba(255, 255, 255, 1)",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 20,
                                paddingRight: 32,
                                paddingBottom: 20,
                                paddingLeft: 16,
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "15px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          ></KContentMenuItem>

                          <KContentMenuItem
                            id="89253"
                            label={ReactSystemFunctions.translate(this.ml, 89253, "label", this.defaultML)}
                            style={
                              {
                                borderTopWidth: 1,
                                borderColor: "rgba(238, 238, 238, 1)",
                                borderStyle: "solid",
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundColor: "rgba(255, 255, 255, 1)",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 20,
                                paddingRight: 32,
                                paddingBottom: 20,
                                paddingLeft: 16,
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "15px",
                                color: "rgba(208, 2, 27, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          ></KContentMenuItem>
                        </KContentMenu>
                      </HorizontalStack>
                    </KCol>
                  </KRow>
                </KPanel>
              </Header>

              <div
                id="product_details_body"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(247, 247, 247, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left",
                    display: "block"
                  } as any
                }
              >
                <KRow
                  id="999506"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      fontSize: "15px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                >
                  <KCol
                    id="150846"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        alignItems: "flex-start",
                        textAlign: "-webkit-center",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: "15px",
                        color: "rgba(68, 68, 68, 1)",
                        lineHeight: "22px"
                      } as any
                    }
                  >
                    <KPanel
                      id="795366"
                      scrolling="hidden"
                      scrollRadius={false}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          maxWidth: "1240px",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: "15px",
                          color: "rgba(68, 68, 68, 1)",
                          lineHeight: "22px"
                        } as any
                      }
                    >
                      <KRow
                        id="587884"
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 24,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: "15px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      >
                        <KCol
                          id="803540"
                          xs={11}
                          sm={11}
                          md={11}
                          lg={11}
                          xl={11}
                          xxl={11}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderTopLeftRadius: 8,
                              borderTopRightRadius: 8,
                              borderBottomRightRadius: 8,
                              borderBottomLeftRadius: 8,
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: "15px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        >
                          <KRow
                            id="98649"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderTopLeftRadius: 12,
                                borderTopRightRadius: 12,
                                borderBottomRightRadius: 0,
                                borderBottomLeftRadius: 0,
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundColor: "rgba(255, 255, 255, 1)",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 20,
                                paddingRight: 16,
                                paddingBottom: 16,
                                paddingLeft: 16,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "15px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          >
                            <KCol
                              id="495864"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-center",
                                  fontFamily: "Poppins",
                                  fontWeight: 400,
                                  fontSize: "15px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            >
                              <Label
                                id="569640"
                                onClick={(e?: any) => {
                                  if (e && e.stopPropagation) e.stopPropagation();
                                  this.Product_DetailsComponent_569640_onClick();
                                }}
                                showCursorPointer
                                value={ReactSystemFunctions.translate(this.ml, 569640, "value", this.defaultML)}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 8,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-right",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 400,
                                    fontSize: "15px",
                                    color: "rgba(0, 118, 255, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              ></Label>

                              <KPanel
                                id="14165"
                                scrolling="hidden"
                                scrollRadius={false}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 400,
                                    fontSize: "15px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="product_details_443286_value">
                                  <Image
                                    id="443286"
                                    placeHolderImage="a44e1d2f-a8d4-467b-70ce-08db9d1adc29"
                                    zoomOnClick={false}
                                    imageFit="stretch"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        width: "100%",
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "15px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  ></Image>
                                </Form.Item>
                              </KPanel>
                            </KCol>
                          </KRow>

                          <KRow
                            id="321216"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderTopLeftRadius: 0,
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 12,
                                borderBottomLeftRadius: 12,
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundColor: "rgba(255, 255, 255, 1)",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 8,
                                paddingRight: 16,
                                paddingBottom: 8,
                                paddingLeft: 16,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "15px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          >
                            <KCol
                              id="36449"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 16,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 400,
                                  fontSize: "15px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            >
                              <VerticalStack
                                id="389629"
                                direction="vertical"
                                size={16}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 400,
                                    fontSize: "15px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <KRow
                                  id="869459"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "15px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="261512"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "15px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <HorizontalStack
                                      id="25894"
                                      direction="horizontal"
                                      size={4}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 400,
                                          fontSize: "15px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="839719"
                                        value={this.state.ProductCategoryById?.at?.(0)?.parentName ?? undefined}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 4,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "block",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "15px",
                                            color: "rgba(155, 155, 155, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      ></Label>
                                    </HorizontalStack>

                                    <HorizontalStack
                                      id="657340"
                                      direction="horizontal"
                                      size={12}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 400,
                                          fontSize: "15px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <Icon
                                        id="690992"
                                        iconName="done"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "16px",
                                            color: "rgba(155, 155, 155, 1)",
                                            letterSpacing: "1px"
                                          } as any
                                        }
                                      ></Icon>

                                      <Label
                                        id="710309"
                                        value={this.state.ProductCategoryById?.at?.(0)?.subBrandName ?? undefined}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "block",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "15px",
                                            color: "rgba(155, 155, 155, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      ></Label>
                                    </HorizontalStack>
                                  </KCol>
                                </KRow>

                                <Label
                                  id="345227"
                                  value={this.state.ProductCategoryById?.at?.(0)?.definition ?? undefined}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "20px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "36px"
                                    } as any
                                  }
                                ></Label>

                                <HorizontalStack
                                  id="371531"
                                  visibility={this.state.isComp371531Visible}
                                  direction="horizontal"
                                  size={12}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "15px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <Icon
                                    id="318676"
                                    iconName="inventory_2"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "20px",
                                        color: "rgba(155, 155, 155, 1)",
                                        letterSpacing: "1px"
                                      } as any
                                    }
                                  ></Icon>

                                  <Label
                                    id="744792"
                                    value={ReactSystemFunctions.translate(this.ml, 744792, "value", this.defaultML)}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "20px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "36px"
                                      } as any
                                    }
                                  ></Label>

                                  <Label
                                    id="829141"
                                    value={ReactSystemFunctions.translate(this.ml, 829141, "value", this.defaultML)}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "20px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "36px"
                                      } as any
                                    }
                                  ></Label>

                                  <Label
                                    id="255017"
                                    value={ReactSystemFunctions.translate(this.ml, 255017, "value", this.defaultML)}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "20px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "36px"
                                      } as any
                                    }
                                  ></Label>

                                  <Label
                                    id="692778"
                                    value={ReactSystemFunctions.translate(this.ml, 692778, "value", this.defaultML)}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "20px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "36px"
                                      } as any
                                    }
                                  ></Label>

                                  <Label
                                    id="553151"
                                    value={ReactSystemFunctions.translate(this.ml, 553151, "value", this.defaultML)}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "20px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "36px"
                                      } as any
                                    }
                                  ></Label>
                                </HorizontalStack>

                                <HorizontalStack
                                  id="613436"
                                  visibility={this.state.isComp613436Visible}
                                  direction="horizontal"
                                  size={12}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "15px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="483029"
                                    value={ReactSystemFunctions.translate(this.ml, 483029, "value", this.defaultML)}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-right",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "15px",
                                        color: "rgba(155, 155, 155, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  ></Label>

                                  <Label
                                    id="427404"
                                    value={ReactSystemFunctions.translate(this.ml, 427404, "value", this.defaultML)}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-right",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "15px",
                                        color: "rgba(155, 155, 155, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  ></Label>
                                </HorizontalStack>
                              </VerticalStack>
                            </KCol>
                          </KRow>

                          <KRow
                            id="657640"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderTopLeftRadius: 0,
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 12,
                                borderBottomLeftRadius: 12,
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundColor: "rgba(255, 255, 255, 1)",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 16,
                                paddingRight: 16,
                                paddingBottom: 8,
                                paddingLeft: 16,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "15px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          >
                            <KCol
                              id="334833"
                              xs={8}
                              sm={8}
                              md={8}
                              lg={8}
                              xl={8}
                              xxl={8}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 400,
                                  fontSize: "15px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            >
                              <Label
                                id="866893"
                                value={ReactSystemFunctions.translate(this.ml, 866893, "value", this.defaultML)}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 400,
                                    fontSize: "15px",
                                    color: "rgba(155, 155, 155, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              ></Label>
                            </KCol>

                            <KCol
                              id="584844"
                              xs={8}
                              sm={8}
                              md={8}
                              lg={8}
                              xl={8}
                              xxl={8}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-right",
                                  fontFamily: "Poppins",
                                  fontWeight: 400,
                                  fontSize: "15px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            ></KCol>

                            <KCol
                              id="220563"
                              xs={8}
                              sm={8}
                              md={8}
                              lg={8}
                              xl={8}
                              xxl={8}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-right",
                                  fontFamily: "Poppins",
                                  fontWeight: 400,
                                  fontSize: "15px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            >
                              <Label
                                id="967619"
                                onClick={(e?: any) => {
                                  if (e && e.stopPropagation) e.stopPropagation();
                                  this.Product_DetailsComponent_967619_onClick();
                                }}
                                showCursorPointer
                                value={ReactSystemFunctions.translate(this.ml, 967619, "value", this.defaultML)}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 400,
                                    fontSize: "15px",
                                    color: "rgba(0, 118, 255, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              ></Label>
                            </KCol>

                            <KCol
                              id="151736"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 16,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 400,
                                  fontSize: "15px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            >
                              <VerticalStack
                                id="550190"
                                direction="vertical"
                                size={12}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 400,
                                    fontSize: "15px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <KTable
                                  id="722378"
                                  kuikaRef={this.product_details_722378_value_kuikaTableRef}
                                  form={this.props.form}
                                  dataSource={this.state.ProductListByProductCategoryId}
                                  size="middle"
                                  bordered={true}
                                  showHeader={true}
                                  loading={false}
                                  nodatafoundmessage={ReactSystemFunctions.translate(
                                    this.ml,
                                    722378,
                                    "nodatafoundmessage",
                                    this.defaultML
                                  )}
                                  sorter={false}
                                  pagination={false}
                                  striped={false}
                                  stripedColor="#F5F7FA"
                                  insertRowActive={false}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "15px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <KTableHeader
                                    id="882882"
                                    hideOnMobileResolution={false}
                                    textDirection="Default"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "12px",
                                        color: "rgba(155, 155, 155, 1)",
                                        lineHeight: "20px"
                                      } as any
                                    }
                                  ></KTableHeader>

                                  <KTableRow
                                    id="106917"
                                    hoverFontColor="red"
                                    hoverBgColor="#F5F5F5"
                                    style={
                                      {
                                        borderTopWidth: 1,
                                        borderRightWidth: 1,
                                        borderBottomWidth: 1,
                                        borderLeftWidth: 1,
                                        borderColor: "rgba(218, 218, 218, 1)",
                                        borderStyle: "solid",
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "15px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <KTableColumn
                                      id="666785"
                                      title={ReactSystemFunctions.translate(this.ml, 666785, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Poppins",
                                          fontWeight: 400,
                                          fontSize: "15px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="33854"
                                        value="[datafield:productCode]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "15px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="134898"
                                      title={ReactSystemFunctions.translate(this.ml, 134898, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Poppins",
                                          fontWeight: 400,
                                          fontSize: "15px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="335051"
                                        value="[datafield:netGramaj]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "15px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="267086"
                                      title={ReactSystemFunctions.translate(this.ml, 267086, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Poppins",
                                          fontWeight: 400,
                                          fontSize: "15px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="740965"
                                        value="[datafield:piecesInBox]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "15px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="586715"
                                      title={ReactSystemFunctions.translate(this.ml, 586715, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Poppins",
                                          fontWeight: 400,
                                          fontSize: "15px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="796551"
                                        value="[datafield:volume]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "15px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="704918"
                                      title={ReactSystemFunctions.translate(this.ml, 704918, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Poppins",
                                          fontWeight: 400,
                                          fontSize: "15px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="298917"
                                        value="[datafield:parcelnBox]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "15px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="515784"
                                      title={ReactSystemFunctions.translate(this.ml, 515784, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Poppins",
                                          fontWeight: 400,
                                          fontSize: "15px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <HorizontalStack
                                        id="729808"
                                        direction="horizontal"
                                        size={12}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "15px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <Icon
                                          id="961139"
                                          visExField1="[datafield:isActive]"
                                          visExField2="false"
                                          visExRule="isEqualTo"
                                          iconName="circle"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "20px",
                                              color: "rgba(155, 155, 155, 1)",
                                              letterSpacing: "1px"
                                            } as any
                                          }
                                        ></Icon>

                                        <Icon
                                          id="331275"
                                          visExField1="[datafield:isActive]"
                                          visExField2="true"
                                          visExRule="isEqualTo"
                                          iconName="circle"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "20px",
                                              color: "rgba(126, 211, 33, 1)",
                                              letterSpacing: "1px"
                                            } as any
                                          }
                                        ></Icon>
                                      </HorizontalStack>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="995325"
                                      title={ReactSystemFunctions.translate(this.ml, 995325, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Poppins",
                                          fontWeight: 400,
                                          fontSize: "15px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <HorizontalStack
                                        id="902353"
                                        direction="horizontal"
                                        size={12}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "15px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <Icon
                                          id="307283"
                                          onClick={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.Product_DetailsComponent_307283_onClick();
                                          }}
                                          showCursorPointer
                                          iconName="edit"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "20px",
                                              color: "rgba(245, 166, 35, 1)",
                                              letterSpacing: "1px"
                                            } as any
                                          }
                                        ></Icon>

                                        <Icon
                                          id="26569"
                                          onClick={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.Product_DetailsComponent_26569_onClick();
                                          }}
                                          showCursorPointer
                                          iconName="delete"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "20px",
                                              color: "rgba(208, 2, 27, 1)",
                                              letterSpacing: "1px"
                                            } as any
                                          }
                                        ></Icon>
                                      </HorizontalStack>
                                    </KTableColumn>
                                  </KTableRow>
                                </KTable>
                              </VerticalStack>
                            </KCol>
                          </KRow>
                        </KCol>

                        <KCol
                          id="262917"
                          xs={13}
                          sm={13}
                          md={13}
                          lg={13}
                          xl={13}
                          xxl={13}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 8,
                              paddingLeft: 24,
                              alignItems: "flex-start",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: "15px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        >
                          <KRow
                            id="718910"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderTopLeftRadius: 12,
                                borderTopRightRadius: 12,
                                borderBottomRightRadius: 12,
                                borderBottomLeftRadius: 12,
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundColor: "rgba(255, 255, 255, 1)",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 16,
                                paddingRight: 16,
                                paddingBottom: 16,
                                paddingLeft: 16,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "15px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          >
                            <KCol
                              id="557462"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 400,
                                  fontSize: "15px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            >
                              <Form.Item className="kFormItem" name="product_details_127103_value" noStyle>
                                <KTab
                                  id="127103"
                                  type="line"
                                  size="default"
                                  tabPosition="top"
                                  selectedTabColor="rgba(0, 118, 255, 1)"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "15px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <KTabItem
                                    id="750885"
                                    tab={ReactSystemFunctions.translate(this.ml, 750885, "tab", this.defaultML)}
                                    key={750885}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundColor: "rgba(255, 255, 255, 1)",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "15px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <KRow
                                      id="444639"
                                      editability={this.state.isComp444639Enabled}
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderBottomWidth: 1,
                                          borderColor: "rgba(233, 233, 233, 1)",
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          paddingRight: 0,
                                          paddingBottom: 16,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 400,
                                          fontSize: "15px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="915327"
                                        xs={8}
                                        sm={8}
                                        md={8}
                                        lg={8}
                                        xl={8}
                                        xxl={8}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "15px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <Tooltip
                                          id="936028"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            936028,
                                            "title",
                                            this.defaultML
                                          )}
                                          placement="top"
                                          color="white"
                                          style={
                                            {
                                              borderTopLeftRadius: 8,
                                              borderTopRightRadius: 8,
                                              borderBottomRightRadius: 8,
                                              borderBottomLeftRadius: 8,
                                              borderTopWidth: 1,
                                              borderRightWidth: 1,
                                              borderBottomWidth: 1,
                                              borderLeftWidth: 1,
                                              borderColor: "rgba(218, 218, 218, 1)",
                                              borderStyle: "solid",
                                              backgroundColor: "rgba(255, 255, 255, 1)",
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              fontFamily: "Poppins",
                                              fontWeight: 400,
                                              fontSize: "12px",
                                              color: "rgba(155, 155, 155, 1)",
                                              lineHeight: "20px"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="69060"
                                            value={ReactSystemFunctions.translate(
                                              this.ml,
                                              69060,
                                              "value",
                                              this.defaultML
                                            )}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 8,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 300,
                                                fontSize: "20px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          ></Label>

                                          <Icon
                                            id="126706"
                                            iconName="info"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(155, 155, 155, 1)",
                                                letterSpacing: "1px"
                                              } as any
                                            }
                                          ></Icon>
                                        </Tooltip>
                                      </KCol>

                                      <KCol
                                        id="656069"
                                        xs={4}
                                        sm={4}
                                        md={4}
                                        lg={4}
                                        xl={4}
                                        xxl={4}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "15px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <Tooltip
                                          id="698236"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            698236,
                                            "title",
                                            this.defaultML
                                          )}
                                          placement="top"
                                          color="white"
                                          style={
                                            {
                                              borderTopLeftRadius: 8,
                                              borderTopRightRadius: 8,
                                              borderBottomRightRadius: 8,
                                              borderBottomLeftRadius: 8,
                                              borderTopWidth: 1,
                                              borderRightWidth: 1,
                                              borderBottomWidth: 1,
                                              borderLeftWidth: 1,
                                              borderColor: "rgba(218, 218, 218, 1)",
                                              borderStyle: "solid",
                                              backgroundColor: "rgba(255, 255, 255, 1)",
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              fontFamily: "Poppins",
                                              fontWeight: 400,
                                              fontSize: "12px",
                                              color: "rgba(155, 155, 155, 1)",
                                              lineHeight: "20px"
                                            } as any
                                          }
                                        >
                                          <VerticalStack
                                            id="17541"
                                            direction="vertical"
                                            size={4}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                textAlign: "-webkit-center",
                                                fontFamily: "Poppins",
                                                fontWeight: 400,
                                                fontSize: "15px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="145993"
                                              value={ReactSystemFunctions.translate(
                                                this.ml,
                                                145993,
                                                "value",
                                                this.defaultML
                                              )}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 0,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 400,
                                                  fontSize: "12px",
                                                  color: "rgba(68, 68, 68, 1)",
                                                  lineHeight: "22px"
                                                } as any
                                              }
                                            ></Label>

                                            <Form.Item className="kFormItem" name="product_details_86423_value">
                                              <Switch
                                                id="86423"
                                                onChange={(e?: any) => {
                                                  if (e && e.stopPropagation) e.stopPropagation();
                                                  this.Product_DetailsComponent_86423_onChange();
                                                }}
                                                disabled={false}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 0,
                                                    paddingRight: 0,
                                                    paddingBottom: 0,
                                                    paddingLeft: 0,
                                                    fontFamily: "Poppins",
                                                    fontWeight: 400,
                                                    fontSize: "15px",
                                                    color: "rgba(68, 68, 68, 1)",
                                                    lineHeight: "22px"
                                                  } as any
                                                }
                                              ></Switch>
                                            </Form.Item>
                                          </VerticalStack>
                                        </Tooltip>
                                      </KCol>

                                      <KCol
                                        id="845353"
                                        xs={4}
                                        sm={4}
                                        md={4}
                                        lg={4}
                                        xl={4}
                                        xxl={4}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "15px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <Tooltip
                                          id="625630"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            625630,
                                            "title",
                                            this.defaultML
                                          )}
                                          placement="top"
                                          color="white"
                                          style={
                                            {
                                              borderTopLeftRadius: 8,
                                              borderTopRightRadius: 8,
                                              borderBottomRightRadius: 8,
                                              borderBottomLeftRadius: 8,
                                              borderTopWidth: 1,
                                              borderRightWidth: 1,
                                              borderBottomWidth: 1,
                                              borderLeftWidth: 1,
                                              borderColor: "rgba(218, 218, 218, 1)",
                                              borderStyle: "solid",
                                              backgroundColor: "rgba(255, 255, 255, 1)",
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              fontFamily: "Poppins",
                                              fontWeight: 400,
                                              fontSize: "12px",
                                              color: "rgba(155, 155, 155, 1)",
                                              lineHeight: "20px"
                                            } as any
                                          }
                                        >
                                          <VerticalStack
                                            id="761561"
                                            direction="vertical"
                                            size={4}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                textAlign: "-webkit-center",
                                                fontFamily: "Poppins",
                                                fontWeight: 400,
                                                fontSize: "15px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="790145"
                                              value={ReactSystemFunctions.translate(
                                                this.ml,
                                                790145,
                                                "value",
                                                this.defaultML
                                              )}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 0,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 400,
                                                  fontSize: "12px",
                                                  color: "rgba(68, 68, 68, 1)",
                                                  lineHeight: "22px"
                                                } as any
                                              }
                                            ></Label>

                                            <Form.Item className="kFormItem" name="product_details_834087_value">
                                              <Switch
                                                id="834087"
                                                onChange={(e?: any) => {
                                                  if (e && e.stopPropagation) e.stopPropagation();
                                                  this.Product_DetailsComponent_834087_onChange();
                                                }}
                                                disabled={false}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 0,
                                                    paddingRight: 0,
                                                    paddingBottom: 0,
                                                    paddingLeft: 0,
                                                    fontFamily: "Poppins",
                                                    fontWeight: 400,
                                                    fontSize: "15px",
                                                    color: "rgba(68, 68, 68, 1)",
                                                    lineHeight: "22px"
                                                  } as any
                                                }
                                              ></Switch>
                                            </Form.Item>
                                          </VerticalStack>
                                        </Tooltip>
                                      </KCol>

                                      <KCol
                                        id="50679"
                                        xs={4}
                                        sm={4}
                                        md={4}
                                        lg={4}
                                        xl={4}
                                        xxl={4}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "15px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <Tooltip
                                          id="486726"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            486726,
                                            "title",
                                            this.defaultML
                                          )}
                                          placement="top"
                                          color="white"
                                          style={
                                            {
                                              borderTopLeftRadius: 8,
                                              borderTopRightRadius: 8,
                                              borderBottomRightRadius: 8,
                                              borderBottomLeftRadius: 8,
                                              borderTopWidth: 1,
                                              borderRightWidth: 1,
                                              borderBottomWidth: 1,
                                              borderLeftWidth: 1,
                                              borderColor: "rgba(218, 218, 218, 1)",
                                              borderStyle: "solid",
                                              backgroundColor: "rgba(255, 255, 255, 1)",
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              fontFamily: "Poppins",
                                              fontWeight: 400,
                                              fontSize: "12px",
                                              color: "rgba(155, 155, 155, 1)",
                                              lineHeight: "20px"
                                            } as any
                                          }
                                        >
                                          <VerticalStack
                                            id="218415"
                                            direction="vertical"
                                            size={4}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                textAlign: "-webkit-center",
                                                fontFamily: "Poppins",
                                                fontWeight: 400,
                                                fontSize: "15px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="946301"
                                              value={ReactSystemFunctions.translate(
                                                this.ml,
                                                946301,
                                                "value",
                                                this.defaultML
                                              )}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 0,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 400,
                                                  fontSize: "12px",
                                                  color: "rgba(68, 68, 68, 1)",
                                                  lineHeight: "22px"
                                                } as any
                                              }
                                            ></Label>

                                            <Form.Item className="kFormItem" name="product_details_932099_value">
                                              <Switch
                                                id="932099"
                                                onChange={(e?: any) => {
                                                  if (e && e.stopPropagation) e.stopPropagation();
                                                  this.Product_DetailsComponent_932099_onChange();
                                                }}
                                                disabled={false}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 0,
                                                    paddingRight: 0,
                                                    paddingBottom: 0,
                                                    paddingLeft: 0,
                                                    fontFamily: "Poppins",
                                                    fontWeight: 400,
                                                    fontSize: "15px",
                                                    color: "rgba(68, 68, 68, 1)",
                                                    lineHeight: "22px"
                                                  } as any
                                                }
                                              ></Switch>
                                            </Form.Item>
                                          </VerticalStack>
                                        </Tooltip>
                                      </KCol>

                                      <KCol
                                        id="495100"
                                        xs={4}
                                        sm={4}
                                        md={4}
                                        lg={4}
                                        xl={4}
                                        xxl={4}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "15px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <Tooltip
                                          id="762411"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            762411,
                                            "title",
                                            this.defaultML
                                          )}
                                          placement="top"
                                          color="white"
                                          style={
                                            {
                                              borderTopLeftRadius: 8,
                                              borderTopRightRadius: 8,
                                              borderBottomRightRadius: 8,
                                              borderBottomLeftRadius: 8,
                                              borderTopWidth: 1,
                                              borderRightWidth: 1,
                                              borderBottomWidth: 1,
                                              borderLeftWidth: 1,
                                              borderColor: "rgba(218, 218, 218, 1)",
                                              borderStyle: "solid",
                                              backgroundColor: "rgba(255, 255, 255, 1)",
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              fontFamily: "Poppins",
                                              fontWeight: 400,
                                              fontSize: "12px",
                                              color: "rgba(155, 155, 155, 1)",
                                              lineHeight: "20px"
                                            } as any
                                          }
                                        >
                                          <VerticalStack
                                            id="410349"
                                            direction="vertical"
                                            size={4}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                textAlign: "-webkit-center",
                                                fontFamily: "Poppins",
                                                fontWeight: 400,
                                                fontSize: "15px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="940079"
                                              value={ReactSystemFunctions.translate(
                                                this.ml,
                                                940079,
                                                "value",
                                                this.defaultML
                                              )}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 0,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 400,
                                                  fontSize: "12px",
                                                  color: "rgba(68, 68, 68, 1)",
                                                  lineHeight: "22px"
                                                } as any
                                              }
                                            ></Label>

                                            <Form.Item className="kFormItem" name="product_details_299888_value">
                                              <Switch
                                                id="299888"
                                                onChange={(e?: any) => {
                                                  if (e && e.stopPropagation) e.stopPropagation();
                                                  this.Product_DetailsComponent_299888_onChange();
                                                }}
                                                disabled={false}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 0,
                                                    paddingRight: 0,
                                                    paddingBottom: 0,
                                                    paddingLeft: 0,
                                                    fontFamily: "Poppins",
                                                    fontWeight: 400,
                                                    fontSize: "15px",
                                                    color: "rgba(68, 68, 68, 1)",
                                                    lineHeight: "22px"
                                                  } as any
                                                }
                                              ></Switch>
                                            </Form.Item>
                                          </VerticalStack>
                                        </Tooltip>
                                      </KCol>
                                    </KRow>

                                    <KRow
                                      id="235913"
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 16,
                                          paddingLeft: 0,
                                          alignItems: "flex-start",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Poppins",
                                          fontWeight: 400,
                                          fontSize: "15px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="377037"
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={24}
                                        xxl={24}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "15px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <VerticalStack
                                          id="651593"
                                          direction="vertical"
                                          size={4}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              fontFamily: "Poppins",
                                              fontWeight: 400,
                                              fontSize: "15px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <KTable
                                            id="841137"
                                            kuikaRef={this.product_details_841137_value_kuikaTableRef}
                                            form={this.props.form}
                                            dataSource={this.state.ProductCategoryById}
                                            size="middle"
                                            bordered={true}
                                            showHeader={true}
                                            loading={false}
                                            nodatafoundmessage={ReactSystemFunctions.translate(
                                              this.ml,
                                              841137,
                                              "nodatafoundmessage",
                                              this.defaultML
                                            )}
                                            sorter={false}
                                            pagination={false}
                                            striped={false}
                                            stripedColor="#F5F7FA"
                                            insertRowActive={false}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                fontFamily: "Poppins",
                                                fontWeight: 400,
                                                fontSize: "15px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          >
                                            <KTableHeader
                                              id="918629"
                                              hideOnMobileResolution={false}
                                              textDirection="Default"
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 8,
                                                  paddingRight: 8,
                                                  paddingBottom: 8,
                                                  paddingLeft: 8,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 400,
                                                  fontSize: "12px",
                                                  color: "rgba(155, 155, 155, 1)",
                                                  lineHeight: "20px"
                                                } as any
                                              }
                                            ></KTableHeader>

                                            <KTableRow
                                              id="130965"
                                              hoverFontColor="red"
                                              hoverBgColor="#F5F5F5"
                                              style={
                                                {
                                                  borderTopWidth: 1,
                                                  borderRightWidth: 1,
                                                  borderBottomWidth: 1,
                                                  borderLeftWidth: 1,
                                                  borderColor: "rgba(218, 218, 218, 1)",
                                                  borderStyle: "solid",
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 0,
                                                  paddingLeft: 0,
                                                  fontFamily: "Poppins",
                                                  fontWeight: 400,
                                                  fontSize: "15px",
                                                  color: "rgba(68, 68, 68, 1)",
                                                  lineHeight: "22px"
                                                } as any
                                              }
                                            >
                                              <KTableColumn
                                                id="1833"
                                                title={ReactSystemFunctions.translate(
                                                  this.ml,
                                                  1833,
                                                  "title",
                                                  this.defaultML
                                                )}
                                                alwaysVisibleOnMobileResolution={false}
                                                style={
                                                  {
                                                    borderTopWidth: 1,
                                                    borderRightWidth: 1,
                                                    borderBottomWidth: 1,
                                                    borderLeftWidth: 1,
                                                    borderColor: "rgba(218, 218, 218, 1)",
                                                    borderStyle: "solid",
                                                    paddingTop: 8,
                                                    paddingRight: 8,
                                                    paddingBottom: 8,
                                                    paddingLeft: 8,
                                                    width: "100px",
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 400,
                                                    fontSize: "15px",
                                                    color: "rgba(68, 68, 68, 1)",
                                                    lineHeight: "22px"
                                                  } as any
                                                }
                                              >
                                                <Label
                                                  id="462650"
                                                  value="[datafield:language]"
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 0,
                                                      paddingRight: 0,
                                                      paddingBottom: 0,
                                                      paddingLeft: 0,
                                                      alignItems: "center",
                                                      textAlign: "-webkit-left",
                                                      display: "inline",
                                                      fontFamily: "Poppins",
                                                      fontWeight: 500,
                                                      fontSize: "15px",
                                                      color: "rgba(0, 117, 255, 1)",
                                                      lineHeight: "22px"
                                                    } as any
                                                  }
                                                ></Label>
                                              </KTableColumn>

                                              <KTableColumn
                                                id="55980"
                                                title={ReactSystemFunctions.translate(
                                                  this.ml,
                                                  55980,
                                                  "title",
                                                  this.defaultML
                                                )}
                                                alwaysVisibleOnMobileResolution={false}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 8,
                                                    paddingRight: 8,
                                                    paddingBottom: 8,
                                                    paddingLeft: 8,
                                                    height: "44px",
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 400,
                                                    fontSize: "15px",
                                                    color: "rgba(68, 68, 68, 1)",
                                                    lineHeight: "22px"
                                                  } as any
                                                }
                                              >
                                                <VerticalStack
                                                  id="972852"
                                                  direction="vertical"
                                                  size={12}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 0,
                                                      paddingRight: 0,
                                                      paddingBottom: 0,
                                                      paddingLeft: 0,
                                                      fontFamily: "Poppins",
                                                      fontWeight: 400,
                                                      fontSize: "15px",
                                                      color: "rgba(68, 68, 68, 1)",
                                                      lineHeight: "22px"
                                                    } as any
                                                  }
                                                >
                                                  <Label
                                                    id="59621"
                                                    value="[datafield:definition]"
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 0,
                                                        paddingRight: 0,
                                                        paddingBottom: 0,
                                                        paddingLeft: 0,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        display: "inline",
                                                        fontFamily: "Poppins",
                                                        fontWeight: 300,
                                                        fontSize: "20px",
                                                        color: "rgba(68, 68, 68, 1)",
                                                        lineHeight: "22px"
                                                      } as any
                                                    }
                                                  ></Label>

                                                  <Label
                                                    id="446821"
                                                    value="[datafield:description]"
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 0,
                                                        paddingRight: 0,
                                                        paddingBottom: 0,
                                                        paddingLeft: 0,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        display: "inline",
                                                        fontFamily: "Poppins",
                                                        fontWeight: 400,
                                                        fontSize: "15px",
                                                        color: "rgba(68, 68, 68, 1)",
                                                        lineHeight: "22px"
                                                      } as any
                                                    }
                                                  ></Label>
                                                </VerticalStack>
                                              </KTableColumn>

                                              <KTableColumn
                                                id="272409"
                                                title={ReactSystemFunctions.translate(
                                                  this.ml,
                                                  272409,
                                                  "title",
                                                  this.defaultML
                                                )}
                                                alwaysVisibleOnMobileResolution={false}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 8,
                                                    paddingRight: 8,
                                                    paddingBottom: 8,
                                                    paddingLeft: 8,
                                                    width: "40px",
                                                    alignItems: "center",
                                                    textAlign: "-webkit-center",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 400,
                                                    fontSize: "15px",
                                                    color: "rgba(68, 68, 68, 1)",
                                                    lineHeight: "22px"
                                                  } as any
                                                }
                                              >
                                                <HorizontalStack
                                                  id="582159"
                                                  direction="horizontal"
                                                  size={5}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 8,
                                                      paddingRight: 8,
                                                      paddingBottom: 8,
                                                      paddingLeft: 8,
                                                      alignItems: "center",
                                                      textAlign: "-webkit-left",
                                                      fontFamily: "Poppins",
                                                      fontWeight: 400,
                                                      fontSize: "15px",
                                                      color: "rgba(68, 68, 68, 1)",
                                                      lineHeight: "22px"
                                                    } as any
                                                  }
                                                >
                                                  <Tooltip
                                                    id="514483"
                                                    title={ReactSystemFunctions.translate(
                                                      this.ml,
                                                      514483,
                                                      "title",
                                                      this.defaultML
                                                    )}
                                                    placement="top"
                                                    color="white"
                                                    style={
                                                      {
                                                        borderTopLeftRadius: 8,
                                                        borderTopRightRadius: 8,
                                                        borderBottomRightRadius: 8,
                                                        borderBottomLeftRadius: 8,
                                                        borderTopWidth: 1,
                                                        borderRightWidth: 1,
                                                        borderBottomWidth: 1,
                                                        borderLeftWidth: 1,
                                                        borderColor: "rgba(218, 218, 218, 1)",
                                                        borderStyle: "solid",
                                                        backgroundColor: "rgba(255, 255, 255, 1)",
                                                        backgroundRepeat: "no-repeat",
                                                        backgroundSize: "contain",
                                                        backgroundPosition: "left",
                                                        paddingTop: 8,
                                                        paddingRight: 8,
                                                        paddingBottom: 8,
                                                        paddingLeft: 8,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        fontFamily: "Poppins",
                                                        fontWeight: 400,
                                                        fontSize: "12px",
                                                        color: "rgba(155, 155, 155, 1)",
                                                        lineHeight: "20px"
                                                      } as any
                                                    }
                                                  >
                                                    <Icon
                                                      id="681564"
                                                      onClick={(e?: any) => {
                                                        if (e && e.stopPropagation) e.stopPropagation();
                                                        this.Product_DetailsComponent_681564_onClick();
                                                      }}
                                                      showCursorPointer
                                                      iconName="edit"
                                                      style={
                                                        {
                                                          borderStyle: "solid",
                                                          borderTopWidth: 0,
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          paddingTop: 8,
                                                          paddingRight: 8,
                                                          paddingBottom: 8,
                                                          paddingLeft: 8,
                                                          alignItems: "flex-start",
                                                          textAlign: "-webkit-left",
                                                          display: "inline",
                                                          fontFamily: "Poppins",
                                                          fontWeight: 500,
                                                          fontSize: "20px",
                                                          color: "rgba(245, 166, 35, 1)",
                                                          letterSpacing: "1px"
                                                        } as any
                                                      }
                                                    ></Icon>
                                                  </Tooltip>

                                                  <Tooltip
                                                    id="313844"
                                                    title={ReactSystemFunctions.translate(
                                                      this.ml,
                                                      313844,
                                                      "title",
                                                      this.defaultML
                                                    )}
                                                    placement="top"
                                                    color="white"
                                                    style={
                                                      {
                                                        borderTopLeftRadius: 8,
                                                        borderTopRightRadius: 8,
                                                        borderBottomRightRadius: 8,
                                                        borderBottomLeftRadius: 8,
                                                        borderTopWidth: 1,
                                                        borderRightWidth: 1,
                                                        borderBottomWidth: 1,
                                                        borderLeftWidth: 1,
                                                        borderColor: "rgba(218, 218, 218, 1)",
                                                        borderStyle: "solid",
                                                        backgroundColor: "rgba(255, 255, 255, 1)",
                                                        backgroundRepeat: "no-repeat",
                                                        backgroundSize: "contain",
                                                        backgroundPosition: "left",
                                                        paddingTop: 8,
                                                        paddingRight: 8,
                                                        paddingBottom: 8,
                                                        paddingLeft: 8,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        fontFamily: "Poppins",
                                                        fontWeight: 400,
                                                        fontSize: "12px",
                                                        color: "rgba(155, 155, 155, 1)",
                                                        lineHeight: "20px"
                                                      } as any
                                                    }
                                                  >
                                                    <Icon
                                                      id="797505"
                                                      onClick={(e?: any) => {
                                                        if (e && e.stopPropagation) e.stopPropagation();
                                                        this.Product_DetailsComponent_797505_onClick();
                                                      }}
                                                      showCursorPointer
                                                      visExField1="[datafield:language]"
                                                      visExField2="EN"
                                                      visExRule="isNotEqualTo"
                                                      iconName="delete"
                                                      style={
                                                        {
                                                          borderStyle: "solid",
                                                          borderTopWidth: 0,
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          paddingTop: 8,
                                                          paddingRight: 8,
                                                          paddingBottom: 8,
                                                          paddingLeft: 8,
                                                          alignItems: "flex-start",
                                                          textAlign: "-webkit-left",
                                                          display: "inline",
                                                          fontFamily: "Poppins",
                                                          fontWeight: 500,
                                                          fontSize: "20px",
                                                          color: "rgba(208, 2, 27, 1)",
                                                          letterSpacing: "1px"
                                                        } as any
                                                      }
                                                    ></Icon>
                                                  </Tooltip>
                                                </HorizontalStack>
                                              </KTableColumn>
                                            </KTableRow>
                                          </KTable>
                                        </VerticalStack>
                                      </KCol>
                                    </KRow>

                                    <KRow
                                      id="494563"
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderBottomWidth: 1,
                                          borderColor: "rgba(233, 233, 233, 1)",
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          paddingRight: 0,
                                          paddingBottom: 8,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 400,
                                          fontSize: "15px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="419198"
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={24}
                                        xxl={24}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-right",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "15px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <Button
                                          id="423689"
                                          onClick={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.Product_DetailsComponent_423689_onClick();
                                          }}
                                          showCursorPointer
                                          label={ReactSystemFunctions.translate(
                                            this.ml,
                                            423689,
                                            "label",
                                            this.defaultML
                                          )}
                                          size="middle"
                                          loading={false}
                                          ghost={false}
                                          block={false}
                                          htmlType="button"
                                          iconPosition="left"
                                          danger={false}
                                          style={
                                            {
                                              borderTopLeftRadius: 8,
                                              borderTopRightRadius: 8,
                                              borderBottomRightRadius: 8,
                                              borderBottomLeftRadius: 8,
                                              borderTopWidth: 1,
                                              borderRightWidth: 1,
                                              borderBottomWidth: 1,
                                              borderLeftWidth: 1,
                                              borderColor: "rgba(3, 119, 253, 1)",
                                              borderStyle: "solid",
                                              backgroundColor: "rgba(255, 255, 255, 1)",
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              paddingTop: 8,
                                              paddingRight: 16,
                                              paddingBottom: 8,
                                              paddingLeft: 16,
                                              height: "40px",
                                              alignItems: "center",
                                              textAlign: "-webkit-center",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "16px",
                                              color: "rgba(54, 112, 246, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        ></Button>
                                      </KCol>
                                    </KRow>
                                  </KTabItem>

                                  <KTabItem
                                    id="546777"
                                    visibility={this.state.isComp546777Visible}
                                    tab={ReactSystemFunctions.translate(this.ml, 546777, "tab", this.defaultML)}
                                    key={546777}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundColor: "rgba(255, 255, 255, 1)",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "15px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <KRow
                                      id="968736"
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "flex-start",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Poppins",
                                          fontWeight: 400,
                                          fontSize: "15px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="776475"
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={24}
                                        xxl={24}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "15px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <KRow
                                          id="563479"
                                          align="top"
                                          justify="start"
                                          horizontalGutter={0}
                                          verticalGutter={0}
                                          style={
                                            {
                                              borderBottomWidth: 1,
                                              borderColor: "rgba(247, 247, 247, 1)",
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 8,
                                              paddingRight: 0,
                                              paddingBottom: 16,
                                              paddingLeft: 0,
                                              fontFamily: "Poppins",
                                              fontWeight: 400,
                                              fontSize: "15px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <KCol
                                            id="354970"
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={14}
                                            xxl={14}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Poppins",
                                                fontWeight: 400,
                                                fontSize: "15px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          >
                                            <Form.Item className="kFormItem" name="product_details_180847_value">
                                              <KSelectBox
                                                id="180847"
                                                editability={this.state.isComp180847Enabled}
                                                kuikaRef={this.product_details_180847_value_kuikaSelectBoxRef}
                                                options={this.state.RegionByNotMatchProductId}
                                                placeholder={ReactSystemFunctions.translate(
                                                  this.ml,
                                                  180847,
                                                  "placeholder",
                                                  this.defaultML
                                                )}
                                                allowClear={false}
                                                autoClearSearchValue={true}
                                                showSearch={false}
                                                widthType="fill"
                                                containsNullItem={false}
                                                sortBy="none"
                                                datavaluefield="id"
                                                datatextfield="definition"
                                                style={
                                                  {
                                                    borderTopLeftRadius: 8,
                                                    borderTopRightRadius: 8,
                                                    borderBottomRightRadius: 8,
                                                    borderBottomLeftRadius: 8,
                                                    borderTopWidth: 1,
                                                    borderRightWidth: 1,
                                                    borderBottomWidth: 1,
                                                    borderLeftWidth: 1,
                                                    borderColor: "rgba(218, 218, 218, 1)",
                                                    borderStyle: "solid",
                                                    backgroundColor: "rgba(255, 255, 255, 1)",
                                                    backgroundRepeat: "no-repeat",
                                                    backgroundSize: "contain",
                                                    backgroundPosition: "left",
                                                    paddingTop: 8,
                                                    paddingRight: 8,
                                                    paddingBottom: 8,
                                                    paddingLeft: 8,
                                                    height: "44px",
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    display: "block",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 400,
                                                    fontSize: "15px",
                                                    color: "rgba(68, 68, 68, 1)",
                                                    lineHeight: "22px"
                                                  } as any
                                                }
                                              ></KSelectBox>
                                            </Form.Item>
                                          </KCol>

                                          <KCol
                                            id="965641"
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={2}
                                            xxl={2}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Poppins",
                                                fontWeight: 400,
                                                fontSize: "15px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          >
                                            <Icon
                                              id="493806"
                                              onClick={(e?: any) => {
                                                if (e && e.stopPropagation) e.stopPropagation();
                                                this.Product_DetailsComponent_493806_onClick();
                                              }}
                                              showCursorPointer
                                              iconName="add_circle"
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 8,
                                                  paddingRight: 8,
                                                  paddingBottom: 8,
                                                  paddingLeft: 8,
                                                  alignItems: "flex-start",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "20px",
                                                  color: "rgba(155, 155, 155, 1)",
                                                  letterSpacing: "1px"
                                                } as any
                                              }
                                            ></Icon>
                                          </KCol>

                                          <KCol
                                            id="499585"
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={8}
                                            xxl={8}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "center",
                                                textAlign: "-webkit-right",
                                                fontFamily: "Poppins",
                                                fontWeight: 400,
                                                fontSize: "15px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          >
                                            <Tooltip
                                              id="482408"
                                              title={ReactSystemFunctions.translate(
                                                this.ml,
                                                482408,
                                                "title",
                                                this.defaultML
                                              )}
                                              placement="top"
                                              color="white"
                                              style={
                                                {
                                                  borderTopLeftRadius: 8,
                                                  borderTopRightRadius: 8,
                                                  borderBottomRightRadius: 8,
                                                  borderBottomLeftRadius: 8,
                                                  borderTopWidth: 1,
                                                  borderRightWidth: 1,
                                                  borderBottomWidth: 1,
                                                  borderLeftWidth: 1,
                                                  borderColor: "rgba(218, 218, 218, 1)",
                                                  borderStyle: "solid",
                                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                                  backgroundRepeat: "no-repeat",
                                                  backgroundSize: "contain",
                                                  backgroundPosition: "left",
                                                  paddingTop: 8,
                                                  paddingRight: 8,
                                                  paddingBottom: 8,
                                                  paddingLeft: 8,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 400,
                                                  fontSize: "12px",
                                                  color: "rgba(155, 155, 155, 1)",
                                                  lineHeight: "20px"
                                                } as any
                                              }
                                            >
                                              <HorizontalStack
                                                id="673788"
                                                direction="horizontal"
                                                size={12}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 0,
                                                    paddingRight: 0,
                                                    paddingBottom: 0,
                                                    paddingLeft: 0,
                                                    fontFamily: "Poppins",
                                                    fontWeight: 400,
                                                    fontSize: "15px",
                                                    color: "rgba(68, 68, 68, 1)",
                                                    lineHeight: "22px"
                                                  } as any
                                                }
                                              >
                                                <Form.Item className="kFormItem" name="product_details_909820_value">
                                                  <Switch
                                                    id="909820"
                                                    onChange={(e?: any) => {
                                                      if (e && e.stopPropagation) e.stopPropagation();
                                                      this.Product_DetailsComponent_909820_onChange();
                                                    }}
                                                    disabled={false}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 0,
                                                        paddingRight: 0,
                                                        paddingBottom: 0,
                                                        paddingLeft: 0,
                                                        fontFamily: "Poppins",
                                                        fontWeight: 400,
                                                        fontSize: "15px",
                                                        color: "rgba(68, 68, 68, 1)",
                                                        lineHeight: "22px"
                                                      } as any
                                                    }
                                                  ></Switch>
                                                </Form.Item>

                                                <Label
                                                  id="37506"
                                                  value={ReactSystemFunctions.translate(
                                                    this.ml,
                                                    37506,
                                                    "value",
                                                    this.defaultML
                                                  )}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 0,
                                                      paddingRight: 0,
                                                      paddingBottom: 0,
                                                      paddingLeft: 0,
                                                      alignItems: "center",
                                                      textAlign: "-webkit-left",
                                                      display: "inline",
                                                      fontFamily: "Poppins",
                                                      fontWeight: 300,
                                                      fontSize: "20px",
                                                      color: "rgba(68, 68, 68, 1)",
                                                      lineHeight: "22px"
                                                    } as any
                                                  }
                                                ></Label>
                                              </HorizontalStack>
                                            </Tooltip>
                                          </KCol>
                                        </KRow>

                                        <KRow
                                          id="128926"
                                          align="top"
                                          justify="start"
                                          horizontalGutter={0}
                                          verticalGutter={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 8,
                                              fontFamily: "Poppins",
                                              fontWeight: 400,
                                              fontSize: "15px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <KCol
                                            id="873191"
                                            xs={24}
                                            sm={24}
                                            md={24}
                                            lg={24}
                                            xl={24}
                                            xxl={24}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                fontFamily: "Poppins",
                                                fontWeight: 400,
                                                fontSize: "15px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          >
                                            <KTable
                                              id="675061"
                                              kuikaRef={this.product_details_675061_value_kuikaTableRef}
                                              form={this.props.form}
                                              dataSource={this.state.RegionByProductId}
                                              size="middle"
                                              bordered={true}
                                              showHeader={true}
                                              loading={false}
                                              nodatafoundmessage={ReactSystemFunctions.translate(
                                                this.ml,
                                                675061,
                                                "nodatafoundmessage",
                                                this.defaultML
                                              )}
                                              sorter={false}
                                              pagination={false}
                                              striped={false}
                                              stripedColor="#F5F7FA"
                                              insertRowActive={false}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 0,
                                                  paddingLeft: 0,
                                                  fontFamily: "Poppins",
                                                  fontWeight: 400,
                                                  fontSize: "15px",
                                                  color: "rgba(68, 68, 68, 1)",
                                                  lineHeight: "22px"
                                                } as any
                                              }
                                            >
                                              <KTableHeader
                                                id="850414"
                                                hideOnMobileResolution={false}
                                                textDirection="Default"
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 8,
                                                    paddingRight: 8,
                                                    paddingBottom: 8,
                                                    paddingLeft: 8,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 400,
                                                    fontSize: "12px",
                                                    color: "rgba(155, 155, 155, 1)",
                                                    lineHeight: "20px"
                                                  } as any
                                                }
                                              ></KTableHeader>

                                              <KTableRow
                                                id="192882"
                                                hoverFontColor="red"
                                                hoverBgColor="#F5F5F5"
                                                style={
                                                  {
                                                    borderTopWidth: 1,
                                                    borderRightWidth: 1,
                                                    borderBottomWidth: 1,
                                                    borderLeftWidth: 1,
                                                    borderColor: "rgba(218, 218, 218, 1)",
                                                    borderStyle: "solid",
                                                    paddingTop: 0,
                                                    paddingRight: 0,
                                                    paddingBottom: 0,
                                                    paddingLeft: 0,
                                                    fontFamily: "Poppins",
                                                    fontWeight: 400,
                                                    fontSize: "15px",
                                                    color: "rgba(68, 68, 68, 1)",
                                                    lineHeight: "22px"
                                                  } as any
                                                }
                                              >
                                                <KTableColumn
                                                  id="393189"
                                                  title={ReactSystemFunctions.translate(
                                                    this.ml,
                                                    393189,
                                                    "title",
                                                    this.defaultML
                                                  )}
                                                  alwaysVisibleOnMobileResolution={false}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 8,
                                                      paddingRight: 8,
                                                      paddingBottom: 8,
                                                      paddingLeft: 8,
                                                      width: "124px",
                                                      alignItems: "center",
                                                      textAlign: "-webkit-center",
                                                      fontFamily: "Poppins",
                                                      fontWeight: 400,
                                                      fontSize: "15px",
                                                      color: "rgba(68, 68, 68, 1)",
                                                      lineHeight: "22px"
                                                    } as any
                                                  }
                                                >
                                                  <HorizontalStack
                                                    id="839799"
                                                    direction="horizontal"
                                                    size={12}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 0,
                                                        paddingRight: 0,
                                                        paddingBottom: 0,
                                                        paddingLeft: 0,
                                                        alignItems: "center",
                                                        fontFamily: "Poppins",
                                                        fontWeight: 400,
                                                        fontSize: "15px",
                                                        color: "rgba(68, 68, 68, 1)",
                                                        lineHeight: "22px"
                                                      } as any
                                                    }
                                                  >
                                                    <Icon
                                                      id="705814"
                                                      visExField1="[datafield:isActive]"
                                                      visExField2="false"
                                                      visExRule="isEqualTo"
                                                      iconName="circle"
                                                      style={
                                                        {
                                                          borderStyle: "solid",
                                                          borderTopWidth: 0,
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          paddingTop: 0,
                                                          paddingRight: 0,
                                                          paddingBottom: 0,
                                                          paddingLeft: 0,
                                                          alignItems: "center",
                                                          textAlign: "-webkit-left",
                                                          display: "inline",
                                                          fontFamily: "Poppins",
                                                          fontWeight: 500,
                                                          fontSize: "20px",
                                                          color: "rgba(155, 155, 155, 1)",
                                                          letterSpacing: "1px"
                                                        } as any
                                                      }
                                                    ></Icon>

                                                    <Icon
                                                      id="118699"
                                                      visExField1="[datafield:isActive]"
                                                      visExField2="true"
                                                      visExRule="isEqualTo"
                                                      iconName="circle"
                                                      style={
                                                        {
                                                          borderStyle: "solid",
                                                          borderTopWidth: 0,
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          paddingTop: 0,
                                                          paddingRight: 0,
                                                          paddingBottom: 0,
                                                          paddingLeft: 0,
                                                          alignItems: "center",
                                                          textAlign: "-webkit-left",
                                                          display: "inline",
                                                          fontFamily: "Poppins",
                                                          fontWeight: 500,
                                                          fontSize: "20px",
                                                          color: "rgba(126, 211, 33, 1)",
                                                          letterSpacing: "1px"
                                                        } as any
                                                      }
                                                    ></Icon>
                                                  </HorizontalStack>
                                                </KTableColumn>

                                                <KTableColumn
                                                  id="67066"
                                                  title={ReactSystemFunctions.translate(
                                                    this.ml,
                                                    67066,
                                                    "title",
                                                    this.defaultML
                                                  )}
                                                  alwaysVisibleOnMobileResolution={false}
                                                  style={
                                                    {
                                                      borderTopWidth: 1,
                                                      borderRightWidth: 1,
                                                      borderBottomWidth: 1,
                                                      borderLeftWidth: 1,
                                                      borderColor: "rgba(218, 218, 218, 1)",
                                                      borderStyle: "solid",
                                                      paddingTop: 8,
                                                      paddingRight: 8,
                                                      paddingBottom: 8,
                                                      paddingLeft: 8,
                                                      height: "44px",
                                                      alignItems: "center",
                                                      textAlign: "-webkit-left",
                                                      fontFamily: "Poppins",
                                                      fontWeight: 400,
                                                      fontSize: "15px",
                                                      color: "rgba(68, 68, 68, 1)",
                                                      lineHeight: "22px"
                                                    } as any
                                                  }
                                                >
                                                  <Label
                                                    id="258989"
                                                    value="[datafield:definition]"
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 0,
                                                        paddingRight: 0,
                                                        paddingBottom: 0,
                                                        paddingLeft: 0,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        display: "inline",
                                                        fontFamily: "Poppins",
                                                        fontWeight: 400,
                                                        fontSize: "15px",
                                                        color: "rgba(68, 68, 68, 1)",
                                                        lineHeight: "22px"
                                                      } as any
                                                    }
                                                  ></Label>
                                                </KTableColumn>

                                                <KTableColumn
                                                  id="285586"
                                                  title={ReactSystemFunctions.translate(
                                                    this.ml,
                                                    285586,
                                                    "title",
                                                    this.defaultML
                                                  )}
                                                  alwaysVisibleOnMobileResolution={false}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 8,
                                                      paddingRight: 8,
                                                      paddingBottom: 8,
                                                      paddingLeft: 8,
                                                      width: "10%",
                                                      alignItems: "center",
                                                      textAlign: "-webkit-center",
                                                      fontFamily: "Poppins",
                                                      fontWeight: 400,
                                                      fontSize: "15px",
                                                      color: "rgba(68, 68, 68, 1)",
                                                      lineHeight: "22px"
                                                    } as any
                                                  }
                                                >
                                                  <Icon
                                                    id="137999"
                                                    iconName="delete"
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 8,
                                                        paddingRight: 8,
                                                        paddingBottom: 8,
                                                        paddingLeft: 8,
                                                        alignItems: "flex-start",
                                                        textAlign: "-webkit-left",
                                                        display: "inline",
                                                        fontFamily: "Poppins",
                                                        fontWeight: 500,
                                                        fontSize: "20px",
                                                        color: "rgba(208, 2, 27, 1)",
                                                        letterSpacing: "1px"
                                                      } as any
                                                    }
                                                  ></Icon>
                                                </KTableColumn>
                                              </KTableRow>
                                            </KTable>
                                          </KCol>
                                        </KRow>
                                      </KCol>
                                    </KRow>
                                  </KTabItem>
                                </KTab>
                              </Form.Item>
                            </KCol>
                          </KRow>
                        </KCol>
                      </KRow>
                    </KPanel>
                  </KCol>
                </KRow>

                <KDrawer
                  id="822573"
                  placement="left"
                  onClose={() => {
                    this.setState({ NavVisible822573: false }, () => {
                      this.callCallbackFunction(822573);
                    });
                  }}
                  visible={this.state.NavVisible822573}
                  width="320px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible822573 && (
                      <LeftMenu_Screen
                        onClose={() => {
                          this.setState({ NavVisible822573: false }, () => {
                            this.callCallbackFunction(822573);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      fontSize: "15px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="269436"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible269436: false }, () => {
                      this.callCallbackFunction(269436);
                    });
                  }}
                  visible={this.state.NavVisible269436}
                  width="500px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible269436 && (
                      <Product_Translation_Screen
                        onClose={() => {
                          this.setState({ NavVisible269436: false }, () => {
                            this.callCallbackFunction(269436);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      fontSize: "15px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="232111"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible232111: false }, () => {
                      this.callCallbackFunction(232111);
                    });
                  }}
                  visible={this.state.NavVisible232111}
                  width="1000px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible232111 && (
                      <Product_NewCategory_Screen
                        onClose={() => {
                          this.setState({ NavVisible232111: false }, () => {
                            this.callCallbackFunction(232111);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      fontSize: "15px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="387927"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible387927: false }, () => {
                      this.callCallbackFunction(387927);
                    });
                  }}
                  visible={this.state.NavVisible387927}
                  width="1000px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible387927 && (
                      <Product_Translation_Screen
                        onClose={() => {
                          this.setState({ NavVisible387927: false }, () => {
                            this.callCallbackFunction(387927);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      fontSize: "15px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="889322"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible889322: false }, () => {
                      this.callCallbackFunction(889322);
                    });
                  }}
                  visible={this.state.NavVisible889322}
                  width="1000px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible889322 && (
                      <Product_NewCategory_Screen
                        onClose={() => {
                          this.setState({ NavVisible889322: false }, () => {
                            this.callCallbackFunction(889322);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      fontSize: "15px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="604560"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible604560: false }, () => {
                      this.callCallbackFunction(604560);
                    });
                  }}
                  visible={this.state.NavVisible604560}
                  width="800px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible604560 && (
                      <Product_New_Screen
                        onClose={() => {
                          this.setState({ NavVisible604560: false }, () => {
                            this.callCallbackFunction(604560);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      fontSize: "15px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="822699"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible822699: false }, () => {
                      this.callCallbackFunction(822699);
                    });
                  }}
                  visible={this.state.NavVisible822699}
                  width="800px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible822699 && (
                      <Product_New_Screen
                        onClose={() => {
                          this.setState({ NavVisible822699: false }, () => {
                            this.callCallbackFunction(822699);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      fontSize: "15px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                ></KDrawer>
              </div>
            </div>
          </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that))
});
const tmp = withGoogleLogin(
  withContext(
    withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(Product_Details_Screen))))
  )
);
export { tmp as Product_Details_Screen };
//export default tmp;
//export { tmp as Product_Details_Screen };
