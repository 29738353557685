import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IContact_UnpairedList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IContact_UnpairedList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  ContactsUnpairedSearch: any[];
  ContactUnpairedSearchCount: any[];
}

export class Contact_UnpairedList_ScreenBase extends React.PureComponent<IContact_UnpairedList_ScreenProps, any> {
  contact_unpairedlist_526539_value_kuikaTableRef: React.RefObject<any>;
  contact_unpairedlist_494996_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "937da27a-1820-c1c3-46e3-ec04ce38ecf6",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "6c5ee225-dfde-4210-b649-8bf8e99054f7",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 316688, PropertyName: "value", Value: "Unpaired contact list" },
        { Id: 640217, PropertyName: "label", Value: "My profile" },
        { Id: 126271, PropertyName: "label", Value: "Logout" },
        { Id: 612880, PropertyName: "placeholder", Value: "Search..." },
        { Id: 526539, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 867714, PropertyName: "title", Value: "First Name" },
        { Id: 772128, PropertyName: "value", Value: "[datafield:firstname]" },
        { Id: 641978, PropertyName: "title", Value: "Last Name" },
        { Id: 182786, PropertyName: "value", Value: "[datafield:lastname]" },
        { Id: 537916, PropertyName: "title", Value: "Email" },
        { Id: 440990, PropertyName: "value", Value: "[datafield:email]" },
        { Id: 629859, PropertyName: "title", Value: "Phone Number" },
        { Id: 622906, PropertyName: "value", Value: "[datafield:phonenumber]" },
        { Id: 275790, PropertyName: "title", Value: "Active" },
        { Id: 440795, PropertyName: "title", Value: "#" },
        { Id: 202231, PropertyName: "value", Value: "Total :" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.contact_unpairedlist_526539_value_kuikaTableRef = React.createRef();
    this.contact_unpairedlist_494996_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      ContactsUnpairedSearch: [],
      ContactUnpairedSearchCount: []
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("contact_unpairedlist", "contact_unpairedlist");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.Contact_UnpairedListPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("contact_unpairedlist", "contact_unpairedlist");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("contact_unpairedlist", "contact_unpairedlist");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.Contact_UnpairedListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  Contact_UnpairedListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      SearchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "contact_unpairedlist_612880_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "contact_unpairedlist_494996_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "contact_unpairedlist_494996_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      SearchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "contact_unpairedlist_612880_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "Contact_UnpairedList/Contact_UnpairedListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ContactsUnpairedSearch = result?.data.contactsUnpairedSearch;

    stateVars.ContactUnpairedSearchCount = result?.data.contactUnpairedSearchCount;
    formVars.contact_unpairedlist_152735_value = ReactSystemFunctions.toString(
      this,
      stateVars.ContactUnpairedSearchCount?.length > 0 ? stateVars.ContactUnpairedSearchCount[0]?.count : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Contact_UnpairedListPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Contact_UnpairedListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_526539 = this.state.ContactsUnpairedSearch;
    formVars.contact_unpairedlist_152735_value = ReactSystemFunctions.toString(
      this,
      this.state.ContactUnpairedSearchCount?.length > 0 ? this.state.ContactUnpairedSearchCount[0]?.count : null
    );

    formVars.contact_unpairedlist_494996_total = ReactSystemFunctions.value(
      this,
      this.state.ContactUnpairedSearchCount?.length > 0 ? this.state.ContactUnpairedSearchCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  Contact_UnpairedListComponent_276412_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Contact_UnpairedList",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "386677",
      null,
      "left",
      null,
      "320px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Contact_UnpairedListComponent_612880_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      SearchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "contact_unpairedlist_612880_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "contact_unpairedlist_494996_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "contact_unpairedlist_494996_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      SearchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "contact_unpairedlist_612880_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Contact_UnpairedList/Contact_UnpairedListComponent_612880_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ContactsUnpairedSearch = result?.data.contactsUnpairedSearch;

    stateVars.ContactUnpairedSearchCount = result?.data.contactUnpairedSearchCount;
    formVars.contact_unpairedlist_152735_value = ReactSystemFunctions.toString(
      this,
      stateVars.ContactUnpairedSearchCount?.length > 0 ? stateVars.ContactUnpairedSearchCount[0]?.count : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Contact_UnpairedListComponent_612880_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Contact_UnpairedListComponent_612880_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_526539 = this.state.ContactsUnpairedSearch;
    formVars.contact_unpairedlist_152735_value = ReactSystemFunctions.toString(
      this,
      this.state.ContactUnpairedSearchCount?.length > 0 ? this.state.ContactUnpairedSearchCount[0]?.count : null
    );

    formVars.contact_unpairedlist_494996_total = ReactSystemFunctions.value(
      this,
      this.state.ContactUnpairedSearchCount?.length > 0 ? this.state.ContactUnpairedSearchCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Contact_UnpairedListComponent_841681_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "Contact_UnpairedNew",
      "prmContactId",
      ReactSystemFunctions.value(this, "contact_unpairedlist_526539_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Contact_UnpairedList",
      "Contact_UnpairedNew",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "164913",
      null,
      "right",
      null,
      "800px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Contact_UnpairedListComponent_494996_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      SearchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "contact_unpairedlist_612880_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "contact_unpairedlist_494996_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "contact_unpairedlist_494996_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      SearchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "contact_unpairedlist_612880_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Contact_UnpairedList/Contact_UnpairedListComponent_494996_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ContactsUnpairedSearch = result?.data.contactsUnpairedSearch;

    stateVars.ContactUnpairedSearchCount = result?.data.contactUnpairedSearchCount;
    formVars.contact_unpairedlist_152735_value = ReactSystemFunctions.toString(
      this,
      stateVars.ContactUnpairedSearchCount?.length > 0 ? stateVars.ContactUnpairedSearchCount[0]?.count : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Contact_UnpairedListComponent_494996_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Contact_UnpairedListComponent_494996_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_526539 = this.state.ContactsUnpairedSearch;
    formVars.contact_unpairedlist_152735_value = ReactSystemFunctions.toString(
      this,
      this.state.ContactUnpairedSearchCount?.length > 0 ? this.state.ContactUnpairedSearchCount[0]?.count : null
    );

    formVars.contact_unpairedlist_494996_total = ReactSystemFunctions.value(
      this,
      this.state.ContactUnpairedSearchCount?.length > 0 ? this.state.ContactUnpairedSearchCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [386677, 164913] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.Contact_UnpairedListPageInit();
    }
  }
}
