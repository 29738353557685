import { FormInstance } from "antd/lib/form";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ICampaign_New_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ICampaign_New_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  CampaignDelete: number;
  GoBack: any;
  CampaignSave: number;
  CampaignImagesSave: number;
  GeneratedCampaignImgLinkSave: number;
  spCampaignNotification: any[];
  CustomPushNotification: string;
  Campaign: any[];
  Region: any[];
  PhotoFromGallery: string;
  SetValueOf: any;
  isComp570206Visible: "visible" | "hidden";
}

export class Campaign_New_ScreenBase extends React.PureComponent<ICampaign_New_ScreenProps, any> {
  campaign_new_925777_value_kuikaDatetimeRef: React.RefObject<any>;
  campaign_new_190049_value_kuikaDatetimeRef: React.RefObject<any>;
  campaign_new_472424_value_kuikaDatetimeRef: React.RefObject<any>;
  ml = [
    {
      Id: "937da27a-1820-c1c3-46e3-ec04ce38ecf6",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "297d4f2a-624e-4b61-84f1-bed9a77eb14c",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 570206, PropertyName: "value", Value: "DELETE" },
        { Id: 305562, PropertyName: "label", Value: "SAVE" },
        { Id: 57433, PropertyName: "label", Value: "SAVE AND PUBLISH NOW" },
        { Id: 286164, PropertyName: "value", Value: "New campaign" },
        { Id: 235050, PropertyName: "value", Value: "Campaign name" },
        { Id: 382409, PropertyName: "placeholder", Value: "Write campaign name..." },
        { Id: 817447, PropertyName: "value", Value: "Details" },
        { Id: 297133, PropertyName: "value", Value: "Koşullar" },
        { Id: 337026, PropertyName: "value", Value: "Cover image" },
        { Id: 380104, PropertyName: "value", Value: "UPLOAD" },
        { Id: 239887, PropertyName: "value", Value: "Image ratio : 4:3" },
        { Id: 299546, PropertyName: "value", Value: "Region(s)" },
        { Id: 72646, PropertyName: "value", Value: "Start Date" },
        { Id: 925777, PropertyName: "placeholder", Value: "Select Date Time..." },
        { Id: 733603, PropertyName: "value", Value: "End Date" },
        { Id: 190049, PropertyName: "placeholder", Value: "Select Date Time..." },
        { Id: 597025, PropertyName: "value", Value: "Submit Date" },
        { Id: 472424, PropertyName: "placeholder", Value: "Select Date Time..." }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.campaign_new_925777_value_kuikaDatetimeRef = React.createRef();
    this.campaign_new_190049_value_kuikaDatetimeRef = React.createRef();
    this.campaign_new_472424_value_kuikaDatetimeRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      CampaignDelete: 0,
      GoBack: "",
      CampaignSave: 0,
      CampaignImagesSave: 0,
      GeneratedCampaignImgLinkSave: 0,
      spCampaignNotification: [],
      CustomPushNotification: "",
      Campaign: [],
      Region: [],
      PhotoFromGallery: "",
      SetValueOf: "",
      isComp570206Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("campaign_new", "campaign_new");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.Campaign_NewPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("campaign_new", "campaign_new");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("campaign_new", "campaign_new");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.Campaign_NewPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      campaign_new_382409_value: this.state.Campaign?.at?.(0)?.campaignName ?? undefined,
      campaign_new_11521_value: this.state.Campaign?.at?.(0)?.campaignContent ?? undefined,
      campaign_new_390387_value: this.state.Campaign?.at?.(0)?.termsLink ?? undefined,
      campaign_new_551830_value: this.state.Campaign?.at?.(0)?.imageLink ?? undefined,
      campaign_new_675166_value: this.state.Campaign?.at?.(0)?.regionIds ?? undefined,
      campaign_new_925777_value: this.state.Campaign?.at?.(0)?.startDate ?? undefined,
      campaign_new_190049_value: this.state.Campaign?.at?.(0)?.endDate ?? undefined,
      campaign_new_472424_value: this.state.Campaign?.at?.(0)?.submitDate ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  Campaign_NewPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      CampaignId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmCampaignId ?? this.props.screenInputs.prmcampaignid,
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "Campaign_New/Campaign_NewPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Campaign = result?.data.campaign;
    formVars.campaign_new_382409_value = ReactSystemFunctions.toString(
      this,
      stateVars.Campaign?.length > 0 ? stateVars.Campaign[0]?.campaignName : null
    );
    formVars.campaign_new_551830_value = ReactSystemFunctions.toString(
      this,
      stateVars.Campaign?.length > 0 ? stateVars.Campaign[0]?.imageLink : null
    );
    formVars.campaign_new_675166_options = stateVars.Region;
    formVars.campaign_new_675166_value = stateVars.Campaign?.length > 0 ? stateVars.Campaign[0]?.regionIds : null;
    formVars.campaign_new_925777_value = ReactSystemFunctions.value(
      this,
      stateVars.Campaign?.length > 0 ? stateVars.Campaign[0]?.startDate : null
    );
    formVars.campaign_new_190049_value = ReactSystemFunctions.value(
      this,
      stateVars.Campaign?.length > 0 ? stateVars.Campaign[0]?.endDate : null
    );
    formVars.campaign_new_472424_value = ReactSystemFunctions.value(
      this,
      stateVars.Campaign?.length > 0 ? stateVars.Campaign[0]?.submitDate : null
    );
    stateVars.Region = result?.data.region;

    formVars.campaign_new_675166_options = stateVars.Region;
    formVars.campaign_new_675166_value = stateVars.Campaign?.length > 0 ? stateVars.Campaign[0]?.regionIds : null;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Campaign_NewPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Campaign_NewPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.isComp570206Visible =
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmPurpose ?? this.props.screenInputs.prmpurpose,
        "EDIT"
      ) === true
        ? "visible"
        : "hidden";
    formVars.campaign_new_382409_value = ReactSystemFunctions.toString(
      this,
      this.state.Campaign?.length > 0 ? this.state.Campaign[0]?.campaignName : null
    );

    formVars.campaign_new_11521_value = ReactSystemFunctions.toString(
      this,
      this.state.Campaign?.length > 0 ? this.state.Campaign[0]?.campaignContent : null
    );

    formVars.campaign_new_390387_value = ReactSystemFunctions.toString(
      this,
      this.state.Campaign?.length > 0 ? this.state.Campaign[0]?.termsLink : null
    );

    formVars.campaign_new_551830_value = ReactSystemFunctions.toString(
      this,
      this.state.Campaign?.length > 0 ? this.state.Campaign[0]?.imageLink : null
    );

    formVars.campaign_new_675166_value = ReactSystemFunctions.toString(
      this,
      this.state.Campaign?.length > 0 ? this.state.Campaign[0]?.regionIds : null
    );

    stateVars.dataSource_675166 = this.state.Region;
    formVars.campaign_new_925777_value = ReactSystemFunctions.value(
      this,
      this.state.Campaign?.length > 0 ? this.state.Campaign[0]?.startDate : null
    );

    formVars.campaign_new_190049_value = ReactSystemFunctions.value(
      this,
      this.state.Campaign?.length > 0 ? this.state.Campaign[0]?.endDate : null
    );

    formVars.campaign_new_472424_value = ReactSystemFunctions.value(
      this,
      this.state.Campaign?.length > 0 ? this.state.Campaign[0]?.submitDate : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  Campaign_NewComponent_570206_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CampaignId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmCampaignId ?? this.props.screenInputs.prmcampaignid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "826aaebd_295b_25fa_f9a0_b66f7cbf0027_confirmation",
        this.defaultML,
        "This campaign will be deleted. Are you sure?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    }).then(async (result) => {
      if (result.isConfirmed) {
        KuikaAppManager.showSpinner(this);
        let result = await KuikaAppManager.ApiRequest(
          this,
          "Campaign_New/Campaign_NewComponent_570206_onClick",
          localVar
        );
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

        stateVars.CampaignDelete = result?.data.campaignDelete;
        this.props.form.setFieldsValue(formVars);
        this.setState(stateVars, () => {
          this.Campaign_NewComponent_570206_onClick1_();
        });
      }
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Campaign_NewComponent_570206_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Campaign_NewComponent_305562_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "campaign_new_11521_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "campaign_new_11521_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "campaign_new_382409_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "campaign_new_382409_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "campaign_new_675166_value", "value", "Region", "id", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "campaign_new_675166_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CampaignContent_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "campaign_new_11521_value", "value", "", "", "")
        ),
        "string"
      ),
      CampaignName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "campaign_new_382409_value", "value", "", "", "")
        ),
        "string"
      ),
      EndDate_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "campaign_new_190049_value", "value", "", "", "")
        ),
        "Date"
      ),
      ImageLink_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      RegionIds_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "campaign_new_675166_value", "value", "Region", "id", "")
        ),
        "string"
      ),
      StartDate_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "campaign_new_925777_value", "value", "", "", "")
        ),
        "Date"
      ),
      SubmitDate_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "campaign_new_472424_value", "value", "", "", "")
        ),
        "Date"
      ),
      TermsLink_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "campaign_new_390387_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmCampaignId ?? this.props.screenInputs.prmcampaignid,
        "Guid"
      ),
      CampaignId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmCampaignId ?? this.props.screenInputs.prmcampaignid,
        "Guid"
      ),
      CampaignImage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "campaign_new_551830_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmImageId ?? this.props.screenInputs.prmimageid,
        "Guid"
      ),
      CampaignImgID_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmImageId ?? this.props.screenInputs.prmimageid,
        "string"
      ),
      CampaignId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmCampaignId ?? this.props.screenInputs.prmcampaignid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "Campaign_New/Campaign_NewComponent_305562_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CampaignSave = result?.data.campaignSave;
    stateVars.CampaignImagesSave = result?.data.campaignImagesSave;
    stateVars.GeneratedCampaignImgLinkSave = result?.data.generatedCampaignImgLinkSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Campaign_NewComponent_305562_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Campaign_NewComponent_305562_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Campaign_NewComponent_57433_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "campaign_new_11521_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "campaign_new_11521_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "campaign_new_382409_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "campaign_new_382409_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "campaign_new_675166_value", "value", "Region", "id", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "campaign_new_675166_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CampaignContent_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "campaign_new_11521_value", "value", "", "", "")
        ),
        "string"
      ),
      CampaignName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "campaign_new_382409_value", "value", "", "", "")
        ),
        "string"
      ),
      EndDate_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "campaign_new_190049_value", "value", "", "", "")
        ),
        "Date"
      ),
      ImageLink_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      RegionIds_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "campaign_new_675166_value", "value", "Region", "id", "")
        ),
        "string"
      ),
      StartDate_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      SubmitDate_0: ReactSystemFunctions.convertToTypeByName(null, "Date"),
      TermsLink_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "campaign_new_390387_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmCampaignId ?? this.props.screenInputs.prmcampaignid,
        "Guid"
      ),
      CampaignId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmCampaignId ?? this.props.screenInputs.prmcampaignid,
        "Guid"
      ),
      CampaignImage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "campaign_new_551830_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmImageId ?? this.props.screenInputs.prmimageid,
        "Guid"
      ),
      CampaignImgID_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmImageId ?? this.props.screenInputs.prmimageid,
        "string"
      ),
      CampaignId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmCampaignId ?? this.props.screenInputs.prmcampaignid,
        "Guid"
      ),
      isNow_3: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      defautCampID_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmCampaignId ?? this.props.screenInputs.prmcampaignid,
        "Guid"
      ),
      backendUrl_4: ReactSystemFunctions.convertToTypeByName("https://alyangidamobileapiV2.kuika.com", "string"),
      screenIdForMobile_4: ReactSystemFunctions.convertToTypeByName("f589ae10-1130-41a9-b0a5-d54903e3ed08", "string"),
      screenInputForMobile_4: ReactSystemFunctions.convertToTypeByName("", "string"),
      screenInputNameForMobile_4: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "Campaign_New/Campaign_NewComponent_57433_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CampaignSave = result?.data.campaignSave;
    stateVars.CampaignImagesSave = result?.data.campaignImagesSave;
    stateVars.GeneratedCampaignImgLinkSave = result?.data.generatedCampaignImgLinkSave;
    stateVars.spCampaignNotification = result?.data.spCampaignNotification;
    stateVars.CustomPushNotification = result?.data.customPushNotification;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Campaign_NewComponent_57433_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Campaign_NewComponent_57433_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Campaign_NewComponent_856813_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Campaign_NewComponent_380104_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.PhotoFromGallery = await ReactSystemFunctions.getPhotoFromGallery(false, "");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Campaign_NewComponent_380104_onClick1_();
    });

    return isErrorOccurred;
  };
  Campaign_NewComponent_380104_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.PhotoFromGallery), null)) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        "campaign_new_551830_value",
        ReactSystemFunctions.value(this, this.state.PhotoFromGallery),
        null
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
