import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ISubBrand_New_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ISubBrand_New_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  BrandSave: number;
  RegionsSaveBySubBrandId: any[];
  GoBack: any;
  BrandById: any[];
  ParentBrandByBrandId: any[];
  ParentBrand: any[];
  RegionsByParentBrandId: any[];
  SetValueOf: any;
}

export class SubBrand_New_ScreenBase extends React.PureComponent<ISubBrand_New_ScreenProps, any> {
  subbrand_new_366459_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "937da27a-1820-c1c3-46e3-ec04ce38ecf6",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "677e4bdb-5e2d-4c5d-add9-df5e074656a0",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 619802, PropertyName: "label", Value: "SAVE" },
        { Id: 607465, PropertyName: "value", Value: "Sub brand form" },
        { Id: 668015, PropertyName: "value", Value: "Name" },
        { Id: 661009, PropertyName: "placeholder", Value: "Monteyer" },
        { Id: 708129, PropertyName: "value", Value: "Description" },
        { Id: 786933, PropertyName: "placeholder", Value: "Sub brand name..." },
        { Id: 381900, PropertyName: "value", Value: "Parent Brand" },
        { Id: 366459, PropertyName: "placeholder", Value: "Alyan" },
        { Id: 161107, PropertyName: "value", Value: "Region(s)" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.subbrand_new_366459_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      BrandSave: 0,
      RegionsSaveBySubBrandId: [],
      GoBack: "",
      BrandById: [],
      ParentBrandByBrandId: [],
      ParentBrand: [],
      RegionsByParentBrandId: [],
      SetValueOf: ""
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("subbrand_new", "subbrand_new");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.SubBrand_NewPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("subbrand_new", "subbrand_new");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("subbrand_new", "subbrand_new");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.SubBrand_NewPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      subbrand_new_661009_value: this.state.BrandById?.at?.(0)?.name ?? undefined,
      subbrand_new_786933_value: this.state.BrandById?.at?.(0)?.description ?? undefined,
      subbrand_new_366459_value: this.state.ParentBrandByBrandId?.at?.(0)?.id ?? undefined,
      subbrand_new_227663_value: this.state.BrandById?.at?.(0)?.regionIds ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  SubBrand_NewPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmSubBrandId ?? this.props.screenInputs.prmsubbrandid,
        "Guid"
      ),
      BrandId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmParentId ?? this.props.screenInputs.prmparentid,
        "Guid"
      ),
      ParentBrandID_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmParentId ?? this.props.screenInputs.prmparentid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "SubBrand_New/SubBrand_NewPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.BrandById = result?.data.brandById;
    formVars.subbrand_new_661009_value = ReactSystemFunctions.toString(
      this,
      stateVars.BrandById?.length > 0 ? stateVars.BrandById[0]?.name : null
    );
    formVars.subbrand_new_786933_value = ReactSystemFunctions.toString(
      this,
      stateVars.BrandById?.length > 0 ? stateVars.BrandById[0]?.description : null
    );
    formVars.subbrand_new_227663_options = stateVars.RegionsByParentBrandId;
    formVars.subbrand_new_227663_value = stateVars.BrandById?.length > 0 ? stateVars.BrandById[0]?.regionIds : null;
    stateVars.ParentBrandByBrandId = result?.data.parentBrandByBrandId;
    formVars.subbrand_new_366459_value =
      stateVars.ParentBrandByBrandId?.length > 0 ? stateVars.ParentBrandByBrandId[0]?.id : null;
    formVars.subbrand_new_366459_options = stateVars.ParentBrand;
    stateVars.ParentBrand = result?.data.parentBrand;

    formVars.subbrand_new_366459_value =
      stateVars.ParentBrandByBrandId?.length > 0 ? stateVars.ParentBrandByBrandId[0]?.id : null;
    formVars.subbrand_new_366459_options = stateVars.ParentBrand;
    stateVars.RegionsByParentBrandId = result?.data.regionsByParentBrandId;

    formVars.subbrand_new_227663_options = stateVars.RegionsByParentBrandId;
    formVars.subbrand_new_227663_value = stateVars.BrandById?.length > 0 ? stateVars.BrandById[0]?.regionIds : null;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.SubBrand_NewPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SubBrand_NewPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.subbrand_new_661009_value = ReactSystemFunctions.toString(
      this,
      this.state.BrandById?.length > 0 ? this.state.BrandById[0]?.name : null
    );

    formVars.subbrand_new_786933_value = ReactSystemFunctions.toString(
      this,
      this.state.BrandById?.length > 0 ? this.state.BrandById[0]?.description : null
    );

    formVars.subbrand_new_366459_value = ReactSystemFunctions.toString(
      this,
      this.state.ParentBrandByBrandId?.length > 0 ? this.state.ParentBrandByBrandId[0]?.id : null
    );

    stateVars.dataSource_366459 = this.state.ParentBrand;

    formVars.subbrand_new_227663_value = ReactSystemFunctions.toString(
      this,
      this.state.BrandById?.length > 0 ? this.state.BrandById[0]?.regionIds : null
    );

    stateVars.dataSource_227663 = this.state.RegionsByParentBrandId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  SubBrand_NewComponent_619802_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "subbrand_new_661009_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "subbrand_new_661009_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "subbrand_new_661009_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "subbrand_new_786933_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "subbrand_new_366459_value", "value", "ParentBrand", "id", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "subbrand_new_366459_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Name_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "subbrand_new_661009_value", "value", "", "", "")
        ),
        "string"
      ),
      Description_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "subbrand_new_786933_value", "value", "", "", "")
        ),
        "string"
      ),
      ParentBrandId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "subbrand_new_366459_value", "value", "ParentBrand", "id", "id")
        ),
        "string"
      ),
      RegionIds_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "subbrand_new_227663_value", "value", "RegionsByParentBrandId", "id", "id")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmSubBrandId ?? this.props.screenInputs.prmsubbrandid,
        "Guid"
      ),
      RegionIds_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "subbrand_new_227663_value", "value", "RegionsByParentBrandId", "id", "id")
        ),
        "string"
      ),
      SubBrandId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmSubBrandId ?? this.props.screenInputs.prmsubbrandid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "SubBrand_New/SubBrand_NewComponent_619802_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.BrandSave = result?.data.brandSave;
    stateVars.RegionsSaveBySubBrandId = result?.data.regionsSaveBySubBrandId;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.SubBrand_NewComponent_619802_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SubBrand_NewComponent_619802_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SubBrand_NewComponent_419961_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SubBrand_NewComponent_366459_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "subbrand_new_366459_value", "value", "ParentBrand", "id", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "subbrand_new_366459_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ParentBrandID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "subbrand_new_366459_value", "value", "ParentBrand", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "SubBrand_New/SubBrand_NewComponent_366459_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RegionsByParentBrandId = result?.data.regionsByParentBrandId;

    formVars.subbrand_new_227663_options = stateVars.RegionsByParentBrandId;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.SubBrand_NewComponent_366459_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SubBrand_NewComponent_366459_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "subbrand_new_227663_value", "", null);

    stateVars.dataSource_227663 = this.state.RegionsByParentBrandId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
