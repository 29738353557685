import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ICampaign_List_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ICampaign_List_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  LanguageByLang: any[];
  CurrentCampaignSearch: any[];
  CurrentCampaignSearchCount: any[];
  PastCampaignSearch: any[];
  PastCampaignSearchCount: any[];
  CampaignImagesByCampagnId: any[];
}

export class Campaign_List_ScreenBase extends React.PureComponent<ICampaign_List_ScreenProps, any> {
  campaign_list_886984_value_kuikaTableRef: React.RefObject<any>;
  campaign_list_758021_value_kuikaPaginationRef: React.RefObject<any>;
  campaign_list_669724_value_kuikaTableRef: React.RefObject<any>;
  campaign_list_296847_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "937da27a-1820-c1c3-46e3-ec04ce38ecf6",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "b2795505-5e81-418b-94df-139de17eff2e",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 316688, PropertyName: "value", Value: "Campaign list" },
        { Id: 640217, PropertyName: "label", Value: "My profile" },
        { Id: 126271, PropertyName: "label", Value: "Logout" },
        { Id: 610406, PropertyName: "placeholder", Value: "Search..." },
        { Id: 625746, PropertyName: "label", Value: "NEW CAMPAIGN" },
        { Id: 688816, PropertyName: "tab", Value: "Current Campaigns" },
        { Id: 886984, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 326261, PropertyName: "title", Value: "Campaign name" },
        { Id: 184064, PropertyName: "value", Value: "[datafield:campaignname]" },
        { Id: 463931, PropertyName: "title", Value: "Region(s)" },
        { Id: 981286, PropertyName: "value", Value: "[datafield:regionsnames]" },
        { Id: 698337, PropertyName: "title", Value: "Start date" },
        { Id: 835747, PropertyName: "value", Value: "[datafield:startdate]" },
        { Id: 855514, PropertyName: "title", Value: "End date" },
        { Id: 855123, PropertyName: "value", Value: "[datafield:enddate]" },
        { Id: 269276, PropertyName: "title", Value: "Submit date" },
        { Id: 991406, PropertyName: "value", Value: "[datafield:submitdate]" },
        { Id: 827875, PropertyName: "title", Value: "#" },
        { Id: 178105, PropertyName: "value", Value: "Total :" },
        { Id: 871467, PropertyName: "tab", Value: "Past Campaigns" },
        { Id: 669724, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 738539, PropertyName: "title", Value: "Campaign name" },
        { Id: 100328, PropertyName: "value", Value: "[datafield:campaignname]" },
        { Id: 490555, PropertyName: "title", Value: "Region(s)" },
        { Id: 134041, PropertyName: "value", Value: "[datafield:regionsnames]" },
        { Id: 649991, PropertyName: "title", Value: "Start date" },
        { Id: 589024, PropertyName: "value", Value: "[datafield:startdate]" },
        { Id: 595348, PropertyName: "title", Value: "End date" },
        { Id: 437782, PropertyName: "value", Value: "[datafield:enddate]" },
        { Id: 950839, PropertyName: "title", Value: "Submit date" },
        { Id: 85487, PropertyName: "value", Value: "[datafield:submitdate]" },
        { Id: 599455, PropertyName: "title", Value: "#" },
        { Id: 798685, PropertyName: "value", Value: "Total :" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.campaign_list_886984_value_kuikaTableRef = React.createRef();
    this.campaign_list_758021_value_kuikaPaginationRef = React.createRef();
    this.campaign_list_669724_value_kuikaTableRef = React.createRef();
    this.campaign_list_296847_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      LanguageByLang: [],
      CurrentCampaignSearch: [],
      CurrentCampaignSearchCount: [],
      PastCampaignSearch: [],
      PastCampaignSearchCount: [],
      CampaignImagesByCampagnId: []
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("campaign_list", "campaign_list");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.Campaign_ListPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("campaign_list", "campaign_list");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("campaign_list", "campaign_list");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.Campaign_ListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  Campaign_ListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Lang_0: ReactSystemFunctions.convertToTypeByName("EN", "string"),
      isCurrent_1: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      SearchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "campaign_list_610406_value", "value", "", "", "")
        ),
        "string"
      ),
      isCurrent_2: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      SearchFor_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "campaign_list_610406_value", "value", "", "", "")
        ),
        "string"
      ),
      isCurrent_3: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      SearchFor_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "campaign_list_610406_value", "value", "", "", "")
        ),
        "string"
      ),
      isCurrent_4: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      SearchFor_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "campaign_list_610406_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "Campaign_List/Campaign_ListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.LanguageByLang = result?.data.languageByLang;
    stateVars.CurrentCampaignSearch = result?.data.currentCampaignSearch;

    stateVars.CurrentCampaignSearchCount = result?.data.currentCampaignSearchCount;
    formVars.campaign_list_789737_value = ReactSystemFunctions.toString(
      this,
      stateVars.CurrentCampaignSearchCount?.length > 0 ? stateVars.CurrentCampaignSearchCount[0]?.count : null
    );
    stateVars.PastCampaignSearch = result?.data.pastCampaignSearch;

    stateVars.PastCampaignSearchCount = result?.data.pastCampaignSearchCount;
    formVars.campaign_list_474202_value = ReactSystemFunctions.toString(
      this,
      stateVars.PastCampaignSearchCount?.length > 0 ? stateVars.PastCampaignSearchCount[0]?.count : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Campaign_ListPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Campaign_ListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_886984 = this.state.CurrentCampaignSearch;
    formVars.campaign_list_789737_value = ReactSystemFunctions.toString(
      this,
      this.state.CurrentCampaignSearchCount?.length > 0 ? this.state.CurrentCampaignSearchCount[0]?.count : null
    );

    formVars.campaign_list_758021_total = ReactSystemFunctions.value(
      this,
      this.state.CurrentCampaignSearchCount?.length > 0 ? this.state.CurrentCampaignSearchCount[0]?.count : null
    );

    stateVars.dataSource_669724 = this.state.PastCampaignSearch;
    formVars.campaign_list_474202_value = ReactSystemFunctions.toString(
      this,
      this.state.PastCampaignSearchCount?.length > 0 ? this.state.PastCampaignSearchCount[0]?.count : null
    );

    formVars.campaign_list_296847_total = ReactSystemFunctions.value(
      this,
      this.state.PastCampaignSearchCount?.length > 0 ? this.state.PastCampaignSearchCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  Campaign_ListComponent_276412_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Campaign_List",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "508020",
      null,
      "left",
      null,
      "320px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Campaign_ListComponent_610406_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      LangId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.LanguageByLang?.length > 0 ? this.state.LanguageByLang[0]?.id : null
        ),
        "Guid"
      ),
      isCurrent_0: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      SearchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "campaign_list_610406_value", "value", "", "", "")
        ),
        "string"
      ),
      LangId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.LanguageByLang?.length > 0 ? this.state.LanguageByLang[0]?.id : null
        ),
        "Guid"
      ),
      isCurrent_1: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      SearchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "campaign_list_610406_value", "value", "", "", "")
        ),
        "string"
      ),
      LangId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.LanguageByLang?.length > 0 ? this.state.LanguageByLang[0]?.id : null
        ),
        "Guid"
      ),
      isCurrent_2: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      SearchFor_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "campaign_list_610406_value", "value", "", "", "")
        ),
        "string"
      ),
      LangId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.LanguageByLang?.length > 0 ? this.state.LanguageByLang[0]?.id : null
        ),
        "Guid"
      ),
      isCurrent_3: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      SearchFor_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "campaign_list_610406_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Campaign_List/Campaign_ListComponent_610406_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CurrentCampaignSearch = result?.data.currentCampaignSearch;

    stateVars.CurrentCampaignSearchCount = result?.data.currentCampaignSearchCount;
    formVars.campaign_list_789737_value = ReactSystemFunctions.toString(
      this,
      stateVars.CurrentCampaignSearchCount?.length > 0 ? stateVars.CurrentCampaignSearchCount[0]?.count : null
    );
    stateVars.PastCampaignSearch = result?.data.pastCampaignSearch;

    stateVars.PastCampaignSearchCount = result?.data.pastCampaignSearchCount;
    formVars.campaign_list_474202_value = ReactSystemFunctions.toString(
      this,
      stateVars.PastCampaignSearchCount?.length > 0 ? stateVars.PastCampaignSearchCount[0]?.count : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Campaign_ListComponent_610406_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Campaign_ListComponent_610406_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_886984 = this.state.CurrentCampaignSearch;
    formVars.campaign_list_789737_value = ReactSystemFunctions.toString(
      this,
      this.state.CurrentCampaignSearchCount?.length > 0 ? this.state.CurrentCampaignSearchCount[0]?.count : null
    );

    formVars.campaign_list_758021_total = ReactSystemFunctions.value(
      this,
      this.state.CurrentCampaignSearchCount?.length > 0 ? this.state.CurrentCampaignSearchCount[0]?.count : null
    );

    stateVars.dataSource_669724 = this.state.PastCampaignSearch;
    formVars.campaign_list_474202_value = ReactSystemFunctions.toString(
      this,
      this.state.PastCampaignSearchCount?.length > 0 ? this.state.PastCampaignSearchCount[0]?.count : null
    );

    formVars.campaign_list_296847_total = ReactSystemFunctions.value(
      this,
      this.state.PastCampaignSearchCount?.length > 0 ? this.state.PastCampaignSearchCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Campaign_ListComponent_625746_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("Campaign_New", "prmCampaignId", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables("Campaign_New", "prmImageId", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables("Campaign_New", "prmPurpose", "ADD");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Campaign_List",
      "Campaign_New",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "559615",
      null,
      "right",
      null,
      "1200px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Campaign_ListComponent_78003_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CampaignId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "campaign_list_886984_value", "id"),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "Campaign_List/Campaign_ListComponent_78003_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CampaignImagesByCampagnId = result?.data.campaignImagesByCampagnId;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Campaign_ListComponent_78003_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Campaign_ListComponent_78003_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "Campaign_New",
      "prmCampaignId",
      ReactSystemFunctions.value(this, "campaign_list_886984_value", "id")
    );
    KuikaAppManager.addToPageInputVariables(
      "Campaign_New",
      "prmImageId",
      ReactSystemFunctions.value(
        this,
        this.state.CampaignImagesByCampagnId?.length > 0 ? this.state.CampaignImagesByCampagnId[0]?.id : null
      )
    );
    KuikaAppManager.addToPageInputVariables("Campaign_New", "prmPurpose", "EDIT");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Campaign_List",
      "Campaign_New",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "467223",
      null,
      "right",
      null,
      "1200px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Campaign_ListComponent_950975_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "Campaign_Details",
      "prmCampaignId",
      ReactSystemFunctions.value(this, "campaign_list_886984_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Campaign_List",
      "Campaign_Details",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "339341",
      null,
      "right",
      null,
      "600px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Campaign_ListComponent_718031_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "Campaign_Details",
      "prmCampaignId",
      ReactSystemFunctions.value(this, "campaign_list_669724_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Campaign_List",
      "Campaign_Details",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "465341",
      null,
      "right",
      null,
      "600px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [508020, 559615, 467223, 339341, 465341] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.Campaign_ListPageInit();
    }
  }
}
