import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IProduct_ListYedek_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IProduct_ListYedek_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  LanguageByLang: any[];
  ParentBrand: any[];
  ProductSearch: any[];
  ProductSearchCount: any[];
  ProductImagesByProductCategoryId: any[];
}

export class Product_ListYedek_ScreenBase extends React.PureComponent<IProduct_ListYedek_ScreenProps, any> {
  product_listyedek_692790_value_kuikaSelectBoxRef: React.RefObject<any>;
  product_listyedek_557544_value_kuikaGalleryViewRef: React.RefObject<any>;
  product_listyedek_761122_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "937da27a-1820-c1c3-46e3-ec04ce38ecf6",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "3e0c9e02-fc33-40d1-bbb3-7e4587ee7dc8",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 316688, PropertyName: "value", Value: "Product group list" },
        { Id: 640217, PropertyName: "label", Value: "My profile" },
        { Id: 126271, PropertyName: "label", Value: "Logout" },
        { Id: 147547, PropertyName: "placeholder", Value: "Search in products..." },
        { Id: 692790, PropertyName: "placeholder", Value: "All brand" },
        { Id: 660889, PropertyName: "value", Value: "Active" },
        { Id: 625746, PropertyName: "label", Value: "NEW PRODUCT GROUP" },
        { Id: 980738, PropertyName: "value", Value: "[datafield:parentname]" },
        { Id: 103929, PropertyName: "value", Value: "[datafield:brandname]" },
        { Id: 422529, PropertyName: "value", Value: "[datafield:definition]" },
        { Id: 263311, PropertyName: "value", Value: "Total :" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.product_listyedek_692790_value_kuikaSelectBoxRef = React.createRef();
    this.product_listyedek_557544_value_kuikaGalleryViewRef = React.createRef();
    this.product_listyedek_761122_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      LanguageByLang: [],
      ParentBrand: [],
      ProductSearch: [],
      ProductSearchCount: [],
      ProductImagesByProductCategoryId: []
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("product_listyedek", "product_listyedek");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.Product_ListYedekPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("product_listyedek", "product_listyedek");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("product_listyedek", "product_listyedek");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.Product_ListYedekPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      product_listyedek_691936_value: true
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  Product_ListYedekPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Lang_0: ReactSystemFunctions.convertToTypeByName("EN", "string"),
      BrandId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_listyedek_692790_value", "value", "ParentBrand", "id", "id")
        ),
        "Guid"
      ),
      SearchFor_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_listyedek_147547_value", "value", "", "", "")
        ),
        "string"
      ),
      isActive_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_listyedek_691936_value", "value", "", "", "")
        ),
        "boolean"
      ),
      currentpage_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_listyedek_761122_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_listyedek_761122_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      BrandId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_listyedek_692790_value", "value", "ParentBrand", "id", "id")
        ),
        "Guid"
      ),
      SearchFor_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_listyedek_147547_value", "value", "", "", "")
        ),
        "string"
      ),
      isActive_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_listyedek_691936_value", "value", "", "", "")
        ),
        "boolean"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "Product_ListYedek/Product_ListYedekPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.LanguageByLang = result?.data.languageByLang;
    stateVars.ParentBrand = result?.data.parentBrand;

    formVars.product_listyedek_692790_options = stateVars.ParentBrand;
    stateVars.ProductSearch = result?.data.productSearch;

    stateVars.ProductSearchCount = result?.data.productSearchCount;
    formVars.product_listyedek_611604_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProductSearchCount?.length > 0 ? stateVars.ProductSearchCount[0]?.count : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Product_ListYedekPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Product_ListYedekPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_692790 = this.state.ParentBrand;

    stateVars.dataSource_557544 = this.state.ProductSearch;
    formVars.product_listyedek_611604_value = ReactSystemFunctions.toString(
      this,
      this.state.ProductSearchCount?.length > 0 ? this.state.ProductSearchCount[0]?.count : null
    );

    formVars.product_listyedek_761122_total = ReactSystemFunctions.value(
      this,
      this.state.ProductSearchCount?.length > 0 ? this.state.ProductSearchCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  Product_ListYedekComponent_276412_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Product_ListYedek",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "855401",
      null,
      "left",
      null,
      "320px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Product_ListYedekComponent_147547_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      LangId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.LanguageByLang?.length > 0 ? this.state.LanguageByLang[0]?.id : null
        ),
        "Guid"
      ),
      BrandId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_listyedek_692790_value", "value", "ParentBrand", "id", "id")
        ),
        "Guid"
      ),
      SearchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_listyedek_147547_value", "value", "", "", "")
        ),
        "string"
      ),
      isActive_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_listyedek_691936_value", "value", "", "", "")
        ),
        "boolean"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_listyedek_761122_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_listyedek_761122_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      LangId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.LanguageByLang?.length > 0 ? this.state.LanguageByLang[0]?.id : null
        ),
        "Guid"
      ),
      BrandId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_listyedek_692790_value", "value", "ParentBrand", "id", "id")
        ),
        "Guid"
      ),
      SearchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_listyedek_147547_value", "value", "", "", "")
        ),
        "string"
      ),
      isActive_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_listyedek_691936_value", "value", "", "", "")
        ),
        "boolean"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Product_ListYedek/Product_ListYedekComponent_147547_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ProductSearch = result?.data.productSearch;

    stateVars.ProductSearchCount = result?.data.productSearchCount;
    formVars.product_listyedek_611604_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProductSearchCount?.length > 0 ? stateVars.ProductSearchCount[0]?.count : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Product_ListYedekComponent_147547_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Product_ListYedekComponent_147547_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_557544 = this.state.ProductSearch;
    formVars.product_listyedek_611604_value = ReactSystemFunctions.toString(
      this,
      this.state.ProductSearchCount?.length > 0 ? this.state.ProductSearchCount[0]?.count : null
    );

    formVars.product_listyedek_761122_total = ReactSystemFunctions.value(
      this,
      this.state.ProductSearchCount?.length > 0 ? this.state.ProductSearchCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Product_ListYedekComponent_692790_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      LangId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.LanguageByLang?.length > 0 ? this.state.LanguageByLang[0]?.id : null
        ),
        "Guid"
      ),
      BrandId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_listyedek_692790_value", "value", "ParentBrand", "id", "id")
        ),
        "Guid"
      ),
      SearchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_listyedek_147547_value", "value", "", "", "")
        ),
        "string"
      ),
      isActive_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_listyedek_691936_value", "value", "", "", "")
        ),
        "boolean"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_listyedek_761122_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_listyedek_761122_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      LangId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.LanguageByLang?.length > 0 ? this.state.LanguageByLang[0]?.id : null
        ),
        "Guid"
      ),
      BrandId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_listyedek_692790_value", "value", "ParentBrand", "id", "id")
        ),
        "Guid"
      ),
      SearchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_listyedek_147547_value", "value", "", "", "")
        ),
        "string"
      ),
      isActive_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_listyedek_691936_value", "value", "", "", "")
        ),
        "boolean"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Product_ListYedek/Product_ListYedekComponent_692790_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ProductSearch = result?.data.productSearch;

    stateVars.ProductSearchCount = result?.data.productSearchCount;
    formVars.product_listyedek_611604_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProductSearchCount?.length > 0 ? stateVars.ProductSearchCount[0]?.count : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Product_ListYedekComponent_692790_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Product_ListYedekComponent_692790_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_557544 = this.state.ProductSearch;
    formVars.product_listyedek_611604_value = ReactSystemFunctions.toString(
      this,
      this.state.ProductSearchCount?.length > 0 ? this.state.ProductSearchCount[0]?.count : null
    );

    formVars.product_listyedek_761122_total = ReactSystemFunctions.value(
      this,
      this.state.ProductSearchCount?.length > 0 ? this.state.ProductSearchCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Product_ListYedekComponent_691936_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      LangId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.LanguageByLang?.length > 0 ? this.state.LanguageByLang[0]?.id : null
        ),
        "Guid"
      ),
      BrandId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_listyedek_692790_value", "value", "ParentBrand", "id", "id")
        ),
        "Guid"
      ),
      SearchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_listyedek_147547_value", "value", "", "", "")
        ),
        "string"
      ),
      isActive_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_listyedek_691936_value", "value", "", "", "")
        ),
        "boolean"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_listyedek_761122_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_listyedek_761122_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      LangId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.LanguageByLang?.length > 0 ? this.state.LanguageByLang[0]?.id : null
        ),
        "Guid"
      ),
      BrandId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_listyedek_692790_value", "value", "ParentBrand", "id", "id")
        ),
        "Guid"
      ),
      SearchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_listyedek_147547_value", "value", "", "", "")
        ),
        "string"
      ),
      isActive_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_listyedek_691936_value", "value", "", "", "")
        ),
        "boolean"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Product_ListYedek/Product_ListYedekComponent_691936_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ProductSearch = result?.data.productSearch;

    stateVars.ProductSearchCount = result?.data.productSearchCount;
    formVars.product_listyedek_611604_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProductSearchCount?.length > 0 ? stateVars.ProductSearchCount[0]?.count : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Product_ListYedekComponent_691936_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Product_ListYedekComponent_691936_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_557544 = this.state.ProductSearch;
    formVars.product_listyedek_611604_value = ReactSystemFunctions.toString(
      this,
      this.state.ProductSearchCount?.length > 0 ? this.state.ProductSearchCount[0]?.count : null
    );

    formVars.product_listyedek_761122_total = ReactSystemFunctions.value(
      this,
      this.state.ProductSearchCount?.length > 0 ? this.state.ProductSearchCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Product_ListYedekComponent_625746_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("Product_NewCategory", "prmImageId", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables("Product_NewCategory", "prmProductTranslationId", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables("Product_NewCategory", "prmProductCategoryId", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables("Product_NewCategory", "prmProductId", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables("Product_NewCategory", "prmPurpose", "ADD");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Product_ListYedek",
      "Product_NewCategory",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "597502",
      null,
      "right",
      null,
      "1000px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Product_ListYedekComponent_815788_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ProductCategoryId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "product_listyedek_557544_value", "productCategoryId"),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Product_ListYedek/Product_ListYedekComponent_815788_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ProductImagesByProductCategoryId = result?.data.productImagesByProductCategoryId;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Product_ListYedekComponent_815788_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Product_ListYedekComponent_815788_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "Product_Details",
      "prmImageId",
      ReactSystemFunctions.value(
        this,
        this.state.ProductImagesByProductCategoryId?.length > 0
          ? this.state.ProductImagesByProductCategoryId[0]?.id
          : null
      )
    );
    KuikaAppManager.addToPageInputVariables(
      "Product_Details",
      "prmProductCategoryId",
      ReactSystemFunctions.value(this, "product_listyedek_557544_value", "productCategoryId")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Product_ListYedek",
      "Product_Details",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Product_ListYedekComponent_761122_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      LangId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.LanguageByLang?.length > 0 ? this.state.LanguageByLang[0]?.id : null
        ),
        "Guid"
      ),
      BrandId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_listyedek_692790_value", "value", "ParentBrand", "id", "id")
        ),
        "Guid"
      ),
      SearchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_listyedek_147547_value", "value", "", "", "")
        ),
        "string"
      ),
      isActive_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_listyedek_691936_value", "value", "", "", "")
        ),
        "boolean"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_listyedek_761122_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_listyedek_761122_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Product_ListYedek/Product_ListYedekComponent_761122_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ProductSearch = result?.data.productSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Product_ListYedekComponent_761122_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Product_ListYedekComponent_761122_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_557544 = this.state.ProductSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [855401, 597502] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.Product_ListYedekPageInit();
    }
  }
}
