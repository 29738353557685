import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ICustomer_List_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ICustomer_List_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  RegionActive: any[];
  CustomerSearch: any[];
  CustomerSearchCount: any[];
}

export class Customer_List_ScreenBase extends React.PureComponent<ICustomer_List_ScreenProps, any> {
  customer_list_369915_value_kuikaSelectBoxRef: React.RefObject<any>;
  customer_list_526539_value_kuikaTableRef: React.RefObject<any>;
  customer_list_494996_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "937da27a-1820-c1c3-46e3-ec04ce38ecf6",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "9490659f-0e28-44fd-9562-d2c336f8e5bf",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 316688, PropertyName: "value", Value: "Customer list" },
        { Id: 640217, PropertyName: "label", Value: "My profile" },
        { Id: 126271, PropertyName: "label", Value: "Logout" },
        { Id: 612880, PropertyName: "placeholder", Value: "Search..." },
        { Id: 369915, PropertyName: "placeholder", Value: "All regions" },
        { Id: 336111, PropertyName: "value", Value: "Active" },
        { Id: 807145, PropertyName: "label", Value: "NEW CUSTOMER" },
        { Id: 526539, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 867714, PropertyName: "title", Value: "Customer Title" },
        { Id: 772128, PropertyName: "value", Value: "[datafield:title]" },
        { Id: 641978, PropertyName: "title", Value: "Commertial Title" },
        { Id: 182786, PropertyName: "value", Value: "[datafield:commercialtitle]" },
        { Id: 537916, PropertyName: "title", Value: "Email" },
        { Id: 440990, PropertyName: "value", Value: "[datafield:email]" },
        { Id: 629859, PropertyName: "title", Value: "Region(s)" },
        { Id: 622906, PropertyName: "value", Value: "[datafield:regionsnames]" },
        { Id: 275790, PropertyName: "title", Value: "Is Special Product Show" },
        { Id: 440795, PropertyName: "title", Value: "#" },
        { Id: 202231, PropertyName: "value", Value: "Total Customer:" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.customer_list_369915_value_kuikaSelectBoxRef = React.createRef();
    this.customer_list_526539_value_kuikaTableRef = React.createRef();
    this.customer_list_494996_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      RegionActive: [],
      CustomerSearch: [],
      CustomerSearchCount: []
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("customer_list", "customer_list");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.Customer_ListPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("customer_list", "customer_list");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("customer_list", "customer_list");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.Customer_ListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      customer_list_302638_value: true
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  Customer_ListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      CustomerId_1: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      SearchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "customer_list_612880_value", "value", "", "", "")
        ),
        "string"
      ),
      Region_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "customer_list_369915_value", "value", "RegionActive", "id", "id")
        ),
        "string"
      ),
      isActive_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "customer_list_302638_value", "value", "", "", "")
        ),
        "boolean"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "customer_list_494996_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "customer_list_494996_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      CustomerId_2: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      SearchFor_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "customer_list_612880_value", "value", "", "", "")
        ),
        "string"
      ),
      isActive_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "customer_list_302638_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Region_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "customer_list_369915_value", "value", "RegionActive", "id", "id")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "Customer_List/Customer_ListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RegionActive = result?.data.regionActive;

    formVars.customer_list_369915_options = stateVars.RegionActive;
    stateVars.CustomerSearch = result?.data.customerSearch;

    stateVars.CustomerSearchCount = result?.data.customerSearchCount;
    formVars.customer_list_152735_value = ReactSystemFunctions.toString(
      this,
      stateVars.CustomerSearchCount?.length > 0 ? stateVars.CustomerSearchCount[0]?.count : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Customer_ListPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Customer_ListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_369915 = this.state.RegionActive;

    stateVars.dataSource_526539 = this.state.CustomerSearch;
    formVars.customer_list_152735_value = ReactSystemFunctions.toString(
      this,
      this.state.CustomerSearchCount?.length > 0 ? this.state.CustomerSearchCount[0]?.count : null
    );

    formVars.customer_list_494996_total = ReactSystemFunctions.value(
      this,
      this.state.CustomerSearchCount?.length > 0 ? this.state.CustomerSearchCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  Customer_ListComponent_276412_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Customer_List",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "386677",
      null,
      "left",
      null,
      "320px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Customer_ListComponent_612880_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CustomerId_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      SearchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "customer_list_612880_value", "value", "", "", "")
        ),
        "string"
      ),
      Region_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "customer_list_369915_value", "value", "RegionActive", "id", "id")
        ),
        "string"
      ),
      isActive_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "customer_list_302638_value", "value", "", "", "")
        ),
        "boolean"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "customer_list_494996_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "customer_list_494996_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      CustomerId_1: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      SearchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "customer_list_612880_value", "value", "", "", "")
        ),
        "string"
      ),
      isActive_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "customer_list_302638_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Region_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "customer_list_369915_value", "value", "RegionActive", "id", "id")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Customer_List/Customer_ListComponent_612880_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CustomerSearch = result?.data.customerSearch;

    stateVars.CustomerSearchCount = result?.data.customerSearchCount;
    formVars.customer_list_152735_value = ReactSystemFunctions.toString(
      this,
      stateVars.CustomerSearchCount?.length > 0 ? stateVars.CustomerSearchCount[0]?.count : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Customer_ListComponent_612880_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Customer_ListComponent_612880_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_526539 = this.state.CustomerSearch;
    formVars.customer_list_152735_value = ReactSystemFunctions.toString(
      this,
      this.state.CustomerSearchCount?.length > 0 ? this.state.CustomerSearchCount[0]?.count : null
    );

    formVars.customer_list_494996_total = ReactSystemFunctions.value(
      this,
      this.state.CustomerSearchCount?.length > 0 ? this.state.CustomerSearchCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Customer_ListComponent_369915_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CustomerId_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      SearchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "customer_list_612880_value", "value", "", "", "")
        ),
        "string"
      ),
      Region_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "customer_list_369915_value", "value", "RegionActive", "id", "id")
        ),
        "string"
      ),
      isActive_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "customer_list_302638_value", "value", "", "", "")
        ),
        "boolean"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "customer_list_494996_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "customer_list_494996_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      CustomerId_1: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      SearchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "customer_list_612880_value", "value", "", "", "")
        ),
        "string"
      ),
      isActive_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "customer_list_302638_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Region_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "customer_list_369915_value", "value", "RegionActive", "id", "id")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Customer_List/Customer_ListComponent_369915_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CustomerSearch = result?.data.customerSearch;

    stateVars.CustomerSearchCount = result?.data.customerSearchCount;
    formVars.customer_list_152735_value = ReactSystemFunctions.toString(
      this,
      stateVars.CustomerSearchCount?.length > 0 ? stateVars.CustomerSearchCount[0]?.count : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Customer_ListComponent_369915_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Customer_ListComponent_369915_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_526539 = this.state.CustomerSearch;
    formVars.customer_list_152735_value = ReactSystemFunctions.toString(
      this,
      this.state.CustomerSearchCount?.length > 0 ? this.state.CustomerSearchCount[0]?.count : null
    );

    formVars.customer_list_494996_total = ReactSystemFunctions.value(
      this,
      this.state.CustomerSearchCount?.length > 0 ? this.state.CustomerSearchCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Customer_ListComponent_302638_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CustomerId_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      SearchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "customer_list_612880_value", "value", "", "", "")
        ),
        "string"
      ),
      Region_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "customer_list_369915_value", "value", "RegionActive", "id", "id")
        ),
        "string"
      ),
      isActive_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "customer_list_302638_value", "value", "", "", "")
        ),
        "boolean"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "customer_list_494996_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "customer_list_494996_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      CustomerId_1: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      SearchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "customer_list_612880_value", "value", "", "", "")
        ),
        "string"
      ),
      isActive_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "customer_list_302638_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Region_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "customer_list_369915_value", "value", "RegionActive", "id", "id")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Customer_List/Customer_ListComponent_302638_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CustomerSearch = result?.data.customerSearch;

    stateVars.CustomerSearchCount = result?.data.customerSearchCount;
    formVars.customer_list_152735_value = ReactSystemFunctions.toString(
      this,
      stateVars.CustomerSearchCount?.length > 0 ? stateVars.CustomerSearchCount[0]?.count : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Customer_ListComponent_302638_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Customer_ListComponent_302638_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_526539 = this.state.CustomerSearch;
    formVars.customer_list_152735_value = ReactSystemFunctions.toString(
      this,
      this.state.CustomerSearchCount?.length > 0 ? this.state.CustomerSearchCount[0]?.count : null
    );

    formVars.customer_list_494996_total = ReactSystemFunctions.value(
      this,
      this.state.CustomerSearchCount?.length > 0 ? this.state.CustomerSearchCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Customer_ListComponent_807145_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("Customer_New", "prmCustomerId", Guid.create().toString());

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Customer_List",
      "Customer_New",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "26095",
      null,
      "right",
      null,
      "600px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Customer_ListComponent_841681_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "Customer_Details",
      "prmCustomerId",
      ReactSystemFunctions.value(this, "customer_list_526539_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Customer_List",
      "Customer_Details",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Customer_ListComponent_494996_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CustomerId_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      SearchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "customer_list_612880_value", "value", "", "", "")
        ),
        "string"
      ),
      Region_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "customer_list_369915_value", "value", "RegionActive", "id", "id")
        ),
        "string"
      ),
      isActive_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "customer_list_302638_value", "value", "", "", "")
        ),
        "boolean"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "customer_list_494996_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "customer_list_494996_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      CustomerId_1: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      SearchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "customer_list_612880_value", "value", "", "", "")
        ),
        "string"
      ),
      isActive_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "customer_list_302638_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Region_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "customer_list_369915_value", "value", "RegionActive", "id", "id")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Customer_List/Customer_ListComponent_494996_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CustomerSearch = result?.data.customerSearch;

    stateVars.CustomerSearchCount = result?.data.customerSearchCount;
    formVars.customer_list_152735_value = ReactSystemFunctions.toString(
      this,
      stateVars.CustomerSearchCount?.length > 0 ? stateVars.CustomerSearchCount[0]?.count : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Customer_ListComponent_494996_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Customer_ListComponent_494996_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_526539 = this.state.CustomerSearch;
    formVars.customer_list_152735_value = ReactSystemFunctions.toString(
      this,
      this.state.CustomerSearchCount?.length > 0 ? this.state.CustomerSearchCount[0]?.count : null
    );

    formVars.customer_list_494996_total = ReactSystemFunctions.value(
      this,
      this.state.CustomerSearchCount?.length > 0 ? this.state.CustomerSearchCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [386677, 26095] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.Customer_ListPageInit();
    }
  }
}
