import React from "react";
import { BrowserRouter, HashRouter, Switch } from "react-router-dom";
import { Redirect } from "../auth/redirect";
import { NoMatch404 } from "../components/common/no-match-404";
import { Dashboard, ForgotPassword, Login, ResetPassword } from "../components/default_screens/index";
import { PrivateRoute } from "./private-route";
import { Route } from "./route";
import { Contact_UnpairedList_Screen } from "../screens/contact_unpairedlist/contact_unpairedlist";
import { Customer_New_Screen } from "../screens/customer_new/customer_new";
import { TestScreen_Screen } from "../screens/testscreen/testscreen";
import { Signup_Screen } from "../screens/signup/signup";
import { Brand_New_Screen } from "../screens/brand_new/brand_new";
import { User_List_Screen } from "../screens/user_list/user_list";
import { Brand_List_Screen } from "../screens/brand_list/brand_list";
import { VerificationCode_Screen } from "../screens/verificationcode/verificationcode";
import { Region_New_Screen } from "../screens/region_new/region_new";
import { Product_Translation_Screen } from "../screens/product_translation/product_translation";
import { LeftMenu_Screen } from "../screens/leftmenu/leftmenu";
import { Translate_Screen } from "../screens/translate/translate";
import { Customer_List_Screen } from "../screens/customer_list/customer_list";
import { SubBrand_List_Screen } from "../screens/subbrand_list/subbrand_list";
import { Campaign_List_Screen } from "../screens/campaign_list/campaign_list";
import { SubBrand_New_Screen } from "../screens/subbrand_new/subbrand_new";
import { ForgotPassword_Screen } from "../screens/forgotpassword/forgotpassword";
import { Product_List_Screen } from "../screens/product_list/product_list";
import { Role_List_Screen } from "../screens/role_list/role_list";
import { Region_List_Screen } from "../screens/region_list/region_list";
import { Product_New_Screen } from "../screens/product_new/product_new";
import { Role_New_Screen } from "../screens/role_new/role_new";
import { Contact_New_Screen } from "../screens/contact_new/contact_new";
import { ResetPassword_Screen } from "../screens/resetpassword/resetpassword";
import { Customer_Details_Screen } from "../screens/customer_details/customer_details";
import { Campaign_Details_Screen } from "../screens/campaign_details/campaign_details";
import { Campaign_New_Screen } from "../screens/campaign_new/campaign_new";
import { User_Profile_Screen } from "../screens/user_profile/user_profile";
import { User_New_Screen } from "../screens/user_new/user_new";
import { Signin_Screen } from "../screens/signin/signin";
import { Product_ListYedek_Screen } from "../screens/product_listyedek/product_listyedek";
import { HomePage_Screen } from "../screens/homepage/homepage";
import { Product_NewCategory_Screen } from "../screens/product_newcategory/product_newcategory";
import { Product_Details_Screen } from "../screens/product_details/product_details";
import { Product_Listcopy_Screen } from "../screens/product_listcopy/product_listcopy";
import { Contact_UnpairedNew_Screen } from "../screens/contact_unpairednew/contact_unpairednew";



import { IAppMode, IAuthType } from "../bootstrapper";
import { KuikaInfo } from "../components/default_screens/kuika-info";
import { MfeLogin } from "../components/mfe/mfe-login";
import { MsalLogin } from "../components/msal/msal-login";
import { MsalRedirect } from "../components/msal/msal-redirect";
import { KMainFunctions } from "../shared/utilty/main-functions";

const confJson = require("../shared/json/conf.json");

interface IRouterState {}

export type IRouterType = "HashRouter" | "BrowserRouter";

interface IRouterProps {
  authType: IAuthType;
  routerType?: IRouterType;
  external_access_token?: string;
  external_refresh_token?: string;
  external_user?: string;
  appMode?: IAppMode;
}

declare let window: any;
export class Router extends React.Component<IRouterProps, IRouterState> {
  timerId: any = null;

  componentDidMount = () => {
    window.onfocus = function () {
      if (window.kuika === undefined) {
        window.kuika = {};
      }
      window.kuika.isTabActive = true;
    };

    window.onblur = function () {
      if (window.kuika === undefined) {
        window.kuika = {};
      }
      window.kuika.isTabActive = false;
    };

    if (!window.styler_speed) {
      window.styler_speed = 20;
    } else if (window.styler_speed < 500) {
      window.styler_speed += 100;
    }

    if (window?.kuika?.activeKuikaComponent !== undefined) {
      return;
    }
    const runTimeout = () => {
      this.timerId = setTimeout(() => {
        const allFormItems: NodeListOf<HTMLElement> = document.querySelectorAll(".kFormItem");
        if (allFormItems) {
          allFormItems.forEach((formItem) => {
            if (formItem.getAttribute("data-display-affected") === "true") {
              return;
            }
            const antFormItemControlInputContent: HTMLElement = formItem.querySelector(
              ".ant-form-item-control .ant-form-item-control-input .ant-form-item-control-input-content"
            );
            const antFormItemControlInput: HTMLElement = formItem.querySelector(
              ".ant-form-item-control .ant-form-item-control-input"
            );
            const antFormItemControl: HTMLElement = formItem.querySelector(".ant-form-item-control");

            if (
              antFormItemControlInputContent &&
              antFormItemControlInputContent.children &&
              antFormItemControlInputContent.children.length > 0
            ) {
              const formChild: HTMLElement = antFormItemControlInputContent.children[0] as HTMLElement;
              let calculatedDisplay: string = formChild.style.display;
              if (
                calculatedDisplay &&
                antFormItemControl &&
                antFormItemControlInput &&
                antFormItemControlInputContent
              ) {
                if (calculatedDisplay === "inline-flex" || calculatedDisplay === "inline-block") {
                  calculatedDisplay = "";
                  formItem.style.width = formChild.style.width ?? "auto";
                  formItem.style.display = "inline-block";
                  formItem.setAttribute("data-display-affected", "true");
                }
              }
            }
          });
        }
        this.componentDidMount();
      }, window.styler_speed);
    };

    runTimeout();

    setTimeout(() => {
      clearTimeout(this.timerId);
    }, 10000);
  };

  render() {
    const KuikaRouter = this.KuikaRouter();
    return (
      <>
        <KuikaRouter>
          <Switch key="Switch">
            <PrivateRoute exact path="/" component={HomePage_Screen} />
                        <PrivateRoute exact path="/contact_unpairedlist" component={Contact_UnpairedList_Screen} />
            <PrivateRoute exact path="/customer_new" component={Customer_New_Screen} />
            <PrivateRoute exact path="/testscreen" component={TestScreen_Screen} />
            <Route exact path="/signup" component={Signup_Screen} />
            <Route exact path="/brand_new" component={Brand_New_Screen} />
            <PrivateRoute exact path="/user_list" component={User_List_Screen} />
            <Route exact path="/brand_list" component={Brand_List_Screen} />
            <Route exact path="/verificationcode" component={VerificationCode_Screen} />
            <PrivateRoute exact path="/region_new" component={Region_New_Screen} />
            <PrivateRoute exact path="/product_translation" component={Product_Translation_Screen} />
            <PrivateRoute exact path="/leftmenu" component={LeftMenu_Screen} />
            <PrivateRoute exact path="/translate" component={Translate_Screen} />
            <PrivateRoute exact path="/customer_list" component={Customer_List_Screen} />
            <PrivateRoute exact path="/subbrand_list" component={SubBrand_List_Screen} />
            <PrivateRoute exact path="/campaign_list" component={Campaign_List_Screen} />
            <PrivateRoute exact path="/subbrand_new" component={SubBrand_New_Screen} />
            <Route exact path="/forgotpassword" component={ForgotPassword_Screen} />
            <PrivateRoute exact path="/product_list" component={Product_List_Screen} />
            <PrivateRoute exact path="/role_list" component={Role_List_Screen} />
            <PrivateRoute exact path="/region_list" component={Region_List_Screen} />
            <PrivateRoute exact path="/product_new" component={Product_New_Screen} />
            <PrivateRoute exact path="/role_new" component={Role_New_Screen} />
            <PrivateRoute exact path="/contact_new" component={Contact_New_Screen} />
            <Route exact path="/resetpassword" component={ResetPassword_Screen} />
            <PrivateRoute exact path="/customer_details" component={Customer_Details_Screen} />
            <PrivateRoute exact path="/campaign_details" component={Campaign_Details_Screen} />
            <PrivateRoute exact path="/campaign_new" component={Campaign_New_Screen} />
            <PrivateRoute exact path="/user_profile" component={User_Profile_Screen} />
            <PrivateRoute exact path="/user_new" component={User_New_Screen} />
            <Route exact path="/signin" component={this.LoginComponent()} />
            <PrivateRoute exact path="/product_listyedek" component={Product_ListYedek_Screen} />
            <PrivateRoute exact path="/homepage" component={HomePage_Screen} />
            <PrivateRoute exact path="/product_newcategory" component={Product_NewCategory_Screen} />
            <PrivateRoute exact path="/product_details" component={Product_Details_Screen} />
            <PrivateRoute exact path="/product_listcopy" component={Product_Listcopy_Screen} />
            <PrivateRoute exact path="/contact_unpairednew" component={Contact_UnpairedNew_Screen} />

            <Route path="/redirect" component={Redirect} />
            <Route path={"/login"} component={this.LoginComponent()} />
            
            
            <Route exact path="/forgotpassword" component={ForgotPassword} />
            <Route exact path="/resetpassword" component={ResetPassword} />
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <Route exact path="/kuikainfo" component={KuikaInfo} />
            <Route exact path="/msalredirect" component={MsalRedirect} />
            <Route path="*" component={NoMatch404} />
          </Switch>
        </KuikaRouter>
      </>
    );
  }

  KuikaRouter = () => {
    // Web component case
    let isWebComponentApp: any;
    isWebComponentApp = this.props.appMode === "WebComponent";
    if (
      confJson?.domainName !== undefined &&
      confJson?.applicationName !== undefined &&
      confJson?.applicationName !== "" &&
      window.kuika !== undefined &&
      window.kuika?.apps !== undefined &&
      window.kuika?.apps.length !== undefined &&
      window.kuika?.apps.length > 0
    ) {
      window.kuika?.apps.forEach((app: any) => {
        if (app.appName === confJson.applicationName && app.workspace === confJson.domainName) {
          isWebComponentApp = true;
        }
      });
    }
    if (window.kuika === undefined) {
      window.kuika = {};
    }
    window.kuika.isWebComponentApp = isWebComponentApp;

    if (isWebComponentApp === true || this.props.routerType === "HashRouter") {
      return (props: any) => {
        return <HashRouter key="HashRouter">{props.children}</HashRouter>;
      };
    }
    return (props: any) => {
      if (KMainFunctions.virtualDirectoryURL === "auto" || window?.location?.protocol === "file:") {
        return <BrowserRouter key="BrowserRouter">{props.children}</BrowserRouter>;
      }
      return (
        <BrowserRouter basename={KMainFunctions.virtualDirectoryURL} key="BrowserRouter">
          {props.children}
        </BrowserRouter>
      );
    };
  };

  LoginComponent = () => {
    if (this.props.authType === "MSAL") {
      return MsalLogin;
    }

    const { external_access_token } = this.props;
    const { external_refresh_token } = this.props;
    const { external_user } = this.props;
    if (
      external_access_token !== null &&
      external_access_token !== undefined &&
      external_access_token !== "" &&
      external_refresh_token !== null &&
      external_refresh_token !== undefined &&
      external_refresh_token !== ""
    ) {
      return (props: any) => {
        return (
          <MfeLogin
            external_user={external_user}
            external_access_token={external_access_token}
            external_refresh_token={external_refresh_token}
          ></MfeLogin>
        );
      };
    }

    if (this.props.authType === "Kuika") {
      return Signin_Screen;
    }
    return <></>;
  };
}
