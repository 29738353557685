import Axios, { AxiosResponse } from "axios";
import { IApplicationInfo } from "../models/dto/application.dto";
import { EApplicationType, ISaveApplicationRequestDTO } from "../models/dto/save-application-request.dto";
import { KuikaAppManager } from "../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../shared/utilty/main-functions";

const backendUrl = KuikaAppManager.GetBackendUrl();
const projectId = "c1ba8d3e-b607-4fbc-effd-08db6bec53c8";
const projectName = "CrmWeb";

export class ApplicationService {
  public static async saveApplication() {
    const virtualDirectoryURL: string = "auto"; // Use by code generation
    return Axios.post<ISaveApplicationRequestDTO, AxiosResponse<any>>(`${backendUrl}/application`, {
      projectId,
      applicationType: EApplicationType.Frontend,
      applicationName: projectName,
      baseUrl: KMainFunctions.windowLocationOrigin,
      description: `${projectName} application frontend`
    });
  }

  public static async getAllApplications(): Promise<AxiosResponse<IApplicationInfo[]>> {
    return Axios.get<IApplicationInfo[]>(`${backendUrl}/application/all`);
  }

  public static saveAllApplicationsToSessionStorage = async () => {
    if (sessionStorage.getItem("all-applications") && sessionStorage.getItem("front-end-applications")) {
      return;
    }

    const response = await ApplicationService.getAllApplications();

    if (response.status !== 200) return;

    sessionStorage.setItem("all-applications", JSON.stringify(response.data));
    sessionStorage.setItem(
      "front-end-applications",
      JSON.stringify(response.data.filter((app) => app.applicationType === EApplicationType.Frontend))
    );
  };

  public static getAllApplicationsFromSessionStorageRaw = (): {
    allApplications: string;
    frontEndApplications: string;
  } | null => {
    const allApplications = sessionStorage.getItem("all-applications");
    const frontEndApplications = sessionStorage.getItem("front-end-applications");
    if (!allApplications || !frontEndApplications) {
      return null;
    }
    return {
      allApplications,
      frontEndApplications
    };
  };

  public static getAllApplicationsFromSessionStorage = (): {
    allApplications: IApplicationInfo[];
    frontEndApplications: IApplicationInfo[];
  } | null => {
    const applications = ApplicationService.getAllApplicationsFromSessionStorageRaw();

    if (!applications) return null;

    return {
      allApplications: JSON.parse(applications.allApplications),
      frontEndApplications: JSON.parse(applications.frontEndApplications)
    };
  };
}
