import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IBrand_New_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IBrand_New_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  ParentBrandSave: number;
  RegionsSaveByParentBrandId: any[];
  BrandImagesSave: number;
  BrandImgLinkSave: number;
  GenerateNewID: any[];
  IfThenElse: boolean;
  GoBack: any;
  ParentBrandByBrandId: any[];
  RegionActive: any[];
  PhotoFromGallery: string;
  SetValueOf: any;
}

export class Brand_New_ScreenBase extends React.PureComponent<IBrand_New_ScreenProps, any> {
  ml = [
    {
      Id: "937da27a-1820-c1c3-46e3-ec04ce38ecf6",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "13470581-31ba-4e37-8271-82f82eae5215",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 619802, PropertyName: "label", Value: "SAVE" },
        { Id: 607465, PropertyName: "value", Value: "Parent brand form" },
        { Id: 38280, PropertyName: "value", Value: "Upload" },
        { Id: 946257, PropertyName: "value", Value: "Name" },
        { Id: 424158, PropertyName: "placeholder", Value: "Brand name..." },
        { Id: 494667, PropertyName: "value", Value: "Description" },
        { Id: 412849, PropertyName: "placeholder", Value: "Brand name..." },
        { Id: 828667, PropertyName: "value", Value: "Regions" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      ParentBrandSave: 0,
      RegionsSaveByParentBrandId: [],
      IfThenElse: false,
      GoBack: "",
      ParentBrandByBrandId: [],
      RegionActive: [],
      PhotoFromGallery: "",
      SetValueOf: ""
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("brand_new", "brand_new");
      return;
    }

    if (true) {
      await this.Brand_NewPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("brand_new", "brand_new");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("brand_new", "brand_new");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.Brand_NewPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      brand_new_699864_value: this.state.ParentBrandByBrandId?.at?.(0)?.imageLink ?? undefined,
      brand_new_424158_value: this.state.ParentBrandByBrandId?.at?.(0)?.name ?? undefined,
      brand_new_412849_value: this.state.ParentBrandByBrandId?.at?.(0)?.description ?? undefined,
      brand_new_165274_value: this.state.ParentBrandByBrandId?.at?.(0)?.regionIds ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  Brand_NewPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      BrandId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmBrandId ?? this.props.screenInputs.prmbrandid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "Brand_New/Brand_NewPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ParentBrandByBrandId = result?.data.parentBrandByBrandId;
    formVars.brand_new_699864_value = ReactSystemFunctions.toString(
      this,
      stateVars.ParentBrandByBrandId?.length > 0 ? stateVars.ParentBrandByBrandId[0]?.imageLink : null
    );
    formVars.brand_new_424158_value = ReactSystemFunctions.toString(
      this,
      stateVars.ParentBrandByBrandId?.length > 0 ? stateVars.ParentBrandByBrandId[0]?.name : null
    );
    formVars.brand_new_412849_value = ReactSystemFunctions.toString(
      this,
      stateVars.ParentBrandByBrandId?.length > 0 ? stateVars.ParentBrandByBrandId[0]?.description : null
    );
    formVars.brand_new_165274_options = stateVars.RegionActive;
    formVars.brand_new_165274_value =
      stateVars.ParentBrandByBrandId?.length > 0 ? stateVars.ParentBrandByBrandId[0]?.regionIds : null;
    stateVars.RegionActive = result?.data.regionActive;

    formVars.brand_new_165274_options = stateVars.RegionActive;
    formVars.brand_new_165274_value =
      stateVars.ParentBrandByBrandId?.length > 0 ? stateVars.ParentBrandByBrandId[0]?.regionIds : null;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Brand_NewPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Brand_NewPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.brand_new_699864_value = ReactSystemFunctions.toString(
      this,
      this.state.ParentBrandByBrandId?.length > 0 ? this.state.ParentBrandByBrandId[0]?.imageLink : null
    );

    formVars.brand_new_424158_value = ReactSystemFunctions.toString(
      this,
      this.state.ParentBrandByBrandId?.length > 0 ? this.state.ParentBrandByBrandId[0]?.name : null
    );

    formVars.brand_new_412849_value = ReactSystemFunctions.toString(
      this,
      this.state.ParentBrandByBrandId?.length > 0 ? this.state.ParentBrandByBrandId[0]?.description : null
    );

    formVars.brand_new_165274_value = ReactSystemFunctions.toString(
      this,
      this.state.ParentBrandByBrandId?.length > 0 ? this.state.ParentBrandByBrandId[0]?.regionIds : null
    );

    stateVars.dataSource_165274 = this.state.RegionActive;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  Brand_NewComponent_619802_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Name_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "brand_new_424158_value", "value", "", "", "")
        ),
        "string"
      ),
      Description_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "brand_new_412849_value", "value", "", "", "")
        ),
        "string"
      ),
      RegionIds_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "brand_new_165274_value", "value", "RegionActive", "id", "id")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmBrandId ?? this.props.screenInputs.prmbrandid,
        "Guid"
      ),
      RegionIds_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "brand_new_165274_value", "value", "RegionActive", "id", "id")
        ),
        "string"
      ),
      ParentBrandId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmBrandId ?? this.props.screenInputs.prmbrandid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "Brand_New/Brand_NewComponent_619802_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ParentBrandSave = result?.data.parentBrandSave;
    stateVars.RegionsSaveByParentBrandId = result?.data.regionsSaveByParentBrandId;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Brand_NewComponent_619802_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Brand_NewComponent_619802_onClick_if1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BrandImage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "brand_new_699864_value", "value", "", "", "")
        ),
        "string"
      ),
      ParentBrandId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmBrandId ?? this.props.screenInputs.prmbrandid,
        "Guid"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmImageId ?? this.props.screenInputs.prmimageid,
        "Guid"
      ),
      BrandImgID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmImageId ?? this.props.screenInputs.prmimageid,
        "string"
      ),
      BrandId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmBrandId ?? this.props.screenInputs.prmbrandid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "Brand_New/Brand_NewComponent_619802_onClick_if1", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.BrandImagesSave = result?.data.brandImagesSave;
    stateVars.BrandImgLinkSave = result?.data.brandImgLinkSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  Brand_NewComponent_619802_onClick_else1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Brand_NewComponent_619802_onClick_else11_();
    });

    return isErrorOccurred;
  };
  Brand_NewComponent_619802_onClick_else1f1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BrandImage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "brand_new_699864_value", "value", "", "", "")
        ),
        "string"
      ),
      ParentBrandId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmBrandId ?? this.props.screenInputs.prmbrandid,
        "Guid"
      ),
      BrandId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmBrandId ?? this.props.screenInputs.prmbrandid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Brand_New/Brand_NewComponent_619802_onClick_else1f1",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GenerateNewID = result?.data.generateNewID;
    stateVars.BrandImagesSave = result?.data.brandImagesSave;
    stateVars.BrandImgLinkSave = result?.data.brandImgLinkSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  Brand_NewComponent_619802_onClick_else11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "brand_new_699864_value", "value", "", "", "")
        ),
        null
      )
    ) {
      isErrorOccurred = await this.Brand_NewComponent_619802_onClick_else1f1();
      if (isErrorOccurred) return true;
    } else {
    }

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  Brand_NewComponent_619802_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEmpty(this.props.screenInputs.prmImageId ?? this.props.screenInputs.prmimageid, null)
    ) {
      isErrorOccurred = await this.Brand_NewComponent_619802_onClick_if1();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.Brand_NewComponent_619802_onClick_else1();
      if (isErrorOccurred) return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Brand_NewComponent_419961_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Brand_NewComponent_38280_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.PhotoFromGallery = await ReactSystemFunctions.getPhotoFromGallery(false, "");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Brand_NewComponent_38280_onClick1_();
    });

    return isErrorOccurred;
  };
  Brand_NewComponent_38280_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.PhotoFromGallery), null)) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        "brand_new_699864_value",
        ReactSystemFunctions.value(this, this.state.PhotoFromGallery),
        null
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
