import { FormInstance } from "antd/lib/form";
import React from "react";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IUser_Profile_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IUser_Profile_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
}

export class User_Profile_ScreenBase extends React.PureComponent<IUser_Profile_ScreenProps, any> {
  ml = [
    {
      Id: "937da27a-1820-c1c3-46e3-ec04ce38ecf6",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "6448766a-1301-4be1-ace4-d78992d73c4a",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 972287, PropertyName: "value", Value: "EDIT" },
        { Id: 607465, PropertyName: "value", Value: "My profile" },
        { Id: 239080, PropertyName: "value", Value: "Simay Bornova" },
        { Id: 960126, PropertyName: "value", Value: "Designer" },
        { Id: 530804, PropertyName: "value", Value: "+905413132897" },
        { Id: 626647, PropertyName: "value", Value: "simay.bornova@kuika.com" },
        { Id: 650782, PropertyName: "value", Value: "Admin" },
        { Id: 455339, PropertyName: "value", Value: "CHANGE PASSWORD" },
        { Id: 120670, PropertyName: "value", Value: "Change password" },
        { Id: 446669, PropertyName: "value", Value: "Current password" },
        { Id: 513962, PropertyName: "placeholder", Value: "***********" },
        { Id: 322666, PropertyName: "value", Value: "New password" },
        { Id: 827906, PropertyName: "placeholder", Value: "***********" },
        { Id: 584184, PropertyName: "value", Value: "New password (confirmation)" },
        { Id: 834161, PropertyName: "placeholder", Value: "***********" },
        { Id: 929356, PropertyName: "label", Value: "Save new password" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("user_profile", "user_profile");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
    }

    KuikaAppManager.calculateAndSetBodyHeight("user_profile", "user_profile");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("user_profile", "user_profile");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
