import { FormInstance } from "antd/lib/form";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IProduct_NewCategory_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IProduct_NewCategory_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  ProductCategoryDelete: any[];
  NAVIGATE: any;
  ProductCodeControl: number;
  IfThenElse: boolean;
  ProductCategorySave: number;
  ProductTranslationSave: number;
  ProductImagesSave: number;
  GeneratedProductImgLinkSave: number;
  GenerateNewID: any[];
  ProductSave: number;
  RegionIdsBySubBrandId: any[];
  RegionsSaveBySubBrandId: any[];
  GoBack: any;
  LanguageByLang: any[];
  ProductCategoryById: any[];
  ProductById: any[];
  CategoryType: any[];
  ParentBrand: any[];
  BrandByParentBrandId: any[];
  PhotoFromGallery: string;
  SetValueOf: any;
  isComp20332Visible: "visible" | "hidden";
  isComp113084Visible: "visible" | "hidden";
}

export class Product_NewCategory_ScreenBase extends React.PureComponent<IProduct_NewCategory_ScreenProps, any> {
  product_newcategory_839889_value_kuikaSelectBoxRef: React.RefObject<any>;
  product_newcategory_638517_value_kuikaSelectBoxRef: React.RefObject<any>;
  product_newcategory_729758_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "937da27a-1820-c1c3-46e3-ec04ce38ecf6",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "5dae7596-836c-474c-bf31-0638144c5195",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 20332, PropertyName: "label", Value: "DELETE" },
        { Id: 619802, PropertyName: "label", Value: "SAVE" },
        { Id: 607465, PropertyName: "value", Value: "Product group form" },
        { Id: 960354, PropertyName: "value", Value: "UPLOAD" },
        { Id: 720621, PropertyName: "value", Value: "Name" },
        { Id: 392772, PropertyName: "value", Value: "*" },
        { Id: 799425, PropertyName: "placeholder", Value: "Product name..." },
        { Id: 511383, PropertyName: "value", Value: "Description" },
        { Id: 432321, PropertyName: "value", Value: "*" },
        { Id: 725745, PropertyName: "placeholder", Value: "Product descriptions..." },
        { Id: 316819, PropertyName: "value", Value: "Category Type" },
        { Id: 859201, PropertyName: "value", Value: "*" },
        { Id: 839889, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 765167, PropertyName: "value", Value: "Product Code" },
        { Id: 431828, PropertyName: "value", Value: "*" },
        { Id: 677483, PropertyName: "placeholder", Value: "Product Code..." },
        { Id: 970882, PropertyName: "value", Value: "Net Weight(gr)" },
        { Id: 198328, PropertyName: "value", Value: "*" },
        { Id: 692755, PropertyName: "placeholder", Value: "15" },
        { Id: 211697, PropertyName: "value", Value: "Pieces In Box" },
        { Id: 136786, PropertyName: "placeholder", Value: "15" },
        { Id: 298322, PropertyName: "value", Value: "Volume" },
        { Id: 521684, PropertyName: "value", Value: "*" },
        { Id: 323676, PropertyName: "placeholder", Value: "15" },
        { Id: 510239, PropertyName: "value", Value: "In Parcel" },
        { Id: 550266, PropertyName: "value", Value: "*" },
        { Id: 479072, PropertyName: "placeholder", Value: "15" },
        { Id: 530804, PropertyName: "value", Value: "Brand" },
        { Id: 343362, PropertyName: "value", Value: "*" },
        { Id: 638517, PropertyName: "placeholder", Value: "Choose brand..." },
        { Id: 843013, PropertyName: "value", Value: "Sub Brand" },
        { Id: 190552, PropertyName: "value", Value: "*" },
        { Id: 729758, PropertyName: "placeholder", Value: "Choose sub brand..." },
        { Id: 793949, PropertyName: "value", Value: "SEO - Title Tag" },
        { Id: 252348, PropertyName: "value", Value: "*" },
        { Id: 552680, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 472388, PropertyName: "value", Value: "SEO - Meta Description" },
        { Id: 813450, PropertyName: "value", Value: "*" },
        { Id: 644881, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 544731, PropertyName: "value", Value: "Public" },
        { Id: 62846, PropertyName: "value", Value: "Active" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.product_newcategory_839889_value_kuikaSelectBoxRef = React.createRef();
    this.product_newcategory_638517_value_kuikaSelectBoxRef = React.createRef();
    this.product_newcategory_729758_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      ProductCategoryDelete: [],
      NAVIGATE: "",
      IfThenElse: false,
      ProductCategorySave: 0,
      ProductTranslationSave: 0,
      RegionIdsBySubBrandId: [],
      RegionsSaveBySubBrandId: [],
      GoBack: "",
      LanguageByLang: [],
      ProductCategoryById: [],
      ProductById: [],
      CategoryType: [],
      ParentBrand: [],
      BrandByParentBrandId: [],
      PhotoFromGallery: "",
      SetValueOf: "",
      isComp20332Visible: "hidden",
      isComp113084Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("product_newcategory", "product_newcategory");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.Product_NewCategoryPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("product_newcategory", "product_newcategory");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("product_newcategory", "product_newcategory");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.Product_NewCategoryPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      product_newcategory_946233_value: this.state.ProductCategoryById?.at?.(0)?.imageLink ?? undefined,
      product_newcategory_799425_value: this.state.ProductCategoryById?.at?.(0)?.definition ?? undefined,
      product_newcategory_725745_value: this.state.ProductCategoryById?.at?.(0)?.description ?? undefined,
      product_newcategory_839889_value: this.state.ProductCategoryById?.at?.(0)?.categoryType ?? undefined,
      product_newcategory_677483_value: this.state.ProductById?.at?.(0)?.productCode ?? undefined,
      product_newcategory_692755_value: this.state.ProductById?.at?.(0)?.netGramaj ?? undefined,
      product_newcategory_136786_value: this.state.ProductById?.at?.(0)?.piecesInBox ?? undefined,
      product_newcategory_323676_value: this.state.ProductById?.at?.(0)?.volume ?? undefined,
      product_newcategory_479072_value: this.state.ProductById?.at?.(0)?.parcelnBox ?? undefined,
      product_newcategory_638517_value: this.state.ProductCategoryById?.at?.(0)?.parentBrandId ?? undefined,
      product_newcategory_729758_value: this.state.ProductCategoryById?.at?.(0)?.subBrandId ?? undefined,
      product_newcategory_552680_value: this.state.ProductCategoryById?.at?.(0)?.titleTag ?? undefined,
      product_newcategory_644881_value: this.state.ProductCategoryById?.at?.(0)?.metaDescription ?? undefined,
      product_newcategory_172884_value: this.state.ProductCategoryById?.at?.(0)?.isPublic ?? undefined,
      product_newcategory_574544_value: this.state.ProductCategoryById?.at?.(0)?.isActive ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  Product_NewCategoryPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Lang_0: ReactSystemFunctions.convertToTypeByName("EN", "string"),
      ProductCategoryId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProductCategoryId ?? this.props.screenInputs.prmproductcategoryid,
        "Guid"
      ),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProductId ?? this.props.screenInputs.prmproductid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "Product_NewCategory/Product_NewCategoryPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.LanguageByLang = result?.data.languageByLang;
    stateVars.ProductCategoryById = result?.data.productCategoryById;
    formVars.product_newcategory_946233_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProductCategoryById?.length > 0 ? stateVars.ProductCategoryById[0]?.imageLink : null
    );
    formVars.product_newcategory_799425_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProductCategoryById?.length > 0 ? stateVars.ProductCategoryById[0]?.definition : null
    );
    formVars.product_newcategory_725745_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProductCategoryById?.length > 0 ? stateVars.ProductCategoryById[0]?.description : null
    );
    formVars.product_newcategory_839889_value =
      stateVars.ProductCategoryById?.length > 0 ? stateVars.ProductCategoryById[0]?.categoryType : null;
    formVars.product_newcategory_839889_options = stateVars.CategoryType;
    formVars.product_newcategory_638517_value =
      stateVars.ProductCategoryById?.length > 0 ? stateVars.ProductCategoryById[0]?.parentBrandId : null;
    formVars.product_newcategory_638517_options = stateVars.ParentBrand;
    formVars.product_newcategory_729758_value =
      stateVars.ProductCategoryById?.length > 0 ? stateVars.ProductCategoryById[0]?.subBrandId : null;
    formVars.product_newcategory_729758_options = stateVars.BrandByParentBrandId;
    formVars.product_newcategory_552680_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProductCategoryById?.length > 0 ? stateVars.ProductCategoryById[0]?.titleTag : null
    );
    formVars.product_newcategory_644881_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProductCategoryById?.length > 0 ? stateVars.ProductCategoryById[0]?.metaDescription : null
    );
    formVars.product_newcategory_172884_value = ReactSystemFunctions.value(
      this,
      stateVars.ProductCategoryById?.length > 0 ? stateVars.ProductCategoryById[0]?.isPublic : null
    );
    formVars.product_newcategory_574544_value = ReactSystemFunctions.value(
      this,
      stateVars.ProductCategoryById?.length > 0 ? stateVars.ProductCategoryById[0]?.isActive : null
    );
    stateVars.ProductById = result?.data.productById;
    formVars.product_newcategory_677483_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProductById?.length > 0 ? stateVars.ProductById[0]?.productCode : null
    );
    formVars.product_newcategory_692755_value = ReactSystemFunctions.value(
      this,
      stateVars.ProductById?.length > 0 ? stateVars.ProductById[0]?.netGramaj : null
    );
    formVars.product_newcategory_136786_value = ReactSystemFunctions.value(
      this,
      stateVars.ProductById?.length > 0 ? stateVars.ProductById[0]?.piecesInBox : null
    );
    formVars.product_newcategory_323676_value = ReactSystemFunctions.value(
      this,
      stateVars.ProductById?.length > 0 ? stateVars.ProductById[0]?.volume : null
    );
    formVars.product_newcategory_479072_value = ReactSystemFunctions.value(
      this,
      stateVars.ProductById?.length > 0 ? stateVars.ProductById[0]?.parcelnBox : null
    );
    stateVars.CategoryType = result?.data.categoryType;

    formVars.product_newcategory_839889_value =
      stateVars.ProductCategoryById?.length > 0 ? stateVars.ProductCategoryById[0]?.categoryType : null;
    formVars.product_newcategory_839889_options = stateVars.CategoryType;
    stateVars.ParentBrand = result?.data.parentBrand;

    formVars.product_newcategory_638517_value =
      stateVars.ProductCategoryById?.length > 0 ? stateVars.ProductCategoryById[0]?.parentBrandId : null;
    formVars.product_newcategory_638517_options = stateVars.ParentBrand;
    stateVars.BrandByParentBrandId = result?.data.brandByParentBrandId;

    formVars.product_newcategory_729758_value =
      stateVars.ProductCategoryById?.length > 0 ? stateVars.ProductCategoryById[0]?.subBrandId : null;
    formVars.product_newcategory_729758_options = stateVars.BrandByParentBrandId;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Product_NewCategoryPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Product_NewCategoryPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.isComp20332Visible =
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmPurpose ?? this.props.screenInputs.prmpurpose,
        "EDIT"
      ) === true
        ? "visible"
        : "hidden";
    formVars.product_newcategory_946233_value = ReactSystemFunctions.toString(
      this,
      this.state.ProductCategoryById?.length > 0 ? this.state.ProductCategoryById[0]?.imageLink : null
    );

    formVars.product_newcategory_799425_value = ReactSystemFunctions.toString(
      this,
      this.state.ProductCategoryById?.length > 0 ? this.state.ProductCategoryById[0]?.definition : null
    );

    formVars.product_newcategory_725745_value = ReactSystemFunctions.toString(
      this,
      this.state.ProductCategoryById?.length > 0 ? this.state.ProductCategoryById[0]?.description : null
    );

    formVars.product_newcategory_839889_value = ReactSystemFunctions.toString(
      this,
      this.state.ProductCategoryById?.length > 0 ? this.state.ProductCategoryById[0]?.categoryType : null
    );

    stateVars.dataSource_839889 = this.state.CategoryType;
    stateVars.isComp113084Visible =
      ReactSystemFunctions.isNotEmpty(
        this.props.screenInputs.prmProductId ?? this.props.screenInputs.prmproductid,
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.product_newcategory_677483_value = ReactSystemFunctions.toString(
      this,
      this.state.ProductById?.length > 0 ? this.state.ProductById[0]?.productCode : null
    );

    formVars.product_newcategory_692755_value = ReactSystemFunctions.value(
      this,
      this.state.ProductById?.length > 0 ? this.state.ProductById[0]?.netGramaj : null
    );

    formVars.product_newcategory_136786_value = ReactSystemFunctions.value(
      this,
      this.state.ProductById?.length > 0 ? this.state.ProductById[0]?.piecesInBox : null
    );

    formVars.product_newcategory_323676_value = ReactSystemFunctions.value(
      this,
      this.state.ProductById?.length > 0 ? this.state.ProductById[0]?.volume : null
    );

    formVars.product_newcategory_479072_value = ReactSystemFunctions.value(
      this,
      this.state.ProductById?.length > 0 ? this.state.ProductById[0]?.parcelnBox : null
    );

    formVars.product_newcategory_638517_value = ReactSystemFunctions.toString(
      this,
      this.state.ProductCategoryById?.length > 0 ? this.state.ProductCategoryById[0]?.parentBrandId : null
    );

    stateVars.dataSource_638517 = this.state.ParentBrand;
    formVars.product_newcategory_729758_value = ReactSystemFunctions.toString(
      this,
      this.state.ProductCategoryById?.length > 0 ? this.state.ProductCategoryById[0]?.subBrandId : null
    );

    stateVars.dataSource_729758 = this.state.BrandByParentBrandId;
    formVars.product_newcategory_552680_value = ReactSystemFunctions.toString(
      this,
      this.state.ProductCategoryById?.length > 0 ? this.state.ProductCategoryById[0]?.titleTag : null
    );

    formVars.product_newcategory_644881_value = ReactSystemFunctions.toString(
      this,
      this.state.ProductCategoryById?.length > 0 ? this.state.ProductCategoryById[0]?.metaDescription : null
    );

    formVars.product_newcategory_172884_value = ReactSystemFunctions.value(
      this,
      this.state.ProductCategoryById?.length > 0 ? this.state.ProductCategoryById[0]?.isPublic : null
    );

    formVars.product_newcategory_574544_value = ReactSystemFunctions.value(
      this,
      this.state.ProductCategoryById?.length > 0 ? this.state.ProductCategoryById[0]?.isActive : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  Product_NewCategoryComponent_20332_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ProductCategoryId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProductCategoryId ?? this.props.screenInputs.prmproductcategoryid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "31bd9dcb_1288_6026_ebca_dbcb3a990686_confirmation",
        this.defaultML,
        "Are you sure that the carts, products, images and translations belonging to this product category will be deleted?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    }).then(async (result) => {
      if (result.isConfirmed) {
        KuikaAppManager.showSpinner(this);
        let result = await KuikaAppManager.ApiRequest(
          this,
          "Product_NewCategory/Product_NewCategoryComponent_20332_onClick",
          localVar
        );
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

        stateVars.ProductCategoryDelete = result?.data.productCategoryDelete;
        this.props.form.setFieldsValue(formVars);
        this.setState(stateVars, () => {
          this.Product_NewCategoryComponent_20332_onClick1_();
        });
      }
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Product_NewCategoryComponent_20332_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Product_NewCategory",
      "Product_List",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Product_NewCategoryComponent_619802_onClick_if0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "product_newcategory_677483_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "product_newcategory_677483_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ProductCode_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "product_newcategory_677483_value", "value", "", "", "")
        ),
        "string"
      ),
      Purpose_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmPurpose ?? this.props.screenInputs.prmpurpose,
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Product_NewCategory/Product_NewCategoryComponent_619802_onClick_if0",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ProductCodeControl = result?.data.productCodeControl;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  Product_NewCategoryComponent_619802_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(this.props.screenInputs.prmPurpose ?? this.props.screenInputs.prmpurpose, "ADD")
    ) {
      isErrorOccurred = await this.Product_NewCategoryComponent_619802_onClick_if0();
      if (isErrorOccurred) return true;
    } else {
    }
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Product_NewCategoryComponent_619802_onClick1_();
    });

    return isErrorOccurred;
  };
  Product_NewCategoryComponent_619802_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "product_newcategory_729758_value",
            "value",
            "BrandByParentBrandId",
            "id",
            ""
          )
        ),
        null
      ),
      message: "This field is required",
      formName: "product_newcategory_729758_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "product_newcategory_799425_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "product_newcategory_799425_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "product_newcategory_725745_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "product_newcategory_725745_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BrandId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "product_newcategory_729758_value",
            "value",
            "BrandByParentBrandId",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      isActive_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_newcategory_574544_value", "value", "", "", "")
        ),
        "boolean"
      ),
      isPublic_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_newcategory_172884_value", "value", "", "", "")
        ),
        "boolean"
      ),
      CategoryType_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "product_newcategory_839889_value", "value", "CategoryType", "name", "name")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProductCategoryId ?? this.props.screenInputs.prmproductcategoryid,
        "Guid"
      ),
      isWebAr_0: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      isWebEn_0: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      isWebFr_0: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      isWebTr_0: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      CreatedDate_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      Definition_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "product_newcategory_799425_value", "value", "", "", "")
        ),
        "string"
      ),
      Description_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_newcategory_725745_value", "value", "", "", "")
        ),
        "string"
      ),
      TitleTag_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "product_newcategory_552680_value", "value", "", "", "")
        ),
        "string"
      ),
      MetaDescription_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "product_newcategory_644881_value", "value", "", "", "")
        ),
        "string"
      ),
      LanguageId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.LanguageByLang?.length > 0 ? this.state.LanguageByLang[0]?.id : null
        ),
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProductTranslationId ?? this.props.screenInputs.prmproducttranslationid,
        "Guid"
      ),
      ProductCategoryID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProductCategoryId ?? this.props.screenInputs.prmproductcategoryid,
        "Guid"
      ),
      WebInfo_1: ReactSystemFunctions.convertToTypeByName("EN", "string"),
      CurrentWebInfo_1: ReactSystemFunctions.convertToTypeByName("EN", "string")
    };

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Product_NewCategory/Product_NewCategoryComponent_619802_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ProductCategorySave = result?.data.productCategorySave;
    stateVars.ProductTranslationSave = result?.data.productTranslationSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Product_NewCategoryComponent_619802_onClick2_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Product_NewCategoryComponent_619802_onClick_if2 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ProductCategoryId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProductCategoryId ?? this.props.screenInputs.prmproductcategoryid,
        "Guid"
      ),
      ProductImage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_newcategory_946233_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmImageId ?? this.props.screenInputs.prmimageid,
        "Guid"
      ),
      ProductImgID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmImageId ?? this.props.screenInputs.prmimageid,
        "Guid"
      ),
      ProductCategoryId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProductCategoryId ?? this.props.screenInputs.prmproductcategoryid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Product_NewCategory/Product_NewCategoryComponent_619802_onClick_if2",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ProductImagesSave = result?.data.productImagesSave;
    stateVars.GeneratedProductImgLinkSave = result?.data.generatedProductImgLinkSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  Product_NewCategoryComponent_619802_onClick_else2 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Product_NewCategoryComponent_619802_onClick_else21_();
    });

    return isErrorOccurred;
  };
  Product_NewCategoryComponent_619802_onClick_else2f1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ProductCategoryId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProductCategoryId ?? this.props.screenInputs.prmproductcategoryid,
        "Guid"
      ),
      ProductImage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_newcategory_946233_value", "value", "", "", "")
        ),
        "string"
      ),
      ProductCategoryId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProductCategoryId ?? this.props.screenInputs.prmproductcategoryid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Product_NewCategory/Product_NewCategoryComponent_619802_onClick_else2f1",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GenerateNewID = result?.data.generateNewID;
    stateVars.ProductImagesSave = result?.data.productImagesSave;
    stateVars.GeneratedProductImgLinkSave = result?.data.generatedProductImgLinkSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  Product_NewCategoryComponent_619802_onClick_else21_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "product_newcategory_946233_value", "value", "", "", "")
        ),
        null
      )
    ) {
      isErrorOccurred = await this.Product_NewCategoryComponent_619802_onClick_else2f1();
      if (isErrorOccurred) return true;
    } else {
    }

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  Product_NewCategoryComponent_619802_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEmpty(this.props.screenInputs.prmImageId ?? this.props.screenInputs.prmimageid, null)
    ) {
      isErrorOccurred = await this.Product_NewCategoryComponent_619802_onClick_if2();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.Product_NewCategoryComponent_619802_onClick_else2();
      if (isErrorOccurred) return true;
    }

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Product_NewCategoryComponent_619802_onClick3_();
    });

    return isErrorOccurred;
  };
  Product_NewCategoryComponent_619802_onClick_if3 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "product_newcategory_677483_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "product_newcategory_677483_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_newcategory_692755_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "product_newcategory_692755_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_newcategory_479072_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "product_newcategory_479072_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_newcategory_323676_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "product_newcategory_323676_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "product_newcategory_729758_value",
            "value",
            "BrandByParentBrandId",
            "id",
            ""
          )
        ),
        null
      ),
      message: "This field is required",
      formName: "product_newcategory_729758_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      NewProductCode_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "product_newcategory_677483_value", "value", "", "", "")
        ),
        "string"
      ),
      Purpose_0: ReactSystemFunctions.convertToTypeByName("ADD", "string"),
      OldProductCode_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.ProductById?.length > 0 ? this.state.ProductById[0]?.productCode : null
        ),
        "string"
      ),
      isActive_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_newcategory_574544_value", "value", "", "", "")
        ),
        "boolean"
      ),
      isPublic_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_newcategory_172884_value", "value", "", "", "")
        ),
        "boolean"
      ),
      isWebAr_0: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      isWebEn_0: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      isWebFr_0: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      isWebTr_0: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      NetGramaj_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_newcategory_692755_value", "value", "", "", "")
        ),
        "string"
      ),
      ParcelnBox_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_newcategory_479072_value", "value", "", "", "")
        ),
        "string"
      ),
      PiecesInBox_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_newcategory_136786_value", "value", "", "", "")
        ),
        "string"
      ),
      ProductCategoryId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProductCategoryId ?? this.props.screenInputs.prmproductcategoryid,
        "Guid"
      ),
      ProductCode_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_newcategory_677483_value", "value", "", "", "")
        ),
        "string"
      ),
      RegionIds_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      UploadedDate_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      Volume_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_newcategory_323676_value", "value", "", "", "")
        ),
        "number"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProductId ?? this.props.screenInputs.prmproductid,
        "Guid"
      ),
      SubBrandID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "product_newcategory_729758_value",
            "value",
            "BrandByParentBrandId",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      SubBrandId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "product_newcategory_729758_value",
            "value",
            "BrandByParentBrandId",
            "id",
            "id"
          )
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Product_NewCategory/Product_NewCategoryComponent_619802_onClick_if3",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ProductSave = result?.data.productSave;
    stateVars.RegionIdsBySubBrandId = result?.data.regionIdsBySubBrandId;
    stateVars.RegionsSaveBySubBrandId = result?.data.regionsSaveBySubBrandId;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  Product_NewCategoryComponent_619802_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEmpty(
        this.props.screenInputs.prmProductId ?? this.props.screenInputs.prmproductid,
        null
      )
    ) {
      isErrorOccurred = await this.Product_NewCategoryComponent_619802_onClick_if3();
      if (isErrorOccurred) return true;
    } else {
    }

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Product_NewCategoryComponent_619802_onClick4_();
    });

    return isErrorOccurred;
  };
  Product_NewCategoryComponent_619802_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "product_newcategory_729758_value",
            "value",
            "BrandByParentBrandId",
            "id",
            ""
          )
        ),
        null
      ),
      message: "This field is required",
      formName: "product_newcategory_729758_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      SubBrandID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "product_newcategory_729758_value",
            "value",
            "BrandByParentBrandId",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      SubBrandId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "product_newcategory_729758_value",
            "value",
            "BrandByParentBrandId",
            "id",
            "id"
          )
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Product_NewCategory/Product_NewCategoryComponent_619802_onClick4_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RegionIdsBySubBrandId = result?.data.regionIdsBySubBrandId;
    stateVars.RegionsSaveBySubBrandId = result?.data.regionsSaveBySubBrandId;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Product_NewCategoryComponent_619802_onClick5_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Product_NewCategoryComponent_619802_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Product_NewCategoryComponent_419961_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Product_NewCategoryComponent_960354_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.PhotoFromGallery = await ReactSystemFunctions.getPhotoFromGallery(false, "");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Product_NewCategoryComponent_960354_onClick1_();
    });

    return isErrorOccurred;
  };
  Product_NewCategoryComponent_960354_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.PhotoFromGallery), null)) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        "product_newcategory_946233_value",
        ReactSystemFunctions.value(this, this.state.PhotoFromGallery),
        null
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  Product_NewCategoryComponent_638517_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ParentBrandId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "product_newcategory_638517_value", "value", "ParentBrand", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Product_NewCategory/Product_NewCategoryComponent_638517_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.BrandByParentBrandId = result?.data.brandByParentBrandId;

    formVars.product_newcategory_729758_options = stateVars.BrandByParentBrandId;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Product_NewCategoryComponent_638517_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Product_NewCategoryComponent_638517_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_729758 = this.state.BrandByParentBrandId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.Product_NewCategoryPageInit();
    }
  }
}
