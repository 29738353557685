import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ICustomer_New_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ICustomer_New_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  CustomerSave: number;
  GoBack: any;
  CustomerById: any[];
  Region: any[];
  isComp189224Visible: "visible" | "hidden";
}

export class Customer_New_ScreenBase extends React.PureComponent<ICustomer_New_ScreenProps, any> {
  customer_new_298277_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "937da27a-1820-c1c3-46e3-ec04ce38ecf6",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "f7d3d0ae-b129-4ad1-85fc-d2a8cda0c42e",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 619802, PropertyName: "label", Value: "SAVE" },
        { Id: 607465, PropertyName: "value", Value: "Customer form" },
        { Id: 960354, PropertyName: "value", Value: "Title" },
        { Id: 12345, PropertyName: "placeholder", Value: "Apple Inc." },
        { Id: 163645, PropertyName: "value", Value: "Commertial Title" },
        { Id: 626187, PropertyName: "placeholder", Value: "Apple Inc." },
        { Id: 530804, PropertyName: "value", Value: "Phone1" },
        { Id: 93333, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 612053, PropertyName: "value", Value: "Phone2" },
        { Id: 463063, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 843013, PropertyName: "value", Value: "Email" },
        { Id: 492418, PropertyName: "placeholder", Value: "name@domainname.com" },
        { Id: 644352, PropertyName: "value", Value: "Address" },
        { Id: 596738, PropertyName: "placeholder", Value: "Write company address..." },
        { Id: 124227, PropertyName: "value", Value: "Country" },
        { Id: 298277, PropertyName: "placeholder", Value: "Choose local country" },
        { Id: 37562, PropertyName: "value", Value: "Region(s)" },
        { Id: 468693, PropertyName: "value", Value: "Show Special Products" },
        { Id: 642550, PropertyName: "value", Value: "Active" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.customer_new_298277_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      CustomerSave: 0,
      GoBack: "",
      CustomerById: [],
      Region: [],
      isComp189224Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("customer_new", "customer_new");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.Customer_NewPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("customer_new", "customer_new");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("customer_new", "customer_new");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.Customer_NewPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      customer_new_12345_value: this.state.CustomerById?.at?.(0)?.title ?? undefined,
      customer_new_626187_value: this.state.CustomerById?.at?.(0)?.commercialTitle ?? undefined,
      customer_new_93333_value: this.state.CustomerById?.at?.(0)?.phoneNumber1 ?? undefined,
      customer_new_463063_value: this.state.CustomerById?.at?.(0)?.phoneNumber2 ?? undefined,
      customer_new_492418_value: this.state.CustomerById?.at?.(0)?.email ?? undefined,
      customer_new_596738_value: this.state.CustomerById?.at?.(0)?.address ?? undefined,
      customer_new_485319_value: this.state.CustomerById?.at?.(0)?.regionIds ?? undefined,
      customer_new_167225_value: this.state.CustomerById?.at?.(0)?.isShowSpecialProducts ?? undefined,
      customer_new_86439_value: this.state.CustomerById?.at?.(0)?.isActive ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  Customer_NewPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      CustomerId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmCustomerId ?? this.props.screenInputs.prmcustomerid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "Customer_New/Customer_NewPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CustomerById = result?.data.customerById;
    formVars.customer_new_12345_value = ReactSystemFunctions.toString(
      this,
      stateVars.CustomerById?.length > 0 ? stateVars.CustomerById[0]?.title : null
    );
    formVars.customer_new_626187_value = ReactSystemFunctions.toString(
      this,
      stateVars.CustomerById?.length > 0 ? stateVars.CustomerById[0]?.commercialTitle : null
    );
    formVars.customer_new_93333_value = ReactSystemFunctions.toString(
      this,
      stateVars.CustomerById?.length > 0 ? stateVars.CustomerById[0]?.phoneNumber1 : null
    );
    formVars.customer_new_463063_value = ReactSystemFunctions.toString(
      this,
      stateVars.CustomerById?.length > 0 ? stateVars.CustomerById[0]?.phoneNumber2 : null
    );
    formVars.customer_new_492418_value = ReactSystemFunctions.toString(
      this,
      stateVars.CustomerById?.length > 0 ? stateVars.CustomerById[0]?.email : null
    );
    formVars.customer_new_596738_value = ReactSystemFunctions.toString(
      this,
      stateVars.CustomerById?.length > 0 ? stateVars.CustomerById[0]?.address : null
    );
    formVars.customer_new_485319_options = stateVars.Region;
    formVars.customer_new_485319_value =
      stateVars.CustomerById?.length > 0 ? stateVars.CustomerById[0]?.regionIds : null;
    formVars.customer_new_167225_value = ReactSystemFunctions.value(
      this,
      stateVars.CustomerById?.length > 0 ? stateVars.CustomerById[0]?.isShowSpecialProducts : null
    );
    formVars.customer_new_86439_value = ReactSystemFunctions.value(
      this,
      stateVars.CustomerById?.length > 0 ? stateVars.CustomerById[0]?.isActive : null
    );
    stateVars.Region = result?.data.region;

    formVars.customer_new_485319_options = stateVars.Region;
    formVars.customer_new_485319_value =
      stateVars.CustomerById?.length > 0 ? stateVars.CustomerById[0]?.regionIds : null;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Customer_NewPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Customer_NewPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.customer_new_12345_value = ReactSystemFunctions.toString(
      this,
      this.state.CustomerById?.length > 0 ? this.state.CustomerById[0]?.title : null
    );

    formVars.customer_new_626187_value = ReactSystemFunctions.toString(
      this,
      this.state.CustomerById?.length > 0 ? this.state.CustomerById[0]?.commercialTitle : null
    );

    formVars.customer_new_93333_value = ReactSystemFunctions.toString(
      this,
      this.state.CustomerById?.length > 0 ? this.state.CustomerById[0]?.phoneNumber1 : null
    );

    formVars.customer_new_463063_value = ReactSystemFunctions.toString(
      this,
      this.state.CustomerById?.length > 0 ? this.state.CustomerById[0]?.phoneNumber2 : null
    );

    formVars.customer_new_492418_value = ReactSystemFunctions.toString(
      this,
      this.state.CustomerById?.length > 0 ? this.state.CustomerById[0]?.email : null
    );

    formVars.customer_new_596738_value = ReactSystemFunctions.toString(
      this,
      this.state.CustomerById?.length > 0 ? this.state.CustomerById[0]?.address : null
    );

    formVars.customer_new_485319_value = ReactSystemFunctions.toString(
      this,
      this.state.CustomerById?.length > 0 ? this.state.CustomerById[0]?.regionIds : null
    );

    stateVars.dataSource_485319 = this.state.Region;
    formVars.customer_new_167225_value = ReactSystemFunctions.value(
      this,
      this.state.CustomerById?.length > 0 ? this.state.CustomerById[0]?.isShowSpecialProducts : null
    );

    formVars.customer_new_86439_value = ReactSystemFunctions.value(
      this,
      this.state.CustomerById?.length > 0 ? this.state.CustomerById[0]?.isActive : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  Customer_NewComponent_619802_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "customer_new_492418_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "customer_new_492418_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Address_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "customer_new_596738_value", "value", "", "", "")
        ),
        "string"
      ),
      CommercialTitle_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "customer_new_626187_value", "value", "", "", "")
        ),
        "string"
      ),
      Email_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "customer_new_492418_value", "value", "", "", "")
        ),
        "string"
      ),
      isActive_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "customer_new_86439_value", "value", "", "", "")
        ),
        "boolean"
      ),
      isShowSpecialProducts_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "customer_new_167225_value", "value", "", "", "")
        ),
        "boolean"
      ),
      PhoneNumber1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "customer_new_93333_value", "value", "", "", "")
        ),
        "string"
      ),
      PhoneNumber2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "customer_new_463063_value", "value", "", "", "")
        ),
        "string"
      ),
      RegionIds_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "customer_new_485319_value", "value", "Region", "id", "id")
        ),
        "string"
      ),
      Title_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "customer_new_12345_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmCustomerId ?? this.props.screenInputs.prmcustomerid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "Customer_New/Customer_NewComponent_619802_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CustomerSave = result?.data.customerSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Customer_NewComponent_619802_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Customer_NewComponent_619802_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Customer_NewComponent_419961_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
