import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ICampaign_Details_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ICampaign_Details_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  Campaign: any[];
}

export class Campaign_Details_ScreenBase extends React.PureComponent<ICampaign_Details_ScreenProps, any> {
  ml = [
    {
      Id: "937da27a-1820-c1c3-46e3-ec04ce38ecf6",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "eff7dda5-259f-4a73-9b69-df51db92421e",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 72474, PropertyName: "value", Value: "BACK" },
        { Id: 607465, PropertyName: "value", Value: "Campaign details" },
        { Id: 843013, PropertyName: "value", Value: "Region(s)" },
        { Id: 644352, PropertyName: "value", Value: "Duration" },
        { Id: 124227, PropertyName: "value", Value: "Submit date" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      Campaign: []
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("campaign_details", "campaign_details");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.Campaign_DetailsPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("campaign_details", "campaign_details");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("campaign_details", "campaign_details");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.Campaign_DetailsPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      campaign_details_417638_value: this.state.Campaign?.at?.(0)?.imageLink ?? undefined,
      campaign_details_321531_value: this.state.Campaign?.at?.(-1)?.campaignContent ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  Campaign_DetailsPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      CampaignId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmCampaignId ?? this.props.screenInputs.prmcampaignid,
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "Campaign_Details/Campaign_DetailsPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Campaign = result?.data.campaign;
    formVars.campaign_details_967350_value = ReactSystemFunctions.toString(
      this,
      stateVars.Campaign?.length > 0 ? stateVars.Campaign[0]?.campaignName : null
    );
    formVars.campaign_details_417638_value = ReactSystemFunctions.toString(
      this,
      stateVars.Campaign?.length > 0 ? stateVars.Campaign[0]?.imageLink : null
    );
    formVars.campaign_details_321531_value = ReactSystemFunctions.toString(
      this,
      stateVars.Campaign?.length > 0 ? stateVars.Campaign[stateVars.Campaign.length - 1].campaignContent : null
    );
    formVars.campaign_details_285969_value = ReactSystemFunctions.toString(
      this,
      stateVars.Campaign?.length > 0 ? stateVars.Campaign[0]?.regionsNames : null
    );
    formVars.campaign_details_350635_value = ReactSystemFunctions.toString(
      this,
      stateVars.Campaign?.length > 0 ? stateVars.Campaign[0]?.startDate : null
    );
    formVars.campaign_details_644851_value = ReactSystemFunctions.toString(
      this,
      stateVars.Campaign?.length > 0 ? stateVars.Campaign[0]?.endDate : null
    );
    formVars.campaign_details_710682_value = ReactSystemFunctions.toString(
      this,
      stateVars.Campaign?.length > 0 ? stateVars.Campaign[0]?.submitDate : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Campaign_DetailsPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Campaign_DetailsPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.campaign_details_967350_value = ReactSystemFunctions.toString(
      this,
      this.state.Campaign?.length > 0 ? this.state.Campaign[0]?.campaignName : null
    );

    formVars.campaign_details_417638_value = ReactSystemFunctions.toString(
      this,
      this.state.Campaign?.length > 0 ? this.state.Campaign[0]?.imageLink : null
    );

    formVars.campaign_details_321531_value = ReactSystemFunctions.toString(
      this,
      this.state.Campaign?.length > 0 ? this.state.Campaign[this.state.Campaign.length - 1].campaignContent : null
    );

    formVars.campaign_details_285969_value = ReactSystemFunctions.toString(
      this,
      this.state.Campaign?.length > 0 ? this.state.Campaign[0]?.regionsNames : null
    );

    formVars.campaign_details_350635_value = ReactSystemFunctions.toString(
      this,
      this.state.Campaign?.length > 0 ? this.state.Campaign[0]?.startDate : null
    );

    formVars.campaign_details_644851_value = ReactSystemFunctions.toString(
      this,
      this.state.Campaign?.length > 0 ? this.state.Campaign[0]?.endDate : null
    );

    formVars.campaign_details_710682_value = ReactSystemFunctions.toString(
      this,
      this.state.Campaign?.length > 0 ? this.state.Campaign[0]?.submitDate : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  Campaign_DetailsComponent_72474_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Campaign_DetailsComponent_419961_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
