import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ISubBrand_List_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ISubBrand_List_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  LanguageByLang: any[];
  ParentBrand: any[];
  BrandListBySearch: any[];
  BrandSearchCount: any[];
  SubBrandDelete: number;
}

export class SubBrand_List_ScreenBase extends React.PureComponent<ISubBrand_List_ScreenProps, any> {
  subbrand_list_519748_value_kuikaSelectBoxRef: React.RefObject<any>;
  subbrand_list_526539_value_kuikaTableRef: React.RefObject<any>;
  subbrand_list_5935_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "937da27a-1820-c1c3-46e3-ec04ce38ecf6",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "474e684e-b0f4-4ea2-be9f-4bf65391a7af",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 316688, PropertyName: "value", Value: "Sub brand list" },
        { Id: 640217, PropertyName: "label", Value: "My profile" },
        { Id: 126271, PropertyName: "label", Value: "Logout" },
        { Id: 189598, PropertyName: "placeholder", Value: "Search..." },
        { Id: 519748, PropertyName: "placeholder", Value: "All brand" },
        { Id: 625746, PropertyName: "label", Value: "NEW BRAND" },
        { Id: 402705, PropertyName: "label", Value: "NEW SUB BRAND" },
        { Id: 526539, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 73852, PropertyName: "title", Value: "Parent Brand name" },
        { Id: 691910, PropertyName: "value", Value: "[datafield:parentbrandname]" },
        { Id: 867714, PropertyName: "title", Value: "Sub brand name" },
        { Id: 772128, PropertyName: "value", Value: "[datafield:subbrandname]" },
        { Id: 422689, PropertyName: "title", Value: "Region(s)" },
        { Id: 220499, PropertyName: "value", Value: "[datafield:regionsnames]" },
        { Id: 658201, PropertyName: "title", Value: "Edit" },
        { Id: 264914, PropertyName: "title", Value: "Delete" },
        { Id: 345641, PropertyName: "value", Value: "Total Product:" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.subbrand_list_519748_value_kuikaSelectBoxRef = React.createRef();
    this.subbrand_list_526539_value_kuikaTableRef = React.createRef();
    this.subbrand_list_5935_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      LanguageByLang: [],
      ParentBrand: [],
      BrandListBySearch: [],
      BrandSearchCount: [],
      SubBrandDelete: 0
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("subbrand_list", "subbrand_list");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.SubBrand_ListPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("subbrand_list", "subbrand_list");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("subbrand_list", "subbrand_list");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.SubBrand_ListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  SubBrand_ListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Lang_0: ReactSystemFunctions.convertToTypeByName("EN", "string"),
      BrandId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "subbrand_list_519748_value", "value", "ParentBrand", "id", "brandId")
        ),
        "Guid"
      ),
      SearchFor_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "subbrand_list_189598_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "subbrand_list_5935_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "subbrand_list_5935_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      BrandId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "subbrand_list_519748_value", "value", "ParentBrand", "id", "id")
        ),
        "Guid"
      ),
      SearchFor_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "subbrand_list_189598_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "SubBrand_List/SubBrand_ListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.LanguageByLang = result?.data.languageByLang;
    stateVars.ParentBrand = result?.data.parentBrand;

    formVars.subbrand_list_519748_options = stateVars.ParentBrand;
    stateVars.BrandListBySearch = result?.data.brandListBySearch;

    stateVars.BrandSearchCount = result?.data.brandSearchCount;
    formVars.subbrand_list_513386_value = ReactSystemFunctions.toString(
      this,
      stateVars.BrandSearchCount?.length > 0 ? stateVars.BrandSearchCount[0]?.count : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.SubBrand_ListPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SubBrand_ListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_519748 = this.state.ParentBrand;

    stateVars.dataSource_526539 = this.state.BrandListBySearch;
    formVars.subbrand_list_513386_value = ReactSystemFunctions.toString(
      this,
      this.state.BrandSearchCount?.length > 0 ? this.state.BrandSearchCount[0]?.count : null
    );

    formVars.subbrand_list_5935_total = ReactSystemFunctions.value(
      this,
      this.state.BrandSearchCount?.length > 0 ? this.state.BrandSearchCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  SubBrand_ListComponent_276412_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SubBrand_List",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "855401",
      null,
      "left",
      null,
      "320px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SubBrand_ListComponent_189598_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BrandId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "subbrand_list_519748_value", "value", "ParentBrand", "id", "brandId")
        ),
        "Guid"
      ),
      SearchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "subbrand_list_189598_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "subbrand_list_5935_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "subbrand_list_5935_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      BrandId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "subbrand_list_519748_value", "value", "ParentBrand", "id", "id")
        ),
        "Guid"
      ),
      SearchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "subbrand_list_189598_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SubBrand_List/SubBrand_ListComponent_189598_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.BrandListBySearch = result?.data.brandListBySearch;

    stateVars.BrandSearchCount = result?.data.brandSearchCount;
    formVars.subbrand_list_513386_value = ReactSystemFunctions.toString(
      this,
      stateVars.BrandSearchCount?.length > 0 ? stateVars.BrandSearchCount[0]?.count : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.SubBrand_ListComponent_189598_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SubBrand_ListComponent_189598_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_526539 = this.state.BrandListBySearch;
    formVars.subbrand_list_513386_value = ReactSystemFunctions.toString(
      this,
      this.state.BrandSearchCount?.length > 0 ? this.state.BrandSearchCount[0]?.count : null
    );

    formVars.subbrand_list_5935_total = ReactSystemFunctions.value(
      this,
      this.state.BrandSearchCount?.length > 0 ? this.state.BrandSearchCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SubBrand_ListComponent_519748_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BrandId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "subbrand_list_519748_value", "value", "ParentBrand", "id", "brandId")
        ),
        "Guid"
      ),
      SearchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "subbrand_list_189598_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "subbrand_list_5935_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "subbrand_list_5935_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      BrandId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "subbrand_list_519748_value", "value", "ParentBrand", "id", "id")
        ),
        "Guid"
      ),
      SearchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "subbrand_list_189598_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SubBrand_List/SubBrand_ListComponent_519748_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.BrandListBySearch = result?.data.brandListBySearch;

    stateVars.BrandSearchCount = result?.data.brandSearchCount;
    formVars.subbrand_list_513386_value = ReactSystemFunctions.toString(
      this,
      stateVars.BrandSearchCount?.length > 0 ? stateVars.BrandSearchCount[0]?.count : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.SubBrand_ListComponent_519748_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SubBrand_ListComponent_519748_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_526539 = this.state.BrandListBySearch;
    formVars.subbrand_list_513386_value = ReactSystemFunctions.toString(
      this,
      this.state.BrandSearchCount?.length > 0 ? this.state.BrandSearchCount[0]?.count : null
    );

    formVars.subbrand_list_5935_total = ReactSystemFunctions.value(
      this,
      this.state.BrandSearchCount?.length > 0 ? this.state.BrandSearchCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SubBrand_ListComponent_625746_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("Brand_New", "prmBrandId", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables("Brand_New", "prmImageId", Guid.create().toString());

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SubBrand_List",
      "Brand_New",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "956086",
      null,
      "right",
      null,
      "500px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SubBrand_ListComponent_402705_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("SubBrand_New", "prmSubBrandId", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables("SubBrand_New", "prmParentId", null);

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SubBrand_List",
      "SubBrand_New",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "801686",
      null,
      "right",
      null,
      "500px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SubBrand_ListComponent_390575_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "SubBrand_New",
      "prmSubBrandId",
      ReactSystemFunctions.value(this, "subbrand_list_526539_value", "subBrandId")
    );
    KuikaAppManager.addToPageInputVariables(
      "SubBrand_New",
      "prmParentId",
      ReactSystemFunctions.value(this, "subbrand_list_526539_value", "parentBrandId")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SubBrand_List",
      "SubBrand_New",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "232721",
      null,
      "right",
      null,
      "500px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SubBrand_ListComponent_989965_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "subbrand_list_526539_value", "subBrandId"),
        "Guid"
      ),
      BrandId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "subbrand_list_519748_value", "value", "ParentBrand", "id", "brandId")
        ),
        "Guid"
      ),
      SearchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "subbrand_list_189598_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "subbrand_list_5935_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "subbrand_list_5935_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      BrandId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "subbrand_list_519748_value", "value", "ParentBrand", "id", "id")
        ),
        "Guid"
      ),
      SearchFor_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "subbrand_list_189598_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "0a441d11_f21e_619e_7220_13b65c333777_confirmation",
        this.defaultML,
        "This subbrand will be deleted. Are you sure?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    }).then(async (result) => {
      if (result.isConfirmed) {
        KuikaAppManager.showSpinner(this);
        let result = await KuikaAppManager.ApiRequest(
          this,
          "SubBrand_List/SubBrand_ListComponent_989965_onClick",
          localVar
        );
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

        stateVars.SubBrandDelete = result?.data.subBrandDelete;
        stateVars.BrandListBySearch = result?.data.brandListBySearch;

        stateVars.BrandSearchCount = result?.data.brandSearchCount;
        formVars.subbrand_list_513386_value = ReactSystemFunctions.toString(
          this,
          stateVars.BrandSearchCount?.length > 0 ? stateVars.BrandSearchCount[0]?.count : null
        );
        this.props.form.setFieldsValue(formVars);
        this.setState(stateVars, () => {
          this.SubBrand_ListComponent_989965_onClick1_();
        });
      }
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SubBrand_ListComponent_989965_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_526539 = this.state.BrandListBySearch;
    formVars.subbrand_list_513386_value = ReactSystemFunctions.toString(
      this,
      this.state.BrandSearchCount?.length > 0 ? this.state.BrandSearchCount[0]?.count : null
    );

    formVars.subbrand_list_5935_total = ReactSystemFunctions.value(
      this,
      this.state.BrandSearchCount?.length > 0 ? this.state.BrandSearchCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SubBrand_ListComponent_5935_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BrandId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "subbrand_list_519748_value", "value", "ParentBrand", "id", "brandId")
        ),
        "Guid"
      ),
      SearchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "subbrand_list_189598_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "subbrand_list_5935_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "subbrand_list_5935_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "SubBrand_List/SubBrand_ListComponent_5935_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.BrandListBySearch = result?.data.brandListBySearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.SubBrand_ListComponent_5935_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SubBrand_ListComponent_5935_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_526539 = this.state.BrandListBySearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [855401, 956086, 801686, 232721] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.SubBrand_ListPageInit();
    }
  }
}
