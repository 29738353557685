import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IProduct_Translation_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IProduct_Translation_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  ProductTranslationSave: number;
  ProductCategoryWebSiteUpdate: any[];
  ProductCategoryById: any[];
  ProductLanguageByCondition: any[];
}

export class Product_Translation_ScreenBase extends React.PureComponent<IProduct_Translation_ScreenProps, any> {
  product_translation_808229_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "937da27a-1820-c1c3-46e3-ec04ce38ecf6",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "55b01d8a-00b1-4170-8fd9-0a977af57a4c",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 972287, PropertyName: "value", Value: "GO BACK" },
        { Id: 619802, PropertyName: "label", Value: "SAVE" },
        { Id: 607465, PropertyName: "value", Value: "Translation form" },
        { Id: 720621, PropertyName: "value", Value: "Language" },
        { Id: 808229, PropertyName: "placeholder", Value: "Choose language..." },
        { Id: 164410, PropertyName: "value", Value: "Name" },
        { Id: 568001, PropertyName: "placeholder", Value: "Product name..." },
        { Id: 511383, PropertyName: "value", Value: "Description" },
        { Id: 725745, PropertyName: "placeholder", Value: "Product descriptions..." },
        { Id: 661186, PropertyName: "value", Value: "SEO - Title Tag" },
        { Id: 359076, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 545777, PropertyName: "value", Value: "SEO - Meta Description" },
        { Id: 202929, PropertyName: "placeholder", Value: "Type here..." }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.product_translation_808229_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      ProductTranslationSave: 0,
      ProductCategoryWebSiteUpdate: [],
      ProductCategoryById: [],
      ProductLanguageByCondition: []
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("product_translation", "product_translation");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.Product_TranslationPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("product_translation", "product_translation");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("product_translation", "product_translation");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.Product_TranslationPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      product_translation_808229_value: this.state.ProductCategoryById?.at?.(0)?.languageId ?? undefined,
      product_translation_568001_value: this.state.ProductCategoryById?.at?.(0)?.definition ?? undefined,
      product_translation_725745_value: this.state.ProductCategoryById?.at?.(-1)?.description ?? undefined,
      product_translation_359076_value: this.state.ProductCategoryById?.at?.(0)?.titleTag ?? undefined,
      product_translation_202929_value: this.state.ProductCategoryById?.at?.(0)?.metaDescription ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  Product_TranslationPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      LangId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmLanguageId ?? this.props.screenInputs.prmlanguageid,
        "Guid"
      ),
      ProductCategoryId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProductCategoryId ?? this.props.screenInputs.prmproductcategoryid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);
    if (
      ReactSystemFunctions.isEqualTo(this.props.screenInputs.prmWaitFor ?? this.props.screenInputs.prmwaitfor, "Edit")
    ) {
      KuikaAppManager.showSpinner(this);
      let result = await KuikaAppManager.ApiRequest(this, "Product_Translation/Product_TranslationPageInit", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.ProductCategoryById = result?.data.productCategoryById;
      formVars.product_translation_808229_value =
        stateVars.ProductCategoryById?.length > 0 ? stateVars.ProductCategoryById[0]?.languageId : null;
      formVars.product_translation_568001_value = ReactSystemFunctions.toString(
        this,
        stateVars.ProductCategoryById?.length > 0 ? stateVars.ProductCategoryById[0]?.definition : null
      );
      formVars.product_translation_725745_value = ReactSystemFunctions.toString(
        this,
        stateVars.ProductCategoryById?.length > 0
          ? stateVars.ProductCategoryById[stateVars.ProductCategoryById.length - 1].description
          : null
      );
      formVars.product_translation_359076_value = ReactSystemFunctions.toString(
        this,
        stateVars.ProductCategoryById?.length > 0 ? stateVars.ProductCategoryById[0]?.titleTag : null
      );
      formVars.product_translation_202929_value = ReactSystemFunctions.toString(
        this,
        stateVars.ProductCategoryById?.length > 0 ? stateVars.ProductCategoryById[0]?.metaDescription : null
      );
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Product_TranslationPageInit1_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Product_TranslationPageInit1_();
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Product_TranslationPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      WaitFor_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmWaitFor ?? this.props.screenInputs.prmwaitfor,
        "string"
      ),
      ProductCategoryId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProductCategoryId ?? this.props.screenInputs.prmproductcategoryid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(this, "Product_Translation/Product_TranslationPageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ProductLanguageByCondition = result?.data.productLanguageByCondition;

    formVars.product_translation_808229_options = stateVars.ProductLanguageByCondition;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Product_TranslationPageInit2_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Product_TranslationPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(this.props.screenInputs.prmWaitFor ?? this.props.screenInputs.prmwaitfor, "Edit")
    ) {
      formVars.product_translation_808229_value = ReactSystemFunctions.toString(
        this,
        this.state.ProductCategoryById?.length > 0 ? this.state.ProductCategoryById[0]?.languageId : null
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Product_TranslationPageInit3_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Product_TranslationPageInit3_();
      });
    }

    return isErrorOccurred;
  };
  Product_TranslationPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_808229 = this.state.ProductLanguageByCondition;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Product_TranslationPageInit4_();
    });

    return isErrorOccurred;
  };
  Product_TranslationPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(this.props.screenInputs.prmWaitFor ?? this.props.screenInputs.prmwaitfor, "Edit")
    ) {
      formVars.product_translation_568001_value = ReactSystemFunctions.toString(
        this,
        this.state.ProductCategoryById?.length > 0 ? this.state.ProductCategoryById[0]?.definition : null
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Product_TranslationPageInit5_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Product_TranslationPageInit5_();
      });
    }

    return isErrorOccurred;
  };
  Product_TranslationPageInit5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(this.props.screenInputs.prmWaitFor ?? this.props.screenInputs.prmwaitfor, "Edit")
    ) {
      formVars.product_translation_725745_value = ReactSystemFunctions.toString(
        this,
        this.state.ProductCategoryById?.length > 0
          ? this.state.ProductCategoryById[this.state.ProductCategoryById.length - 1].description
          : null
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Product_TranslationPageInit6_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Product_TranslationPageInit6_();
      });
    }

    return isErrorOccurred;
  };
  Product_TranslationPageInit6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(this.props.screenInputs.prmWaitFor ?? this.props.screenInputs.prmwaitfor, "Edit")
    ) {
      formVars.product_translation_359076_value = ReactSystemFunctions.toString(
        this,
        this.state.ProductCategoryById?.length > 0 ? this.state.ProductCategoryById[0]?.titleTag : null
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Product_TranslationPageInit7_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Product_TranslationPageInit7_();
      });
    }

    return isErrorOccurred;
  };
  Product_TranslationPageInit7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(this.props.screenInputs.prmWaitFor ?? this.props.screenInputs.prmwaitfor, "Edit")
    ) {
      formVars.product_translation_202929_value = ReactSystemFunctions.toString(
        this,
        this.state.ProductCategoryById?.length > 0 ? this.state.ProductCategoryById[0]?.metaDescription : null
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  Product_TranslationComponent_972287_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Product_TranslationComponent_619802_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "product_translation_568001_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "product_translation_568001_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "product_translation_808229_value",
            "value",
            "ProductLanguageByCondition",
            "id",
            ""
          )
        ),
        null
      ),
      message: "This field is required",
      formName: "product_translation_808229_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Definition_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "product_translation_568001_value", "value", "", "", "")
        ),
        "string"
      ),
      Description_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "product_translation_725745_value", "value", "", "", "")
        ),
        "string"
      ),
      TitleTag_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "product_translation_359076_value", "value", "", "", "")
        ),
        "string"
      ),
      MetaDescription_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "product_translation_202929_value", "value", "", "", "")
        ),
        "string"
      ),
      LanguageId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "product_translation_808229_value",
            "value",
            "ProductLanguageByCondition",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProductTranslationId ?? this.props.screenInputs.prmproducttranslationid,
        "Guid"
      ),
      ProductCategoryID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProductCategoryId ?? this.props.screenInputs.prmproductcategoryid,
        "Guid"
      ),
      WebInfo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "product_translation_808229_value",
            "value",
            "ProductLanguageByCondition",
            "id",
            "name"
          )
        ),
        "string"
      ),
      CurrentWebInfo_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmCurrentLang ?? this.props.screenInputs.prmcurrentlang,
        "string"
      ),
      ProductCategoryID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProductCategoryId ?? this.props.screenInputs.prmproductcategoryid,
        "Guid"
      ),
      WebInfo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "product_translation_808229_value",
            "value",
            "ProductLanguageByCondition",
            "id",
            "name"
          )
        ),
        "string"
      ),
      CurrentWebInfo_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmCurrentLang ?? this.props.screenInputs.prmcurrentlang,
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    KuikaAppManager.showSpinner(this);
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Product_Translation/Product_TranslationComponent_619802_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ProductTranslationSave = result?.data.productTranslationSave;
    stateVars.ProductCategoryWebSiteUpdate = result?.data.productCategoryWebSiteUpdate;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Product_TranslationComponent_619802_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Product_TranslationComponent_619802_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Product_TranslationComponent_419961_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
